import React, { useCallback, useState } from 'react'
import moment from 'moment/moment'
import { nanoid } from 'nanoid'
import { type IMarketing, type Unit } from '../../../store/slices/unitsSlice'
import { type IProperty } from '../../../types'
import UnitBodyOptionsBadgeSh, { type UnitBodyOptionsBadgeShProps } from './UnitBodyOptionsBadgeSh'
import { type IconName } from '../../../app/icon/iconsConfig'
import { useAppDispatch, useAppSelector } from '../../../hooks/redux'
import { type RootState } from '../../../store/store'
import { addUnitToFavorites } from '../../../store/actionsCreators/userActions/addUnitToFavorites'
import _ from 'lodash'
import { type User } from '../../../store/slices/userSlice'
import { useLocation } from 'react-router-dom'

interface IProps {
    unit: Unit
}

export default function UnitBodyOptionsSh({ unit }: IProps): JSX.Element {

    const dispatch = useAppDispatch()
    const currentUser = useAppSelector((state: RootState) => state.user.currentUser)
    const fetching = useAppSelector((state: RootState) => state.modal.loading)
    const path = useLocation().pathname
    const property = unit?.attributes?.Property?.data?.attributes ?? unit?.attributes?.Property as IProperty
    const Marketing = unit?.attributes?.Marketing as IMarketing ?? {}

    const userFavoriteUnits = (currentUser as User).FavoriteUnits ?? []

    const isFavorite = userFavoriteUnits.includes(unit.id);


    const handleFavoriteClick = useCallback((e: any) => {
        e.preventDefault();
        if (!fetching) {
            dispatch(addUnitToFavorites(unit.id, isFavorite));
        }
    }, [unit.id, isFavorite, dispatch]);


    const isDev = process.env.REACT_APP_IS_DEV === 'true'

    const { DiscountEndDatetime, DiscountStartDatetime, DiscountPrice, Status } = Marketing
    const startDate = moment(DiscountStartDatetime)
    const endDate = moment(DiscountEndDatetime)
    const discountActive = moment().isAfter(moment(startDate)) && moment().isBefore(moment(endDate)) && Status === 'Enabled'
    const unitBodyOptionsBadges: UnitBodyOptionsBadgeShProps[] = [
        {
            text: `Dubai, ${String(property?.Area ?? property?.Community ?? property?.Name ?? '')}`,
            icon: 'location_on'
        },
        {
            text: unit?.attributes?.Type,
            icon: 'home'
        },
        {
            text: unit?.attributes?.Bedrooms > 0 ? `${unit?.attributes?.Bedrooms} Bed` : 'Studio',
            icon: 'bed',
            renderCondition: unit?.attributes?.Bedrooms != null
        },
        {
            text: `${unit?.attributes?.Bathrooms} Bath`,
            icon: 'bathtub',
            renderCondition: unit?.attributes?.Bathrooms != null && unit?.attributes?.Bathrooms !== 0

        },

        ...((unit?.attributes?.Statuses != null && unit?.attributes?.Statuses.length > 0)
            ? unit?.attributes?.Statuses.filter((el: string) => ['Vacant', 'Occupied'].includes(el)).map((el: string): { icon: IconName, text: string } => ({
                text: el,
                icon: 'door_front'
            }))
            : []),
        ...(unit?.attributes?.Statuses?.filter((el: string) => ['Building Management', 'Keys in BSO office', 'Digital lock'].includes(el))?.map((el: string): { icon: IconName, text: string } => ({
            text: el,
            icon: 'lock'
        })) ?? []),
        {
            text: `${String(unit?.attributes?.SizeArea?.toLocaleString('en-US'))} Sq.Ft`,
            icon: 'square_foot',
            renderCondition: unit?.attributes?.SizeArea != null
        },
        {
            text: unit?.attributes?.ImportantNotice,
            icon: 'info',
            renderCondition: Boolean(unit?.attributes?.ImportantNotice)
        }
    ]

    return (
        <>
            <div className="position-relative d-flex align-items-start mb-0 px-3">
                <span
                    className="text-gray-900 fs-2x fs-lg-1 lh-sm me-0 mt-1"
                    style={{ paddingRight: '40px' }}
                >
                    {unit?.attributes?.Marketing?.Headline ?? property?.Name ?? 'Not added'}
                </span>

                {!path.includes("/agent/my_units") && isDev && <div
                    className="position-absolute mt-2"
                    style={{ top: '0', right: '10px', cursor: 'pointer' }}
                    onClick={handleFavoriteClick}
                >
                    <i className={`ki-duotone ki-heart-circle fs-5x  fs-lg-3x ${isFavorite ? 'text-dark' : ""}`}>
                        <span className="path1"></span>
                        <span className="path2"></span>
                    </i>

                </div>}
            </div >

            <div className="d-flex align-items-center mb-0 px-3 gap-2 mb-2">
                <span
                    className="text-gray-900 fs-4"
                > AED
                </span>
                <span
                    className={`text-gray-900 fs-2 ${discountActive ? 'text-decoration-line-through' : ''}`}
                >
                    {unit.attributes?.Marketing?.Price?.toLocaleString('en-US') ?? 'Not added'}
                </span>
                {discountActive
                    ? <span className={'text-gray-900 fs-2'}>
                        {Number(DiscountPrice)?.toLocaleString('en-US')}
                    </span>
                    : <></>
                }
                <span className={'btn btn-sm btn-light py-1 px-2'}>
                    {
                        unit.attributes.Marketing?.Use
                    }
                </span>
                {
                    unit.attributes?.Documents?.data?.length > 0 || currentUser.id !== Number(process.env.REACT_APP_BSO_USER_ID)
                        ? <></>
                        : <span className={'btn btn-sm btn-danger py-1 px-2'}>
                            Docs not uploaded
                        </span>
                }
            </div>

            <div className="d-flex flex-wrap fw-bold fs-6 mb-2 px-3">
                {
                    unitBodyOptionsBadges.map((option) => <UnitBodyOptionsBadgeSh {...option} key={nanoid()} />)
                }
            </div>
        </>

    )
}
