import React, { useState, type Dispatch, type SetStateAction } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../../../../../hooks/redux'
import { type RootState } from '../../../../../store/store'

import { Modal } from 'antd'
import { type ISettings, type User } from '../../../../../store/slices/userSlice'
import Icon from '../../../../../app/icon/Icon'
import CloseIconBasic from '../../../../shared/elements/CloseIconBasic'
import { mainSwalDeal } from '../../../../../functions/swalOptions'
import { ava_blank } from '../../../../../urls'

interface IProps {
    previewOpen?: boolean
    setPreviewOpen: Dispatch<SetStateAction<boolean>>
    currentUser: User
}

export default function MyProfileHead({
    previewOpen,
    setPreviewOpen,
    currentUser
}: IProps): JSX.Element {
    const userCONTACTS = (currentUser)?.Type?.[0]
    const userContactsINFO = (currentUser)?.ContactInfo
    const userSettings = currentUser?.Settings as ISettings[]
    const userID = currentUser.id
    const navigate = useNavigate()
    const [imageUrl, setImageUrl] = useState<string>(
        userCONTACTS?.Photo ?? ava_blank
    )
    const licenseApproved = useAppSelector(
        (state: RootState) => state.agentStates.statusesApproved.licenseApproved
    )

    const upgradeAvatar = () => {
        void mainSwalDeal('Want to change your avatar?', undefined, 'Yes, I do', 'Not now').then(async (result: any) => {
            if (result.isConfirmed) {
                navigate(`/agent/modal/agent_avatar/${userID}/${userID}`)
            }
        })
    }

    const { innerHeight, innerWidth } = window

    const aspectRatio = innerHeight / innerWidth;

    const currentHeight = Math.floor(375 * aspectRatio);

    return (
        <>

            <div className="d-flex flex-sm-nowrap">
                <div className="me-7 mb-4 position-relative">
                    <div
                        onClick={() => {
                            setPreviewOpen(true)
                        }}
                        className="symbol symbol-100px symbol-lg-120px symbol-fixed position-relative"
                    >
                        <img
                            className="object-fit-cover border rounded"
                            src={imageUrl}
                            alt="image"
                        />
                        <div className="position-absolute translate-middle bottom-0 start-100 mb-6 bg-success rounded-circle border border-4 border-body h-20px w-20px"></div>
                    </div>

                    <Modal
                        open={previewOpen}
                        footer={null}
                        onCancel={() => { setPreviewOpen(false); }}
                        closeIcon={
                            <CloseIconBasic
                                onCancel={() => { setPreviewOpen(false); }}
                                cls={''}
                                style={{ top: '25px', right: '15px' }}
                            />
                        }
                        centered={false}
                        style={{
                            top: 0,
                            padding: 0,
                        }}
                        bodyStyle={{
                            padding: 0,
                            margin: 0,
                            height: `${currentHeight}px`,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            overflow: 'hidden',
                        }}
                        maskStyle={{
                            backgroundColor: 'transparent',
                        }}
                        width="100%"
                    >
                        <div
                            style={{
                                width: '100%',
                                height: '100vh',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                        >
                            <div
                                className={`position-absolute `}

                                style={{
                                    top: "42px",
                                    left: `${31}px`,
                                    zIndex: 9999,

                                }}
                                onClick={upgradeAvatar}
                            >

                                <div className={'p-1'} style={{ borderRadius: '1rem', background: '#00000066' }}>
                                    <Icon name={'edit'} imageSize={24} className={'fs-4x text-white fs-lg-3x'} styles={{ fill: 'white' }} />
                                </div>
                            </div>

                            <img
                                src={imageUrl}
                                alt="example"
                                style={{
                                    maxWidth: '100%',
                                    maxHeight: '100%',
                                    objectFit: 'contain',
                                }}
                            />
                        </div>
                    </Modal>



                </div>
                <div className="flex-grow-1">
                    <div className="d-flex justify-content-between align-items-start flex-wrap mb-2">
                        <div className="d-flex flex-column">
                            <div className="d-flex align-items-center mb-2">
                                <span className="text-gray-900 fs-2 fs-lg-3 fw-bold me-1 ">
                                    {`${userContactsINFO?.FirstName
                                        ? userContactsINFO?.FirstName
                                        : userCONTACTS?.Name}
                                         ${userContactsINFO?.FamilyName
                                            ? userContactsINFO?.FamilyName
                                            : ''
                                        }`}
                                </span>
                                <span className={'d-flex align-items-center'}>
                                    {licenseApproved
                                        ? <Icon name={'verified'} className={'fs-1 text-warning'} />
                                        : <Icon name={'verified'} className={'fs-1 text-primary'} />
                                    }

                                </span>
                            </div>

                            {/* <div className="d-flex flex-wrap fw-semi-bold fs-6 pe-2">
                                <span
                                    className="d-flex align-items-center text-gray-400  me-5 mb-2"
                                >
                                    <Icon name={'leaderboard'} className={'fs-1 fs-4 me-1 text-muted'}/>
                  Rating {userSettings?.[0]?.Performance ?? 0}
                                </span>
                            </div> */}

                            <div className="d-flex flex-wrap fw-semi-bold fs-6 fs-lg-7 pe-2">
                                <span className="d-flex align-items-center text-gray-400  mb-2">
                                    <Icon name={'mail'} className={'fs-1 fs-4 me-1 text-muted'} />
                                    {(currentUser)?.email}
                                </span>
                                <br />
                            </div>
                            <div className={'fw-semi-bold'}>
                                <span

                                    className="d-flex align-items-center text-gray-400 mb-1 fs-lg-7"
                                >
                                    <Icon name={'call'} className={'fs-1 fs-4 me-1 text-muted'} />
                                    {(currentUser)?.phone
                                        ? (currentUser)?.phone
                                        : ' No phone'}
                                </span>
                            </div>

                            {/* <div className={'mb-4 fw-semi-bold'}>
                                <span
                                    className="d-flex align-items-center text-gray-400 mb-2"
                                >
                                    <Icon name={'location_on'} className={'fs-1 fs-4 me-1 text-muted'} />
                                    {userContactsINFO?.Country
                                        ? userContactsINFO?.Country
                                        : ''}
                                </span>


                            </div> */}

                        </div>
                    </div>
                </div>

            </div>

        </>
    )
}


