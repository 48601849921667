import React, { useMemo } from 'react'

import { Link, useLocation, useNavigate } from 'react-router-dom'
import { type RootState } from '../../store/store'
import { useAppDispatch, useAppSelector } from '../../hooks/redux'
import { type UserType } from '../../hooks/UseUserType'
import { setFilterNavigate } from '../../store/slices/scheduleSlice'
import { onBlockLoadDocuments } from '../../functions/agent/documents/onBlockLoadDocuments'
import Icon from '../../app/icon/Icon'
import { removeDataAttributes } from '../../functions/adapters/universal/removeDataAttributes'
import _ from 'lodash'
import dayjs from 'dayjs'
import UseUserType from '../../hooks/UseUserType'
import { BSO_DEVELOP_MODE_ON_FRONT, REACT_APP_BSO_USER_ID, USER_ID_KEY } from '../../urls'
import { getBelongsUnitForOffer, getBelongsUnitForViewing } from '../../functions/shared/schedule/getBelongsUnit'

export default function AgentFooter(): JSX.Element {
    const location = useLocation().pathname
    const navigate = useNavigate()
    const dispatch = useAppDispatch()

    const { userType, userTypeLowercase } = UseUserType()
    let notifyApp: UserType | 'BSO' = userType

    const currentUser = useAppSelector((state: RootState) => state.user.currentUser)
    const userID = currentUser.id ?? window.localStorage.getItem(USER_ID_KEY)
    if (String(userID) === REACT_APP_BSO_USER_ID) {
        notifyApp = 'BSO'
    }

    const { statusesApproved, documentsLoaded } = useAppSelector(
        (state: RootState) => state.agentStates
    )

    const loadSts = documentsLoaded.emiratesLoaded || documentsLoaded.passportLoaded
    const verifiedStatus = statusesApproved.emiratesApproved || statusesApproved.passportApproved

    const agentViewings = useAppSelector((state: RootState) => state.viewings.viewings).filter((el) => {
        return el?.attributes?.User?.data?.id === userID || el.attributes.Unit.data?.attributes.User?.data?.id === Number(userID)
    })
    const agentOffers = useAppSelector((state: RootState) => state.offers.offers).filter((el) => {
        return el?.attributes?.User?.data?.id === userID || el.attributes.Unit.data?.attributes.User?.data?.id === Number(userID)
    })
    const agentAdvertises = useAppSelector((state: RootState) => state.advertises.advertises).filter((el) => el?.attributes?.User?.data?.id === userID || el.attributes.Unit.data?.attributes.User?.data?.id === Number(userID))
    const userDevice = useAppSelector((state) => state.app.userDevice)
    const units = useAppSelector((state) => state.units.units)
    const chatList = useAppSelector((state) => state.chatList.chatList)

    const allAttention = [...agentViewings, ...agentOffers, ...agentAdvertises].filter((el: any) => {
        let task = {
            type: 'TYPE UNIT',
            color: 'secondary'
        }

        if ('Datetime' in el.attributes) {
            task = getBelongsUnitForViewing(el)
        } else if ('ProcessEndDate' in el.attributes) {
            task = getBelongsUnitForOffer(el)
        }

        return (el?.attributes?.Notify === notifyApp && task.type !== 'MY UNIT') || el.attributes.Notify === 'Both' || (el.attributes.Notify === 'Other Agent' && el?.attributes.User?.data?.id !== Number(userID))
    })

    const chatAttentionCount = useMemo(() =>
        chatList
            .filter((chat) => chat?.userID != null)
            .reduce(
                (accumulator, chat) => {
                    if (chat.Unread != null) {
                        const keys = Object.keys(chat.Unread)
                        const unreadUsers = keys.filter(key => Number(key) === currentUser.id)
                        const unreadCount = unreadUsers.reduce(
                            (unreadAccumulator, key) => unreadAccumulator + (chat?.Unread?.[key] ?? 0),
                            0
                        )
                        return accumulator + unreadCount
                    } else {
                        return accumulator
                    }
                },
                0
            )
        , [chatList])
    const unitsAttentionCount = useMemo(() => {
        return units.filter((unitItem) => {
            if (unitItem?.attributes?.Marketing == null) {
                return false
            }
            const unit = removeDataAttributes(_.cloneDeep(unitItem))
            if (unit?.Statuses?.includes('Archived') === true) {
                return false
            }
            if (unit.User.id !== Number(process.env.REACT_APP_BSO_USER_ID)) {
                return false
            }
            if (unit.HideDate != null && new Date().getTime() - new Date(unit.HideDate).getTime() > 0) {
                return false
            }
            if (unit?.Statuses?.includes('Drafted') === true) {
                return false
            }

            if (unit.CreateDate == null || dayjs().isAfter(dayjs(unit.CreateDate).add(1, 'd'))) {
                return false
            }
            return true
        }).length
    }, [units])

    const handleButtonClick = (groupOpen: string) => {
        dispatch(setFilterNavigate(groupOpen))
        navigate(`/${userTypeLowercase}/schedule`)
    }

    const isDevFront = window.localStorage.getItem(BSO_DEVELOP_MODE_ON_FRONT)

    return (
        <>


            <div className={`footer fixed-bottom bg-white shadow-sm w-100 justify-content-between ${userDevice === 'IPhone' ? 'pb-6' : ''}`} style={{ zIndex: '199' }}
            >
                <div className="d-flex flex-row mx-auto   mw-450px   px-4 pb-4 justify-content-center" style={{ paddingTop: '1.86rem' }}>
                    <Link
                        to={loadSts ? `/${userTypeLowercase}/dashboard` : '#'}
                        onClick={loadSts ? undefined : () => { onBlockLoadDocuments(userTypeLowercase, navigate) }}
                        className={`text-decoration-none p-1 mx-4 ${location === `/${userTypeLowercase}/dashboard` || location === `/${userTypeLowercase}/profile` ? 'text-dark' : 'text-gray-400'}`}
                    >
                        <div className="d-flex flex-column align-items-center">
                            <Icon
                                name={'grid_view'}
                                className={'fs-5x fs-lg-3x'}
                                imageSize={48}
                            />
                            <span className={`${location === `/${userTypeLowercase}/dashboard` ? 'fw-bold' : 'fw-normal'} fs-4`}>Board</span>
                        </div>
                    </Link>

                    <div
                        className={`text-decoration-none p-1 mx-4 ${location === `/${userTypeLowercase}/schedule` ? 'text-dark' : 'text-gray-400'}`}
                        onClick={loadSts ? () => { handleButtonClick('attention') } : () => { onBlockLoadDocuments(userTypeLowercase, navigate) }}
                    >
                        <div className="d-flex flex-column align-items-center position-relative">
                            {allAttention && allAttention.length > 0
                                ? (
                                    <span
                                        className="position-absolute top-0 start-100 translate-middle badge badge-circle badge-danger"
                                    >
                                        {allAttention.length}
                                    </span>
                                )
                                : null}
                            { /* <span className="bi bi-calendar2-check fs-4x m-0 p-0" /> */}
                            <Icon
                                name={'event_available'}
                                className={'fs-5x fs-lg-3x'}
                                imageSize={48}
                            />
                            <span className={`${location === `/${userTypeLowercase}/schedule` ? 'fw-bold' : 'fw-normal'} fs-4`}>Tasks</span>
                        </div>
                    </div>
                    <Link
                        to={loadSts ? `/${userTypeLowercase}/chats` : '#'}
                        onClick={loadSts ? undefined : () => { onBlockLoadDocuments(userTypeLowercase, navigate) }}
                        className={`text-decoration-none p-1 mx-4 ${location === `/${userTypeLowercase}/chats` ? 'text-dark' : 'text-gray-400'}`}
                    >
                        <div className="d-flex flex-column align-items-center position-relative">
                            {chatAttentionCount > 0
                                ? (
                                    <span
                                        className="position-absolute top-0 start-100 translate-middle badge badge-circle badge-danger"
                                    >
                                        {chatAttentionCount}
                                    </span>
                                )
                                : null}
                            {/* <span className="bi bi-chat fs-4x m-0 p-0" /> */}
                            <Icon
                                name={'chat'}
                                className={'fs-5x  fs-lg-3x'}
                                imageSize={48}
                            />
                            <span className={`${location === `/${userTypeLowercase}/chats` ? 'fw-bold' : 'fw-normal'} fs-4`}>Chats</span>
                        </div>
                    </Link>
                    <Link
                        to={`/${userTypeLowercase}/units/nav`}
                        className={`position-relative text-decoration-none p-1 mx-4 ${location.includes('units') && location !== `/${userTypeLowercase}/my_units` ? 'text-dark' : 'text-gray-400'}`}
                    >
                        <div className="d-flex flex-column align-items-center position-relative">
                            {unitsAttentionCount > 0
                                ? (
                                    <span
                                        className="position-absolute top-0 start-100 translate-middle badge badge-circle badge-danger"
                                    >
                                        {unitsAttentionCount}
                                    </span>
                                )
                                : null}
                            { /* <span className="bi bi-houses fs-4x m-0 p-0" /> */}
                            <Icon
                                name={'home'}
                                className={'fs-5x fs-lg-3x'}
                                imageSize={48}
                            />
                            {/* <i className="bi bi-houses"></i> */}
                            <span className={`${location.includes('units') && location !== `/${userTypeLowercase}/my_units` ? 'fw-bold' : 'fw-normal'} fs-4`}>Units</span>
                        </div>
                    </Link>
                    <Link
                        to={`/${userTypeLowercase}/menu`}
                        // to={loadSts ? `/${userTypeLowercase}/menu` : '#'}
                        // onClick={loadSts ? undefined : () => { onBlockLoadDocuments() } }
                        className={`text-decoration-none p-1 mx-4 ${location === `/${userTypeLowercase}/menu` ? 'text-dark' : 'text-gray-400'}`}
                    >
                        <div className="d-flex flex-column align-items-center ">
                            {/* <span className=" bi bi-list fs-4x m-0 p-0" /> */}
                            <Icon
                                name={'menu'}
                                className={'fs-5x fs-lg-3x'}
                                imageSize={48}
                            />
                            <span className={`${location === `/${userTypeLowercase}/menu` ? 'fw-bold' : 'fw-normal'} fs-4`}>Menu</span>
                        </div>
                    </Link>

                </div>
                {isDevFront === 'true' ? <span className="badge badge-danger position-fixed" style={{ zIndex: '9999999', right: 2, bottom: 2 }}>DEV FRONT MODE</span> : null}

            </div>
        </>
    )
}

