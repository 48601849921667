import React, { useState } from 'react'

import { swalJustAlertAndRedirect } from '../../../../functions/swalOptions'

import swalTexts from '../../../texts/swalTexts'
interface IProps {
    googleMapsLink: string | null
}

export default function GeoForMyCustomModalForView({ googleMapsLink }: IProps): JSX.Element {
    const [openAlert, setOpenAlert] = useState(false)

    if (openAlert) {
        if (googleMapsLink) {
            window.open(googleMapsLink)
            setOpenAlert(false)
        } else {
            void swalJustAlertAndRedirect(swalTexts.justAlertAndRedirect.message, swalTexts.justAlertAndRedirect.title, swalTexts.justAlertAndRedirect.icon)
                .then(async (result: any) => {
                    if (result.isConfirmed) {
                        setOpenAlert(false)
                    }
                })
        }
    }

    return (
        <>

            <div className="container mx-auto mb-2 m-0 py-1 w-100 mt-4 position-relative" onClick={() => { setOpenAlert(true) }} >
                <div className="row">
                    <div className="col-3 btn btn-bso max-w-100px text-center opacity-75 mx-auto">
                        <i className="fs-7x fs-lg-4x las la-map-marked-alt"></i>
                    </div>

                    <div className="col-9 d-flex align-items-center bg-light-secondary">
                        <span className="d-flex flex-column align-items-start justify-content-center mx-2">
                            <span className="fs-3 fw-bold">
                                Get unit location
                            </span>
                            <span className="fs-7 ">Open link in Google Maps  <i className="las la-angle-right"></i></span>
                        </span>
                    </div>

                </div>
            </div>
        </>
    )
}
