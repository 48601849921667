/* eslint-disable @typescript-eslint/indent */
import { useAppDispatch, useAppSelector } from '../../../hooks/redux'
import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2'
import { type RootState } from '../../../store/store'

import { swalAttentionLeave, swalError, swalInProgress, swalJustAlertAndRedirect, swalNeedLoadDocs, swalNeedRera, swalRejectedDoc, swalSuccess } from '../../../functions/swalOptions'
import { scanDocumentsForOffer } from '../../../store/actionsCreators/offerActions'

import { registerLocale } from 'i18n-iso-countries'
import en from 'i18n-iso-countries/langs/en.json'
import { setButtonDriverExtendLocation, setShowButtonBuyCoinsOnAdvertise, setWhereAfterToGo } from '../../../store/slices/modalSlice'
import { setMyArchivedUnitsOpen, setMyDraftUnitsOpen, type Unit } from '../../../store/slices/unitsSlice'
import { setIsExtend, setNotEnoughMarketingCredits, setUnitIDnotEnoughAreaForAdvertise, type IAdvertise } from '../../../store/slices/advertisesSlice'
import { BLOCK_OPEN_AD_AFTER_EXTEND_AREA_WITH_UNIT_ID, BLOCK_OPEN_AD_BEFORE_EXTEND_AREA, USER_AREAS_EXTEND_COUNT, USER_CHOOSE_EXTEND_AREA, USER_ID_KEY } from '../../../urls'
import { isInInterval } from '../../../functions/agent/date/isInInterval'
import { getOfferType } from '../../../functions/agent/offers/getOfferType'
import { type ISettings } from '../../../store/slices/userSlice'
import swalTexts from '../../../app/texts/swalTexts'
import useUserType from '../../../hooks/UseUserType'
import { areUnitActionButtonsBlocked } from '../../../functions/shared/units/getUnitOfferStatus'
import openAddViewingModal from '../../../store/actionsCreators/viewingActions/openAddViewingModal'
import { setUnitHidden, setUnitSold } from '../../../store/actionsCreators/unitsActions'
import { setWhatIsNotThereArea } from '../../../store/slices/areasSlice'
import { makePositiveOrUnchanged } from '../../../functions/agent/date/makePositiveOrUnchanged'
import { userUpdateClickCount } from '../../../store/actionsCreators/userActions/userUpdateClickCount'
import { filterVacantDateForAdvertise, getNextAvailableAdvert } from '../../../functions/shared/date/filterVacantDateForAdvertise'
import { updateWaitingList } from '../../../store/actionsCreators/unitActions/updateWaitingList'
import { archiveAgentUnit } from '../../../store/actionsCreators/unitActions/archiveAgentUnit'
import checkIfUnitHasOngoingTransactions from "../../../functions/shared/units/checkIfUnitHasOngoingTransactions";
import { publishAgentUnit } from '../../../store/actionsCreators/unitActions/publishAgentUnit'
import { type IChatMessage } from '../../../types'

registerLocale(en)

interface IProps {
    unit: Unit
    fromEntry: string | undefined
    settings?: ISettings[]
}

export default function ButtonsActionsSh({ fromEntry, unit, settings }: IProps): JSX.Element {
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const userID = window.localStorage.getItem(USER_ID_KEY)
    const blockOpenAdAfterExtendArea = window.localStorage.getItem(BLOCK_OPEN_AD_BEFORE_EXTEND_AREA)
    const isDev = process.env.REACT_APP_IS_DEV === 'true'
    const { userType, userTypeLowercase } = useUserType()
    const userAreas = settings?.[0]?.Areas ?? []
    const unitArea =
        (unit?.attributes?.Property?.data?.attributes as any)?.Area ??
        (unit?.attributes?.Property as any)?.Area ??
        ''
    const propertyID = (unit?.attributes?.Property?.data?.id as any) ??
        (unit?.attributes?.Property as any)?.id ??
        ''
    const typeUnit = unit?.attributes?.Marketing?.Use ?? ""
    const titleUnit = unit?.attributes?.Marketing?.Headline ?? ""
    const awaitingList = unit.attributes.WaitingList
    // console.log(awaitingList, "awaitingList", titleUnit)

    const blockAgentButtons = areUnitActionButtonsBlocked(unit)

    const MAX_MATCHING_ADVERTISEMENTS = unit?.attributes?.AdvertisementSpaces ?? 3

    let isReserved = false;
    let isAdvertising = false
    let isReserveOtherAgents = false
    let isAdvertiseArea = false
    let isUserSpecialBroker = false
    let isSpecialBrokerEvent = false
    let advertiseOwnerID = 0

    let offerModalType = ''
    let advertiseID = 'not'
    let isBroadcastedUnit: IChatMessage | null = null;


    if (userAreas?.includes(unitArea) || userAreas?.includes('All areas')) {
        isAdvertiseArea = true
    }
    const advertises = useAppSelector((state: RootState) => state.advertises.advertises)
    const { statusesApproved, documentsLoaded, documentsInProgress, documentsRejected, documentsExpires } = useAppSelector((state: RootState) => state.agentStates)

    const verifiedStatus = statusesApproved.emiratesApproved || statusesApproved.passportApproved
    const verifiedInProgress = documentsInProgress.emiratesInProgress || documentsInProgress.passportInProgress
    const loadSts = documentsLoaded.emiratesLoaded || documentsLoaded.passportLoaded
    const rejSts = documentsRejected.passportReject || documentsRejected.emiratesReject
    const expSts = documentsExpires.emiratesExpire || documentsExpires.passportExpire

    const [openAlert, setOpenAlert] = useState(false)
    const [openAlertAddv, setOpenAlertAddv] = useState(false)
    const [actionLoading, setActionLoading] = useState(false)
    const currentUser = useAppSelector((state: RootState) => state.user.currentUser)
    const currentUserMarketingCredits = Number((currentUser?.Settings as ISettings[])?.[0]?.MarketingCredits) ?? 0
    const unitHasOngoingTransactions = checkIfUnitHasOngoingTransactions(unit.id)



    /// Broadcast logic
    const priceBroadcast = 5
    const userBroadcastMessages = useAppSelector((state: RootState) => state.chat.userBroadcastMessages)

    isBroadcastedUnit = userBroadcastMessages.find((_unitMessage) => _unitMessage.Unit?.attributes.id === unit.id) ?? null

    const handleLoad = () => {
        window.open(`https://bso.ae/api/app/downloadPDF?id=${String(unit.id)}&type=Unit%20preview&dev=${isDev}`, '_blank')
    };




    useEffect(() => {
        if (openAlert) {
            if (!verifiedStatus && !verifiedInProgress && userType === 'Agent') {
                if (rejSts) {
                    void swalRejectedDoc(`Your ${documentsRejected.emiratesReject ? 'EmiratesID' : 'Passport'} did rejected, please update documents, to again access to this application\'s functionality `).then(async (result: any) => {
                        if (result?.isConfirmed === true) {
                            setOpenAlert(false)
                            if (documentsRejected.emiratesReject) {
                                navigate('/agent/modal/update_emirates_front_together/not/not')
                            } else {
                                navigate('/agent/modal/update_passport_together/not/not')
                            }
                        }
                    })
                } if (expSts) {
                    void swalRejectedDoc(`Your ${documentsExpires.emiratesExpire ? 'EmiratesID' : 'Passport'} has expired, please update documents, to again access to this application\'s functionality `).then(async (result: any) => {
                        if (result?.isConfirmed === true) {
                            setOpenAlert(false)
                            if (documentsExpires.emiratesExpire) {
                                navigate('/agent/modal/update_emirates_front_together/not/not')
                            } else {
                                navigate('/agent/modal/update_passport_together/not/not')
                            }
                        }
                    })
                }
            } if (verifiedInProgress) {
                void swalInProgress(swalTexts.inProgress.message).then(async (result: any) => {
                    if (result.isConfirmed) {
                        setOpenAlert(false)
                    }
                })
            }
        } if (openAlertAddv) {
            if (!documentsLoaded.licenseLoaded) {
                void swalNeedRera(swalTexts.needRera.messageLicenseLoaded, 'Warning', typeUnit, "Attention, you do not have a RERA card").then(async (result: any) => {
                    if (result.isConfirmed) {
                        if (isAdvertising) {
                            navigate(`/agent/modalView/advertise_modal/${unit.id}/${advertiseID}/not`)
                        } else {
                            handleLoad()
                        }
                        setOpenAlertAddv(false)
                    } else if (result.isDenied) {
                        navigate('/agent/modal/update_rera/units/not')
                        setOpenAlertAddv(false)
                    } else if (result.dismiss === Swal.DismissReason.cancel) {
                        setOpenAlertAddv(false)
                    }
                })
            } if (documentsRejected.licenseReject) {
                void swalNeedRera(swalTexts.needRera.messageLicenseReject, 'Error', typeUnit).then(async (result: any) => {
                    if (result.isConfirmed) {
                        if (isAdvertising) {
                            navigate(`/agent/modalView/advertise_modal/${unit.id}/${advertiseID}/not`)
                        } else {
                            handleLoad()
                        }
                        setOpenAlertAddv(false)
                    } else if (result.isDenied) {
                        navigate('/agent/modal/update_rera/units/not')
                        setOpenAlertAddv(false)
                    } else if (result.dismiss === Swal.DismissReason.cancel) {
                        setOpenAlertAddv(false)
                    }
                })
            } if (documentsExpires.licenseExpire) {
                void swalNeedRera(swalTexts.needRera.messageLicenseExpire, 'Error', typeUnit).then(async (result: any) => {
                    if (result.isConfirmed) {
                        if (isAdvertising) {
                            navigate(`/agent/modalView/advertise_modal/${unit.id}/${advertiseID}/not`)
                        } else {
                            handleLoad()
                        }
                        setOpenAlertAddv(false)
                    } else if (result.isDenied) {
                        navigate('/agent/modal/update_rera/units/not')
                        setOpenAlertAddv(false)
                    } else if (result.dismiss === Swal.DismissReason.cancel) {
                        setOpenAlertAddv(false)
                    }
                })
            }
            if (documentsInProgress.licenseInProgress) {
                void swalNeedRera(swalTexts.inProgress.messageForRera, 'Warning', typeUnit).then(async (result: any) => {
                    if (result.isConfirmed) {
                        if (isAdvertising) {
                            navigate(`/agent/modalView/advertise_modal/${unit.id}/${advertiseID}/not`)
                        } else {
                            handleLoad()
                        }
                        setOpenAlertAddv(false)
                    } else if (result.isDenied) {
                        navigate('/agent/modal/update_rera/units/not')
                        setOpenAlertAddv(false)
                    } else if (result.dismiss === Swal.DismissReason.cancel) {
                        setOpenAlertAddv(false)
                    }
                })
            }
        }
    }, [openAlert, openAlertAddv])


    if (unit?.attributes?.SpecialBrokers && unit?.attributes?.SpecialBrokers?.data?.length > 0) {

        isSpecialBrokerEvent = true
        isUserSpecialBroker = unit?.attributes?.SpecialBrokers?.data?.some((SpBroker: any) => {
            return SpBroker?.id && SpBroker.id === Number(userID);
        });
    }

    offerModalType = getOfferType(unit)
    const isAdvFind = advertises.find((adv: IAdvertise) => {
        return adv.attributes.User.data?.id === Number(userID) && adv.attributes.Unit.data?.id === unit.id // && !adv.attributes.Statuses?.includes('Completed')
    })

    if (isAdvFind != null) {
        if (isAdvFind?.attributes?.StartDate && isAdvFind.attributes.EndDate) {
            if (isInInterval(isAdvFind.attributes.StartDate, isAdvFind.attributes.EndDate) || !isAdvFind.attributes.Statuses?.includes('Completed')) {
                isAdvertising = true
                advertiseID = String((isAdvFind).id)
            }
        } else {
            isAdvertising = true
            advertiseID = String((isAdvFind).id)
        }
    }


    const matchingAdvertsFromOtherUsers = advertises.filter((adv: IAdvertise) => {
        const stsAds = adv?.attributes?.Statuses;
        return (
            adv.attributes.User.data?.id !== Number(userID) &&
            adv.attributes.Unit.data?.id === unit.id &&
            ![
                'Canceled',
                // 'In-process',
                'Advertisement closed',
                'Ads removing rejected',
                'Ads removed',
                'Completed'
            ].some(status => stsAds?.includes(status)) &&
            typeUnit === 'For sale' &&
            (
                adv?.attributes?.StartDate == null || adv?.attributes?.EndDate == null ||
                isInInterval(adv.attributes?.StartDate, adv.attributes?.EndDate)
            ) &&
            (unit?.attributes?.ImagesOnly !== true)
        );
    });

    if (matchingAdvertsFromOtherUsers.length >= MAX_MATCHING_ADVERTISEMENTS) {
        isReserveOtherAgents = true
        if (awaitingList != null && awaitingList.length > 0) {
            isReserved = awaitingList?.some((waitingItem) => {
                if (Number(waitingItem.userID) === Number(userID)) {
                    advertiseOwnerID = Number(userID);
                    return true;
                }
                return false;
            }) || false;
        }
    } else {
        if (matchingAdvertsFromOtherUsers.length < MAX_MATCHING_ADVERTISEMENTS) {
            if (awaitingList != null && awaitingList.length > 0 && awaitingList.length >= MAX_MATCHING_ADVERTISEMENTS) {
                if (awaitingList[0].userID !== Number(userID)) {
                    isReserveOtherAgents = true
                }
                isReserved = awaitingList?.some((waitingItem) => {
                    if (Number(waitingItem.userID) === Number(userID)) {
                        advertiseOwnerID = Number(userID);
                        return true;
                    }
                    return false;
                }) || false;
            }

        }

    }


    useEffect(() => {
        if (userAreas?.includes(unitArea) || userAreas?.includes('All areas')) {
            isAdvertiseArea = true
        }
        const isAdvFind = advertises.find((adv: IAdvertise) => {
            return adv.attributes.User.data?.id === Number(userID) &&
                adv.attributes.Unit.data?.id === unit.id &&
                !adv.attributes.Statuses?.includes('Completed')
        })

        if (isAdvFind != null) {
            if (isAdvFind?.attributes?.StartDate && isAdvFind.attributes.EndDate) {
                if (isInInterval(isAdvFind.attributes.StartDate, isAdvFind.attributes.EndDate) || !isAdvFind.attributes.Statuses?.includes('Completed')) {
                    isAdvertising = true
                    advertiseID = String((isAdvFind).id)
                }
            } else {
                isAdvertising = true
                advertiseID = String((isAdvFind).id)
            }
        }
    }, [advertises])




    const onBlockLoadDocuments = (): void => {
        void swalNeedLoadDocs().then(async (result: any) => {
            if (result?.isConfirmed) {
                navigate('/agent/modal/update_emirates_front_together/INagentMODULE/to_choose_subscription')
            } if (result.isDismissed) {
                console.log('cancel')
            }
        })
    }

    const onReserveAdInAwaitingList = (): void => {
        if ((!isAdvertising && isReserveOtherAgents) && isReserved && Number(userID) === Number(advertiseOwnerID)) {
            void swalJustAlertAndRedirect("You are already using the Waiting List for this unit", "Reserved", "Info")
                .then(async (result: any) => {
                    if (result.isConfirmed) {
                        setOpenAlert(false)
                    }
                })
        } else {
            if ((awaitingList == null) || (awaitingList != null && awaitingList.length < MAX_MATCHING_ADVERTISEMENTS)) {
                const advertiseReserveDates = filterVacantDateForAdvertise(matchingAdvertsFromOtherUsers)
                if (!isReserved) {
                    const nextAdvert = getNextAvailableAdvert(advertiseReserveDates, awaitingList ?? []);
                    if (nextAdvert) {
                        const daysLeft = nextAdvert.daysLeft;
                        const formattedDate = nextAdvert.EndDate !== 'TBA' ? nextAdvert.VacantDate : 'TBA';
                        swalAttentionLeave(
                            formattedDate === 'TBA' ? "Would you like to subscribe for updates regarding this unit's advertisement listings and reserve an available slot in the future?" :
                                `The advertisement listings for this unit are full. The next advertisement slot will be available on <strong> ${formattedDate} </strong> in ${daysLeft}<strong> day${daysLeft > 1 ? "s" : ""}  </strong >.<br/>Would you like to subscribe for updates regarding this unit's advertisement listings and reserve an available slot in the future?`,
                            "Attention", "Reserve Advertisement").then(async (result: any) => {
                                if (result.isConfirmed) {
                                    await dispatch(updateWaitingList({
                                        userID,
                                        unitID: unit.id,
                                        matchingAdvertsFromOtherUsers,
                                        awaitingList
                                    }))
                                }
                            }).catch(console.log);
                    } else {
                        void swalJustAlertAndRedirect("There is no advertising option for this unit, other agents are already using all available advertisement slots. You can just share the unit's details with your clients from the unit modal", "The listings are full", "Info")
                            .then(async (result: any) => {
                                if (result.isConfirmed) {
                                    setOpenAlert(false);
                                }
                            });
                    }
                }
            } else {
                void swalJustAlertAndRedirect("There is no advertising option for this unit, other agents are already using all available advertisement slots. You can just share the unit's details with your clients from the unit modal", "The listings are full", "Info")
                    .then(async (result: any) => {
                        if (result.isConfirmed) {
                            setOpenAlert(false);
                        }
                    });
            }
        }
    }

    const onBlockAdvertise = async (inBusyQueueAttention?: string): Promise<void> => {
        await swalAttentionLeave(inBusyQueueAttention || swalTexts.attentionLeave.messageForAgentButtonActions).then(async (result: any) => {
            if (result.isConfirmed) {
                window.localStorage.setItem(USER_AREAS_EXTEND_COUNT, `${unit.id} - 1`)
                dispatch(setIsExtend(false))
                dispatch(setButtonDriverExtendLocation(true))
                dispatch(setUnitIDnotEnoughAreaForAdvertise(unit.id))
                dispatch(setWhatIsNotThereArea(unitArea))
                window.localStorage.setItem(USER_CHOOSE_EXTEND_AREA, unitArea)
                if (inBusyQueueAttention) {
                    window.localStorage.setItem(BLOCK_OPEN_AD_AFTER_EXTEND_AREA_WITH_UNIT_ID, `${unit.id}`)
                }
            }
        }).catch(console.log);
    }

    useEffect(() => {
        if (blockOpenAdAfterExtendArea === "true" && unit.id === Number(window.localStorage.getItem(BLOCK_OPEN_AD_AFTER_EXTEND_AREA_WITH_UNIT_ID))) {

            void dispatch(updateWaitingList({
                userID,
                unitID: unit.id,
                matchingAdvertsFromOtherUsers,
                awaitingList
            }))
            window.localStorage.removeItem(BLOCK_OPEN_AD_BEFORE_EXTEND_AREA)
            window.localStorage.removeItem(BLOCK_OPEN_AD_AFTER_EXTEND_AREA_WITH_UNIT_ID)
        }
    }, [blockOpenAdAfterExtendArea])

    return (
        <div className="d-flex flex-column m-0 px-3"
            onClick={loadSts || userType !== 'Agent'
                ? !verifiedStatus && userType === 'Agent'
                    ? () => { setOpenAlert(true) }
                    : undefined
                : () => { onBlockLoadDocuments() }}
        >
            <div className="d-flex flex-row row g-0 gx-4 mb-0">
                {(fromEntry === 'nav' || fromEntry == null) &&

                    <>
                        <div className="col-4">
                            <div
                                onClick={() => {
                                    if (isAdvertising && !blockAgentButtons) {
                                        dispatch(setWhereAfterToGo('/agent/units/nav'))
                                        navigate(`/agent/modalView/advertise_modal/${unit.id}/${advertiseID}/not`);
                                    } else if (loadSts && verifiedStatus && statusesApproved.licenseApproved && !blockAgentButtons) {
                                        dispatch(userUpdateClickCount('advertise'));
                                        if (isSpecialBrokerEvent) {
                                            if (isUserSpecialBroker) {
                                                if (isAdvertiseArea) {
                                                    dispatch(setWhereAfterToGo('/agent/units/nav'))
                                                    navigate(`/agent/modal/agent_advertise_create_duration/${unit.id}/${userID}`)
                                                } else {
                                                    void onBlockAdvertise();
                                                }
                                            } else {
                                                void swalJustAlertAndRedirect("There is no advertising option for this unit, other agents are already using all available advertisement slots.<br/> You can just share the unit's details with your clients from the unit modal.", "The listings are full", "Info")
                                                    .then(async (result: any) => {
                                                        if (result.isConfirmed) {
                                                            setOpenAlert(false);
                                                        }
                                                    });
                                            }
                                        } else {
                                            if (isAdvertiseArea) {
                                                if (unit.attributes.ImagesOnly === true) {
                                                    void swalJustAlertAndRedirect("There is no advertising option for this unit, other agents are already using all available advertisement slots.<br/> You can just share the unit's details with your clients from the unit modal.", "The listings are full", "Info")
                                                        .then(async (result: any) => {
                                                            if (result.isConfirmed) {
                                                                setOpenAlert(false);
                                                            }
                                                        });
                                                } else if (isReserveOtherAgents) {
                                                    onReserveAdInAwaitingList()
                                                } else {
                                                    dispatch(setWhereAfterToGo('/agent/units/nav'))
                                                    navigate(`/agent/modal/agent_advertise_create_duration/${unit.id}/${userID}`);
                                                }
                                            } else {
                                                if (isReserveOtherAgents && !isReserved) {
                                                    const advertiseReserveDates = filterVacantDateForAdvertise(matchingAdvertsFromOtherUsers)
                                                    const nextAdvert = getNextAvailableAdvert(advertiseReserveDates, awaitingList ?? []);
                                                    if (nextAdvert) {
                                                        void onBlockAdvertise('You haven\'t purchased the area of this unit for advertisement and , <br/> <strong> THIS UNIT IS ALREADY FULL OF ADVERTISEMENTS</strong> .<br/>Do you want to purchase this area, subscribe for updates regarding this unit\'s advertisement listings, and reserve an available slot in the future?');
                                                    } else {
                                                        void swalJustAlertAndRedirect("There is no advertising option for this unit, other agents are already using all available advertisement slots. You can just share the unit's details with your clients from the unit modal.", "The listings are full", "Info")
                                                            .then(async (result: any) => {
                                                                if (result.isConfirmed) {
                                                                    setOpenAlert(false);
                                                                }
                                                            });
                                                    }
                                                } else {
                                                    if (isReserved) {
                                                        void swalJustAlertAndRedirect("There is no advertising option for this unit, other agents are already using all available advertisement slots. You can just share the unit's details with your clients from the unit modal.", "The listings are full", "Info")
                                                            .then(async (result: any) => {
                                                                if (result.isConfirmed) {
                                                                    setOpenAlert(false);
                                                                }
                                                            });
                                                    } else {
                                                        void onBlockAdvertise();
                                                    }
                                                }
                                            }
                                        }
                                    } else if (!statusesApproved.licenseApproved && !blockAgentButtons) {
                                        dispatch(userUpdateClickCount('advertise'))
                                        setOpenAlertAddv(true);
                                    }
                                }
                                }
                                className={`btn  ${blockAgentButtons ? 'opacity-50' : 'opacity-100'} 
                                ${isAdvertising ? 'btn-success' : (((isUserSpecialBroker && isAdvertiseArea) || isAdvertiseArea) && statusesApproved.licenseApproved) ? 'btn-dark' : 'btn-secondary'} m-0 fs-6 p-1 py-3 w-100`}>
                                {isAdvertising ? 'Advertised' :
                                    (!isAdvertising && isReserveOtherAgents) && isReserved ?
                                        Number(userID) === Number(advertiseOwnerID) ?
                                            'Reserved Ad' :
                                            'Advertise'
                                        : 'Advertise'}
                                {!isAdvertising && isReserveOtherAgents && !isReserved && !isUserSpecialBroker ?
                                    <i className="bi bi-lock-fill fs-2x text-white"></i> :
                                    null}
                            </div>
                        </div>
                        <div className="col-4">
                            <div onClick={
                                loadSts
                                    ? verifiedStatus
                                        ? (e) => {
                                            e.preventDefault()
                                            console.log(blockAgentButtons, "blockAgentButtons")
                                            if (!blockAgentButtons) {
                                                openAddViewingModal(unit, navigate, dispatch)
                                            }
                                        }
                                        : undefined
                                    : undefined
                            }
                                className={`book-viewing-button fs-6 h-100 fw-semibold py-3 lh-1 ${blockAgentButtons ? 'opacity-50' : 'opacity-100'}`}>Book viewing</div>
                        </div>
                        <div className="col-4">
                            <div className={`btn btn-bso  m-0 fs-6 p-1 py-3  w-100 ${blockAgentButtons ? 'opacity-50' : 'opacity-100'}`}
                                onClick={loadSts
                                    ? verifiedStatus
                                        ? () => {
                                            if (!blockAgentButtons) {
                                                dispatch(userUpdateClickCount('offer'));
                                                void dispatch(scanDocumentsForOffer(unit.id, offerModalType, navigate, false))
                                            }
                                        }
                                        : undefined
                                    : undefined}
                            >
                                Make offer
                            </div>
                        </div>
                    </>
                }
                {fromEntry === 'makeOffer' && (
                    <div className="col-12">
                        <div className={`btn btn-bso  m-0 fs-6 p-1 py-3  w-100 ${blockAgentButtons ? 'opacity-50' : 'opacity-100'}`}
                            onClick={loadSts
                                ? verifiedStatus
                                    ? () => {
                                        if (!blockAgentButtons) {
                                            dispatch(userUpdateClickCount('offer'));
                                            void dispatch(scanDocumentsForOffer(unit.id, offerModalType, navigate, false))
                                        }
                                    }
                                    : undefined
                                : undefined
                            }>
                            Make offer
                        </div>
                    </div>
                )}
                {fromEntry === 'bookViewing' && (
                    <div className="col-12">
                        <div onClick={
                            loadSts
                                ? verifiedStatus
                                    ? (e) => {
                                        e.preventDefault()
                                        if (!blockAgentButtons) {
                                            openAddViewingModal(unit, navigate, dispatch)
                                        }
                                    }
                                    : undefined
                                : undefined}
                            className={`btn btn-bso m-0 fs-6 p-1 py-3 w-100 ${blockAgentButtons ? 'opacity-50' : 'opacity-100'}`}>Book viewing</div>
                    </div>
                )}
                {(fromEntry === 'catalogAgent' || fromEntry === 'other_agent_s_unit') && (
                    <>
                        <div className="col-6">
                            <div onClick={() => {
                                if (!blockAgentButtons) {
                                    dispatch(userUpdateClickCount('viewing-agent'));
                                    if (verifiedStatus) {
                                        navigate(`/agent/modal/agent_set_viewing_modal/${unit.id}/${userID}`)
                                    } else {
                                        setOpenAlert(true)
                                    }
                                }
                            }}
                                className={`book-viewing-button fs-6 h-100 fw-semibold  m-0 fs-6 p-1 py-3 w-100 ${blockAgentButtons ? 'opacity-50' : 'opacity-100'}`}>Book viewing</div>
                        </div>
                        <div className="col-6">
                            <div
                                onClick={() => {
                                    if (!blockAgentButtons) {
                                        dispatch(userUpdateClickCount('offer-agent'));
                                        navigate(`/agent/modal/agent_to_agent_make_offer/${unit.id}/${userID}`)
                                    }
                                }}
                                className={`btn btn-bso  m-0 fs-6 p-1 py-3  w-100 ${blockAgentButtons ? 'opacity-50' : 'opacity-100'}`}>
                                Make offer
                            </div>
                        </div>
                    </>
                )}
                {fromEntry === 'myUnitsAgent' && (
                    !actionLoading
                        ? <>
                            {userType === 'Agent'
                                ? <div className="col">
                                    <Link to={`/${userTypeLowercase}/modal/agent_edit_unit/${unit.id}/${propertyID}`} className="text-decoration-none">
                                        <div className="btn btn-bso  m-0 fs-6 p-1 py-3 w-100">Edit</div>
                                    </Link>
                                </div>
                                : <div className="col">
                                    <Link to={`/${userTypeLowercase}/set_unit_modal/edit_unit_modal/${unit.id}/-`} className="text-decoration-none">
                                        <div className="btn btn-bso  m-0 fs-6 p-1 py-3 w-100">Edit</div>
                                    </Link>
                                </div>}

                            {!unit.attributes.Statuses?.includes('Door close')
                                ? unit?.attributes?.Statuses != null && unit?.attributes?.Statuses.length > 0 &&
                                    unit.attributes.Statuses?.includes('Sold')
                                    ? <div className="col" onClick={() => {
                                        void swalAttentionLeave('Are you sure want to set enabled ?').then(async (result: any) => {
                                            if (result.isConfirmed) {
                                                setActionLoading(true)
                                                void dispatch(setUnitSold(unit.id, true))
                                                    .finally(() => {
                                                        setActionLoading(false)
                                                    })
                                            }
                                        }).catch(console.log)
                                    }}>
                                        <div className="book-viewing-button fs-6 h-100 fw-semi-bold py-3 cursor-pointer">Set enabled</div>
                                    </div>
                                    : <div className="col" onClick={() => {
                                        void swalAttentionLeave(`Are you sure want to set ${unit?.attributes?.Marketing?.Use === 'For sale' ? 'sold' : 'rented'}  ?`).then(async (result: any) => {
                                            if (result.isConfirmed) {
                                                setActionLoading(true)
                                                void dispatch(setUnitSold(unit.id, false))
                                                    .finally(() => {
                                                        setActionLoading(false)
                                                    })
                                            }
                                        }).catch(console.log)
                                    }}>
                                        <div className="btn btn-dark fs-6 m-0 fs-6 p-1 py-3 w-100 cursor-pointer">
                                            {
                                                unit?.attributes?.Marketing?.Use === 'For sale'
                                                    ? <> Set sold </>
                                                    : <> Set rented </>
                                            }
                                        </div>
                                    </div>
                                : null
                            }
                            {userType !== 'Agent'
                                ? (unit?.attributes?.Statuses?.includes('Drafted')
                                    ? <div className="col" onClick={() => {
                                        setActionLoading(true)
                                        void dispatch(setUnitHidden(unit.id, true))
                                            .finally(() => {
                                                setActionLoading(false)
                                            })
                                    }}>
                                        <div className="book-viewing-button fs-6 h-100 fw-semibold py-3 cursor-pointer">Show</div>
                                    </div>
                                    : <div className="col" onClick={() => {
                                        if (!unitHasOngoingTransactions) {
                                            setActionLoading(true)
                                            void dispatch(setUnitHidden(unit.id, false))
                                                .finally(() => {
                                                    setActionLoading(false)
                                                })
                                        }
                                    }}>
                                        <div className={`btn ${unitHasOngoingTransactions ? 'btn-secondary' : 'btn-dark'} fs-6 h-100 fw-semibold py-3 cursor-pointer w-100`}>
                                            Hide
                                        </div>
                                    </div>)
                                :
                                unit?.attributes?.Statuses?.includes('Archived') ?
                                    <div className="col">
                                        <div className="btn btn-bso m-0 fs-6 p-1 py-3 w-100"
                                            onClick={() => {
                                                dispatch(archiveAgentUnit(unit.id))
                                            }}
                                        >
                                            Revise unit
                                        </div>
                                    </div> :
                                    <div className="col">
                                        {unit?.attributes?.Statuses?.includes('Drafted') ?
                                            <div className="btn btn-bso m-0 fs-6 p-1 py-3 w-100"
                                                onClick={async () => {
                                                    const isPublished = await dispatch(publishAgentUnit(unit.id))
                                                    if (isPublished.isSuccessful) {
                                                        await swalSuccess(isPublished.textNotification, () => {
                                                            dispatch(setMyArchivedUnitsOpen(false))
                                                            dispatch(setMyDraftUnitsOpen(false))
                                                        })
                                                    } else {
                                                        await swalError(isPublished.textNotification)
                                                    }
                                                }}
                                            >
                                                Publish
                                            </div> :

                                            unit?.attributes?.Statuses?.includes('Sold') ?

                                                <div className="btn btn-bso m-0 fs-6 p-1 py-3 w-100"
                                                    onClick={() => { console.log("REVISE"); }}

                                                >
                                                    Make relevant
                                                </div>
                                                :
                                                <div className="btn btn-bso m-0 fs-6 p-1 py-3 w-100"
                                                    onClick={
                                                        (isBroadcastedUnit != null) ?
                                                            () => { navigate(`/${userTypeLowercase}/modalView/broadcast_modal/${unit.id}/${isBroadcastedUnit?.id as number}/-`) } :
                                                            priceBroadcast > currentUserMarketingCredits ?
                                                                () => {
                                                                    dispatch(setShowButtonBuyCoinsOnAdvertise(true))
                                                                    dispatch(setNotEnoughMarketingCredits(makePositiveOrUnchanged((priceBroadcast) - currentUserMarketingCredits)))
                                                                    navigate(`/${userTypeLowercase}/modal/agent_set_broadcast/${unit.id}/-`)
                                                                } : () => { navigate(`/${userTypeLowercase}/modal/agent_set_broadcast/${unit.id}/-`) }
                                                    }
                                                >
                                                    {(isBroadcastedUnit != null) ? "Show Broadcast" : "Broadcast"}
                                                </div>
                                        }
                                    </div>
                            }
                        </>
                        : <>
                            <div className="btn btn-bso  m-0 fs-6 p-1 py-3 w-100">Loading...</div>
                        </>
                )}

            </div>

        </div >

    )
}