import React, { useState } from 'react'
import _ from 'lodash'
import { useAppSelector } from '../../../../hooks/redux'
import { regExps } from '../../../../constants'
import { type RootState } from '../../../../store/store'

import CustomSelect from '../../../../app/custom/selects/CustomSelect'
import AreaInput from '../../../../app/custom/inputs/AreaInput'
import { Space, Switch } from 'antd'
import { getBanks } from '../../../../functions/getDataForSelects/dataFunctions'
import {
    type Control,
    Controller,
    type FieldErrors,
    type FieldValues,
    type UseFormRegister,
    type UseFormSetValue
} from 'react-hook-form'
import CustomFormLable from '../../../../app/custom/customModalUtils/CustomFormLable'
import DefaultInput from "../../../../app/custom/inputs/DefaultInput";
import GroupInput from "../../../../app/custom/inputs/GroupInput";
import { SpecialRequestSelectors } from "../../../../store/slices/setUnitSlice";
import { nanoid } from "nanoid";

interface AgentToBSOOfferAdditionalInfoProps {
    setValue: UseFormSetValue<FieldValues>
    errors: FieldErrors<any>
    register: UseFormRegister<any>
    control: Control<any>
    offerID: number
    unitID: number
}

export default function AgentToBSOOfferAdditionalInfo({
    setValue,
    errors,
    register,
    control,
    offerID,
    unitID
}: AgentToBSOOfferAdditionalInfoProps): JSX.Element {
    const units = useAppSelector((state: RootState) => state.units.units)
    const unit = units.find((unitItem) => unitItem.id === unitID)
    const offers = useAppSelector((state: RootState) => state.offers.offers)
    const offer = offers.find((offerItem) => offerItem.id === offerID)
    const config = useAppSelector((state) => state.app.config)

    const [hasPets, setHasPets] = useState(offer?.attributes?.Type[0]?.Pets === true)

    const additionalFieldsFilled = [
        offer?.attributes?.Type?.[0]?.UAEBank,
        offer?.attributes?.Type?.[0]?.InUAESince,
        offer?.attributes?.Type?.[0]?.Smoking,
        offer?.attributes?.Type?.[0]?.AdditionalNotes
    ].some(field => field != null)

    const [additionalInfoOpen, setAdditionalInfoOpen] = useState(additionalFieldsFilled)

    const smoking = offer?.attributes?.Type?.[0]?.Smoking === true
    const fitsUnitRequirements = offer?.attributes?.FitsSpecialRequest === true

    const isSale = unit?.attributes?.Marketing?.Use === 'For sale'
    const isCommercial = offer?.attributes?.Type?.[0]?.__component === 'offer.commercial'

    const rowTypeAdditionalNotes = {
        id: 'Type.AdditionalNotes',
        content: ['Additional Notes', "Note client's special needs"],
        element: 'input',
        maxLength: 5000,
        defaultValue: offer?.attributes?.Type[0]?.AdditionalNotes ?? undefined
    }
    const rowTypeClientOccupation = {
        id: 'Type.Occupation',
        content: ['Client Occupation', 'Enter client occupation'],
        element: 'input',
        required: true,
        defaultValue: offer?.attributes?.Type?.[0]?.Occupation ?? undefined
    }

    const rowTypeClientEmployer = {
        id: 'Type.Employer',
        content: ['Client Employer', 'Enter client employer'],
        element: 'input',
        required: true,
        defaultValue: offer?.attributes?.Type?.[0]?.Employer ?? undefined
    }

    const rowTypeClientOccupants = {
        id: 'Type.Occupants',
        content: ['Client Occupants', 'Select Client Occupants'],
        selectors: config?.offers?.Type?.Residential?.Occupants ?? [],
        element: 'select',
        required: true,
        defaultValue: offer?.attributes?.Type?.[0]?.Occupants ?? undefined
    }

    const rowTypePetType = {
        id: 'Type.PetType',
        content: ['Pet Type', 'Select pet type'],
        selectors: config?.offers?.Type?.Residential?.PetType ?? [],
        element: 'select',
        required: true,
        defaultValue: offer?.attributes?.Type?.[0]?.PetType ?? undefined
    }

    const rowTypeInUAESince = {
        id: 'Type.InUAESince',
        content: ['Client in UAE since', 'Year', 'Input valid year'],
        element: 'input-group',
        regexp: regExps.isYear,
        required: true,
        dateBeforeToday: true,
        defaultValue: offer?.attributes?.Type?.[0]?.InUAESince ?? undefined
    }

    const rowTypeUAEBank = {
        id: 'Type.UAEBank',
        content: ['Client Bank in UAE', 'Select bank'],
        selectors: getBanks(),
        element: 'select',
        required: true,
        defaultValue: offer?.attributes?.Type?.[0]?.UAEBank ?? undefined
    }

    return (
        <>

            <div className="fv-row  mt-8 p-0 w-100">

                {
                    !isSale && !isCommercial
                        ? <>
                            <DefaultInput row={rowTypeClientOccupation} register={register} errors={errors} />
                            <DefaultInput row={rowTypeClientEmployer} register={register} errors={errors} />
                            <div className="fv-row my-2 py-2 w-100 fv-plugins-icon-container">
                                <CustomFormLable row={rowTypeClientOccupants} />
                                <CustomSelect control={control} register={register} data={rowTypeClientOccupants} setValue={setValue} />
                                {
                                    _.get(errors, rowTypeClientOccupants.id) != null && (
                                        <div className="text-danger my-2">{String(_.get(errors, rowTypeClientOccupants.id)?.message)}</div>
                                    )
                                }
                            </div>
                            <div className="d-flex flex-wrap align-items-center my-2">
                                <div >
                                    <div className="fs-6 fw-bold mb-1 text-uppercase">Does client have pets ?</div>
                                </div>

                                <div className="ms-auto">
                                    <Space direction="vertical">
                                        <Controller
                                            control={control}
                                            name={'Type.Pets'}
                                            defaultValue={hasPets}
                                            render={({ field }) => (
                                                <Switch unCheckedChildren="NO"
                                                    {...field}
                                                    checkedChildren={<span style={{ color: '#504f4f', fontWeight: 'bold' }}>YES</span>}
                                                    onChange={(e) => {
                                                        setHasPets(e)
                                                        field.onChange(e)
                                                    }}
                                                />
                                            )}
                                        />
                                    </Space>
                                </div>
                            </div>
                            {
                                hasPets
                                    ? <div className="fv-row my-2 py-2 w-100 fv-plugins-icon-container">
                                        <CustomFormLable row={rowTypePetType} />
                                        <CustomSelect control={control} register={register} data={rowTypePetType} setValue={setValue} />
                                        {
                                            _.get(errors, rowTypePetType.id) != null && (
                                                <div className="text-danger my-2">{String(_.get(errors, rowTypePetType.id)?.message)}</div>
                                            )
                                        }
                                    </div>
                                    : <></>
                            }
                            <div className="separator separator-dashed my-4"></div>
                            <div className="d-flex flex-wrap align-items-center">
                                <div >
                                    <div className="fs-6 fw-bold mb-1 text-uppercase">Do you want to fill additional info ?</div>
                                </div>

                                <div className="ms-auto">
                                    <Space direction="vertical">
                                        <Controller
                                            control={control}
                                            name={'additional_info'}
                                            defaultValue={additionalInfoOpen}
                                            render={({ field }) => (
                                                <Switch unCheckedChildren="NO"
                                                    {...field}
                                                    checkedChildren={<span style={{ color: '#504f4f', fontWeight: 'bold' }}>YES</span>}
                                                    onChange={(e) => {
                                                        setAdditionalInfoOpen(e)
                                                        field.onChange(e)
                                                    }}
                                                />
                                            )}
                                        />
                                    </Space>
                                </div>
                            </div>
                            {
                                additionalInfoOpen
                                    ? <>
                                        <GroupInput register={register} errors={errors} row={rowTypeInUAESince} />
                                        <div className="fv-row my-2 py-2 w-100 fv-plugins-icon-container">
                                            <CustomFormLable row={rowTypeUAEBank} />
                                            <CustomSelect control={control} register={register} data={rowTypeUAEBank} setValue={setValue} />
                                            {
                                                _.get(errors, rowTypeUAEBank.id) != null && (
                                                    <div className="text-danger my-2">{String(_.get(errors, rowTypeUAEBank.id)?.message)}</div>
                                                )
                                            }
                                        </div>
                                        <div className="d-flex flex-wrap align-items-center my-4">
                                            <div >
                                                <div className="fs-6 fw-bold mb-1 text-uppercase">Does client smoke ?</div>
                                            </div>

                                            <div className="ms-auto">
                                                <Space direction="vertical">
                                                    <Controller
                                                        control={control}
                                                        name={'Type.Smoking'}
                                                        defaultValue={smoking}
                                                        render={({ field }) => (
                                                            <Switch unCheckedChildren="NO"
                                                                {...field}
                                                                checkedChildren={<span style={{ color: '#504f4f', fontWeight: 'bold' }}>YES</span>}
                                                                onChange={field.onChange}
                                                            />
                                                        )}
                                                    />
                                                </Space>
                                            </div>
                                        </div>
                                        <AreaInput
                                            register={register}
                                            errors={errors}
                                            row={rowTypeAdditionalNotes}
                                        />
                                    </>
                                    : <></>
                            }
                            <div className="separator separator-dashed my-4"></div>
                            <div className={'my-4 d-flex flex-wrap gap-2'}>
                                {
                                    Object.keys(unit?.attributes?.SpecialRequest ?? {})
                                        .filter((requirement) => unit?.attributes?.SpecialRequest?.[requirement] === true)
                                        .map((requirement) => {
                                            const label = SpecialRequestSelectors.find((selector) => selector?.value === requirement)?.label
                                            return (
                                                <div key={nanoid()} className={'badge badge-danger'}>
                                                    {
                                                        label
                                                    }
                                                </div>
                                            )
                                        })
                                }
                            </div>
                            <div className="d-flex flex-wrap align-items-center">
                                <div >
                                    <div className="fs-6 fw-bold mb-1 text-uppercase">Does your offer fit the unit requirements?</div>
                                </div>

                                <div className="ms-auto">
                                    <Space direction="vertical">
                                        <Controller
                                            control={control}
                                            name={'FitsSpecialRequest'}
                                            defaultValue={fitsUnitRequirements}
                                            render={({ field }) => (
                                                <Switch unCheckedChildren="NO"
                                                    {...field}
                                                    checkedChildren={<span style={{ color: '#504f4f', fontWeight: 'bold' }}>YES</span>}
                                                    onChange={field.onChange}
                                                />
                                            )}
                                        />
                                    </Space>
                                </div>
                            </div>
                        </>
                        : <>
                            <AreaInput
                                register={register}
                                errors={errors}
                                row={rowTypeAdditionalNotes}
                            />
                        </>
                }
            </div >

        </>

    )
}
