import { createSlice, type PayloadAction } from '@reduxjs/toolkit'

import { type IAdvertise } from './advertisesSlice'
export type UnitsCategory = 'all' | 'rent' | 'sell' | "agents" | "landlords" 

export interface IMarketing {
    id: number
    Headline: null | string
    Price: null | number
    Status: null | string
    Use: null | string
    DiscountHeadline: null | string
    DiscountPrice: null | string
    DiscountStartDatetime: null | string
    DiscountEndDatetime: null | string
    Description: null | string
    MarketingFormTemplate: null | string
    Checks: null | number
}

export interface IUtility {
    id: number
    BuildingManagementName: null | string
    CoolingProviderName: null | string
    GasSupplierName: null | string
}

export interface IUnitKey {
    id: number
    attributes: {
        PickUpDate: string
        DropOutDate: string
        Status: string | null
        Holder: { data: {
            id: number
            attributes: { } } } | null
        createdAt: string
        updatedAt: string
    }

}
export interface IUnitKeys {

    data: IUnitKey[] | []
}
export interface IProperty {

    data: {
        id: number
        attributes: {
            createdAt: string
            updatedAt: string
            Developer: null
            Name: null | string
            Area: null | string
            PlotNumber: null | string
            Images: null | string[]
            Statuses: null | string
            MAKANI: null | string
            Type: null | string
            Community: null | string
            GoogleMapsLink: null | string
            Address: null | string
            Utilities: IUtility[]
            Services: string[]
        }

    }

}

interface DocumentData {
    id: number
    attributes: {}
}

interface Documents {
    data: DocumentData[] | DocumentData[]
}

export interface Unit {
    id: number
    attributes: {
        Offers: any
        createdAt: string
        updatedAt: string
        RefID: string
        GroupID: null | number
        Number: number
        SizeArea: number
        Bedrooms: number
        Bathrooms: number
        Type: string
        Fit: string
        View: string
        Features: string[]
        Amenities: string[]
        Appliances: string[]
        Viewings?: any
        Images: string[]
        ManagedBy: string
        Statuses: string[]
        Property?: IProperty
        Marketing?: null | IMarketing
        UnitKeys?: IUnitKeys
        AdvertisePrice?: null | number
        Advertises?: null | { data: IAdvertise[] }
        Video?: string
        FloorPlan?: string
        ImportantNotice?: string
        KeysAmount?: number
        Documents: Documents
        AdvertisementSpaces: number
        AnnualServiceCharge?: number
        ParkingSlotsAmount?: number
        Landlords: any[]
        User: {
            data: null | {
                id: number
                attributes: Record<string, any>
            }
        }
        Tasks: {
            data: Array<{
                attributes: {
                    Type: string
                    Statuses: string[]
                    createdAt: string
                    updatedAt: string
                    Subject: string
                    Description: string
                    DueDate: string
                    StartDate: string
                }
            }>
        }
        CreateDate?: any
        SpecialRequest?: Record<string, boolean | null>
        WaitingList?:  Array<Record<string, any>> | null 
        ImagesOnly : boolean | null
        SpecialBrokers : any
        DisabledTimes : any
        Services?: any
        PMCs?: any[]
    }
}

interface UnitsState {
    units: Unit[]
    catalog: Unit[]
    unitsLoading: boolean
    error: string
    filters: Record<string, any>
    searchFilters: Record<string, any>
    areasFound: boolean
    noAreasFound: boolean
    areasPageCount: number
    searchString: string

    unitsCategory: UnitsCategory

    searchCatalog: Unit[]
    searchSearchFilters: Record<string, any>
    searchAreasFound: boolean
    searchNoAreasFound: boolean
    searchAreasPageCount: number
    searchSearchString: string

    page: number
    pageCount: number
    searchPage: number
    searchPageCount: number

    catalogLoading: boolean
    searchCatalogLoading: boolean

    catalogScrollPosition: number
    searchCatalogScrollPosition: number
    myUnitsScrollPosition: number
    myFavoritesUnitsOpen: boolean
    myArchivedUnitsOpen: boolean
    myDraftUnitsOpen: boolean
    typeUnitToCreate : string
}

const initialState: UnitsState = {
    catalog: [],
    units: [],
    unitsLoading: false,
    error: '',
    filters: {},
    searchFilters: {},
    areasFound: false,
    noAreasFound: false,
    areasPageCount: 0,
    searchString: '',

    unitsCategory: 'rent',

    searchCatalog: [],
    searchSearchFilters: {},
    searchAreasFound: false,
    searchNoAreasFound: false,
    searchAreasPageCount: 0,
    searchSearchString: '',

    page: 1,
    pageCount: 0,
    searchPage: 1,
    searchPageCount: 0,
    catalogLoading: false,
    searchCatalogLoading: false,
    catalogScrollPosition: 0,
    myUnitsScrollPosition: 0,
    searchCatalogScrollPosition: 0,
    myArchivedUnitsOpen: false,
    myFavoritesUnitsOpen: false,
    myDraftUnitsOpen : false ,
    typeUnitToCreate: "Apartment"
}

export interface UpdateUnitPayload {
    viewing: any
    unit_id: any
    unit_key?: any
}

export const unitsSlice = createSlice({
    name: 'units',
    initialState,
    reducers: {
        unitsSetCatalog (state, action) {
            state.catalog = action.payload
        },
        unitsFetching (state) {
            state.unitsLoading = true
        },
        unitsNotFetching (state) {
            state.error = ''
            state.unitsLoading = false
        },
        unitsFetchingSuccess (state, action) {
            state.error = ''
            state.units = action.payload
            state.unitsLoading = false
        },
        unitsFetchingError (state, action) {
            state.unitsLoading = false
            state.error = action.payload.message
        },
        unitsSetFilters (state, action) {
            state.filters = action.payload
        },
        unitsSetSearchString (state, action) {
            state.searchString = action.payload
        },
        unitsSetSearchFilters (state, action) {
            state.searchFilters = action.payload
        },
        unitsSetAreasFound (state, action) {
            state.areasFound = action.payload
        },
        unitsSetNoAreasFound (state, action) {
            state.noAreasFound = action.payload
        },
        unitsSetAreasPageCount (state, action) {
            state.areasPageCount = action.payload
        },
        updateUnitAfterReservViewing (state, action: PayloadAction<UpdateUnitPayload, string>): void {
            const prevUnits = [...state.units]
            const payloadViewing = action.payload
            const payloadUnitKey = action.payload?.unit_key
            const actualUnits = prevUnits.map((unit) => {
                if (unit.id === Number(payloadViewing.unit_id)) {
                    const prevViewings = unit.attributes?.Viewings?.data ?? []
                    const prevUnitKeys = unit.attributes?.UnitKeys?.data ?? []
                    const actualViewings = [...prevViewings, payloadViewing.viewing]
                    const actualUnitKeys = prevUnitKeys.map((key: any) => {
                        if (key.id === payloadUnitKey?.id) {
                            return payloadUnitKey
                        }
                        return key
                    })

                    return {
                        ...unit,
                        attributes: {
                            ...unit.attributes,
                            UnitKeys: { data: actualUnitKeys },
                            Viewings: { data: actualViewings }
                        }
                    }
                } else {
                    return unit
                }
            })

            state.units = actualUnits
        },
        addNewUnit: (state, action) => {
            state.error = ''
            const findDublicate = state.units.find((unit) => unit.id === action.payload.id)
            if (findDublicate === undefined) {
                const actualUnits = [...state.units, action.payload]
                state.units = actualUnits
            }
        },
        // updateUnitAfterCreateAdvertise (state, action): void {
        //     const prevUnits = [...state.units]
        //     const payloadAdvertise = action.payload

        //     const actualUnits = prevUnits.map((unit) => {
        //         if (unit.id === Number(payloadAdvertise.attributes.Unit.data.id)) {
        //             const prevAdvertises = unit.attributes?.Advertises?.data ?? []
        //             const actualAdvertises = [...prevAdvertises, payloadAdvertise]

        //             return {
        //                 ...unit,
        //                 attributes: {
        //                     ...unit.attributes,
        //                     Advertises: { data: actualAdvertises }
        //                 }
        //             }
        //         } else {
        //             return unit
        //         }
        //     })

        //     state.units = actualUnits
        // },
        setSearchSearchFilters (state, action) {
            state.searchSearchFilters = action.payload
        },
        setSearchAreasFound (state, action) {
            state.searchAreasFound = action.payload
        },
        setSearchNoAreasFound (state, action) {
            state.searchNoAreasFound = action.payload
        },
        setSearchAreasPageCount (state, action) {
            state.searchAreasPageCount = action.payload
        },
        setSearchSearchString (state, action) {
            state.searchSearchString = action.payload
        },

        unitsSetPage (state, action) {
            state.page = action.payload
        },
        unitsSetPageCount (state, action) {
            state.pageCount = action.payload
        },
        unitsSetSearchPage (state, action) {
            state.searchPage = action.payload
        },
        unitsSetSearchPageCount (state, action) {
            state.searchPageCount = action.payload
        },
        unitsSetCatalogLoading (state, action) {
            state.catalogLoading = action.payload
        },
        unitsSetSearchCatalogLoading (state, action) {
            state.searchCatalogLoading = action.payload
        },
        setUnitsCategory (state, action) {
            state.unitsCategory = action.payload
        },
        setUnitsSearchCatalog (state, action) {
            state.searchCatalog = action.payload
        },
        setUnitsCatalogScrollPosition (state, action) {
            state.catalogScrollPosition = action.payload
        },
        setMyUnitsScrollPosition (state, action) {
            state.myUnitsScrollPosition = action.payload
        },
        setUnitsSearchCatalogScrollPosition (state, action) {
            state.searchCatalogScrollPosition = action.payload
        },
        setMyArchivedUnitsOpen (state, action) {
            state.myArchivedUnitsOpen = action.payload
        },
        setTypeUnitToCreate (state, action) {
            state.typeUnitToCreate = action.payload
        },
        setMyFavoritesUnitsOpen (state, action) {
            state.myFavoritesUnitsOpen = action.payload
        },

        setMyDraftUnitsOpen (state, action) {
            state.myDraftUnitsOpen = action.payload
        },
        
        
    }
})
export const {
    updateUnitAfterReservViewing,
    unitsSetCatalog,
    unitsFetching,
    unitsNotFetching,
    unitsFetchingSuccess,
    unitsFetchingError,
    unitsSetFilters,
    unitsSetSearchString,
    unitsSetSearchFilters,
    unitsSetAreasFound,
    unitsSetNoAreasFound,
    unitsSetAreasPageCount,
    setSearchSearchFilters,
    setSearchAreasFound,
    setSearchNoAreasFound,
    setSearchAreasPageCount,
    setSearchSearchString,
    unitsSetPage,
    unitsSetPageCount,
    unitsSetSearchPage,
    unitsSetSearchPageCount,
    unitsSetCatalogLoading,
    unitsSetSearchCatalogLoading,
    setUnitsCategory,
    setUnitsSearchCatalog,
    setUnitsCatalogScrollPosition,
    setUnitsSearchCatalogScrollPosition,
    setMyUnitsScrollPosition,
    setMyArchivedUnitsOpen,
    addNewUnit,
    setMyFavoritesUnitsOpen,
    setMyDraftUnitsOpen,
    setTypeUnitToCreate
    // updateUnitAfterCreateAdvertise
} = unitsSlice.actions

export default unitsSlice.reducer
