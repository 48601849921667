import { type NavigateFunction } from 'react-router-dom'
import { type AppDispatch, store } from '../../store/store'
import { type User, type ISettings } from '../../store/slices/userSlice'
import { showNegotiateSwals } from '../../functions/agent/offers/showNegotiateSwals'
import awaitAction from '../../functions/utils/awaitAction'
import { getLatestServiceAgentSubscription } from '../../functions/agent/subscriptions/getLatestServiceAgentSubscription'
import { USER_ID_BACK_ID_LOAD } from '../../urls'

export const AgentLoadProcessData = async (navigate: NavigateFunction, dispatch: AppDispatch, location: any): Promise<void> => {
    const queryParams = new URLSearchParams(location.search)
    const status = queryParams.get('status')

    const statusesApproved = store.getState().agentStates.statusesApproved
    const verifiedStatus = statusesApproved.emiratesApproved || statusesApproved.passportApproved
    let redirectModule = '/agent/modal/update_emirates_front_together/INagentMODULE/first'

    const currentUser = store.getState().user.currentUser

    if (currentUser?.tutorialCompleted) {
        if (!currentUser?.emailVerified) {
            redirectModule = '/agent/units/nav'
        } else {
            if (verifiedStatus) { 
                if (currentUser?.Orders && Array.isArray(currentUser.Orders) && currentUser.Orders.length > 0) {
                    if ((currentUser.Settings as ISettings[])?.[0]?.Areas?.length > 0) {
                        if (location.pathname.includes('agent_settings_for_subscriptions') && status === 'true' && !location.pathname.includes('Premium')) {
                            redirectModule = location.pathname
                        } else if (location.pathname.includes('/agent/modal/agent_advertise_create_duration/')) {
                            redirectModule = location.pathname
                        } else if (location.pathname.includes('/agent/chats/')) {
                            redirectModule = location.pathname
                        } else if (location.pathname.includes('/agent/modalView/viewing_modal/')) {
                            redirectModule = location.pathname
                        } else if (location.pathname.includes('/agent/modalView/unit_modal/')) {
                            redirectModule = location.pathname
                        } else if (location.pathname.includes('/agent/modal/agent_settings_for_areas_extension/')) {
                            redirectModule = location.pathname
                            // TO DO логика по редиректу если не загрузил bэк сайд
                        } else if (window.localStorage.getItem(USER_ID_BACK_ID_LOAD) === 'true') {
                            redirectModule = '/agent/modal/update_emirates_back/not/not'
                        } else {
                            redirectModule = '/agent/units/nav'
                        }
                    } else {
                        if (currentUser?.Orders && Array.isArray(currentUser.Orders)) {
                            const latestServiceAgentSubscription = getLatestServiceAgentSubscription(currentUser?.Orders)
                            const service = latestServiceAgentSubscription?.Service?.[0]
                            if (service?.Package?.includes('Standard')) {
                                redirectModule = `/agent/modal/agent_settings_for_subscriptions/${currentUser.id}/Standard`          
                            } else {
                                redirectModule = `/agent/modal/agent_settings_for_subscriptions/${currentUser.id}/Free`
                            }
                        }
                    }
                } else {
                    redirectModule = '/agent/settings/subscriptions/choose'
                }
            } else {
                if (location.pathname.includes('agent_settings_for_subscriptions') && status === 'true' && !location.pathname.includes('Premium')) {
                    redirectModule = location.pathname
                } else if (location.pathname.includes('/agent/modal/agent_advertise_create_duration/')) {
                    redirectModule = location.pathname
                } else if (location.pathname.includes('/agent/chats/')) {
                    redirectModule = location.pathname
                } else if (location.pathname.includes('/agent/modalView/viewing_modal/')) {
                    redirectModule = location.pathname
                } else if (location.pathname.includes('/agent/modal/agent_settings_for_areas_extension/')) {
                    redirectModule = location.pathname
                } else if (currentUser?.Orders && Array.isArray(currentUser.Orders) && currentUser.Orders.length > 0) {
                    if ((currentUser.Settings as ISettings[])?.[0]?.Areas?.length > 0) {
                        redirectModule = '/agent/units/nav'
                    } else {
                        if (currentUser?.Orders && Array.isArray(currentUser.Orders)) {
                            const latestServiceAgentSubscription = getLatestServiceAgentSubscription(currentUser?.Orders)
                            const service = latestServiceAgentSubscription?.Service?.[0]
                            if (service?.Package?.includes('Standard')) {
                                redirectModule = `/agent/modal/agent_settings_for_subscriptions/${currentUser.id}/Standard`
                            } else {
                                redirectModule = `/agent/modal/agent_settings_for_subscriptions/${currentUser.id}/Free`
                            }
                        } else {
                            redirectModule = '/agent/units/nav'
                        }
                    }
                } else {
                    redirectModule = '/agent/units/nav'
                }
            }
        }
    } else if ((currentUser?.tutorialCompleted === false || currentUser?.tutorialCompleted === null) && !currentUser?.emailVerified) {
        if ((currentUser as User)?.ContactInfo === null) {
            const updUser = store.getState().user.currentUser
            const isThereCurrenDoc = updUser?.Documents !== null && Array.isArray(updUser?.Documents) && updUser?.Documents.length > 0 && updUser?.Documents[0]?.LinkBack === null && updUser?.Documents[0]?.LinkFront !== null
            if (isThereCurrenDoc) {
                redirectModule = '/auth/register_scan_emirates' 
            } else {
                redirectModule = '/auth/register_scan_emirates_front' 
            }
        }
    }
    navigate(redirectModule)

    awaitAction(() => {
        void showNegotiateSwals(currentUser as User, dispatch, navigate)
    }, [
        {
            path: 'swalsStates.oneCycleShowPopupsViewingReschedule',
            checker: (val: boolean) => !val
        },
        {
            path: 'swalsStates.showPopupViewingFeedback',
            checker: (val: boolean) => !val
        },
        {
            path: 'swalsStates.showPopupViewingReschedule',
            checker: (val: boolean) => !val
        },
        {
            path: 'swalsStates.newViewingRescheduleSocket',
            checker: (val: boolean) => !val
        },
        {
            path: 'swalsStates.newViewingFeedbackSocket',
            checker: (val: boolean) => !val
        }
    ])
}
