import React, {type ReactElement} from "react";
import styles from './Separator.module.sass'
interface SeparatorProps {
    color?: 'gray-300' | 'gray-400'
}

export default function Separator ({color = 'gray-300'}: SeparatorProps): ReactElement {
    return <div className={styles.separator}>
        <div className={
            styles[`separator-${color}`]
        }/>
    </div>
}