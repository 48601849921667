
import React, {Fragment, useEffect, useRef, useState} from 'react'
import { Link, useNavigate } from 'react-router-dom'
import fillModalLink from '../../../functions/agent/modals/fillModalLink'
import { useAppDispatch, useAppSelector } from '../../../hooks/redux'
import { store, type RootState } from '../../../store/store'
import { swalAttentionLeave, swalError, swalSuccess } from '../../../functions/swalOptions'
import { type IResponseObjectJSON } from '../../../types/userTypes/TAuth'
import { createLinksAdvertiseAction } from '../../../store/actionsCreators/advertiseActions/createLinksAdvertiseAction'

import { reduceLinks, type StateLink } from '../../../functions/agent/advertises/reduceLinks'
import { archiveUnit } from '../../../store/actionsCreators/unitsActions'
import { deleteAgentUnit } from '../../../store/actionsCreators/unitActions/deleteAgentUnit'
import { UniversalBuyBricks } from '../../../components/shared/bricks/UniversalBuyBricks'
import TextButton from "../../../components/shared/UIv2/Button/TextButton/TextButton";
import {nanoid} from "nanoid";
import SubmitButton from "./FooterActions/SubmitButton";
import ResetButton from "./FooterActions/ResetButton";
import FooterUpSale from "./FooterActions/FooterUpSale";
import FooterCount from "./FooterActions/FooterCount";

export default function CustomModalFooter ({ modal, form, objectID, unitID, errors, control, setError, setValue, watch }: any): JSX.Element {
    const userDevice = useAppSelector((state) => state.app.userDevice)
    const submitRef = useRef<HTMLButtonElement>(null)
    const resetRef = useRef<HTMLButtonElement>(null)

    const modalLoading = useAppSelector(state => state.modal.loading)
    const authLoading = useAppSelector(state => state.auth.loading)

    const loading = modalLoading || authLoading

    const service = useAppSelector((state) => state.service)
    const showButtonBuyCoinsOnAdvertise = useAppSelector((state: RootState) => state.modal.showButtonBuyCoinsOnAdvertise)

    const dispatch = useAppDispatch()

    const navigate = useNavigate()
    let sumPay = 0
    for (const el of service.dop) {
        sumPay += Number(el.price)
    }
    const units = useAppSelector((state: RootState) => state.units.units)
    const statusUnit = units.filter((_unit: any) => _unit.id === Number(unitID))?.[0]?.attributes?.Statuses

    const antdSubmitHandler = async (callback: any): Promise<void> => {
        const currentAdvertise = store.getState().advertises.advertises?.filter((adv: any) => adv.id === Number(objectID))?.[0]
        const defaultLinks: StateLink[] = reduceLinks(currentAdvertise)
        if (form.getFieldValue('links').length === defaultLinks.length) {
            return
        }

        form
            .validateFields()
            .then(async () => {
                const json: IResponseObjectJSON = await dispatch(callback)
                if (json.isSuccessful) {
                    void swalSuccess(json.textNotification, () => {
                        navigate(json.textNavigate)
                    })
                } else {
                    void swalError(json.textNotification, () => {
                        navigate(json.textNavigate)
                    })
                }
            })
            .catch((errorInfo: any) => {
                //  console.log('Form errors:', errorInfo.errorFields)
            })
    }

    const deleteUnitHandler = async (): Promise<void> => {
        void swalAttentionLeave(
            statusUnit?.includes('Archived')
                ? 'Are you sure you want to delete the unit? It will be impossible to restore it'
                : 'Are you sure you want to move the object to the archive? You can restore it later'

        ).then(async (result: any) => {
            if (result.isConfirmed) {
                if (statusUnit.includes('Archived')) {
                    const resultDelete = await dispatch(deleteAgentUnit(Number(unitID)))
                    if (resultDelete.success && resultDelete.response) {
                        void swalSuccess('Unit deleted successfully', () => {
                            navigate('/agent/my_units')
                        })
                    }
                } else {
                    const resultArchive = await dispatch(archiveUnit(Number(unitID)))
                    if (resultArchive.success && resultArchive.response) {
                        void swalSuccess('Unit has archived successfully', () => {
                            navigate('/agent/my_units')
                        })
                    }
                }
            }
        }).catch(console.log)
    }

    const [isShowFooter, setIsShowFooter] = useState(true)
    const showPopupViewingReschedule = useAppSelector((state: RootState) => state.swalsStates.showPopupViewingReschedule)

    useEffect(() => {
        if (showPopupViewingReschedule) {
            setIsShowFooter(false)
        } else {
            setIsShowFooter(true)
        }
    }, [showPopupViewingReschedule])

    return (
        <>
            <div
                className="w-100"
            >
                <div className={`d-flex flex-column w-100 gap-6 ${/* userDevice === 'IPhone' ? 'pb-8' : 'pb-4' */'pb-0'}`}>
                    {isShowFooter
                        ? modal?.[0]?.footer?.map((row: any) => (
                            <Fragment key={nanoid()}>
                                {row.element === 'button-custom-secondary-action' && !loading &&
                                (
                                    <button className={`btn btn-secondary mt-2 ${userDevice === 'IPhone' ? 'mb-6' : ''} p-4 w-100 mx-1`}
                                        onClick={row.onClick}
                                    >
                                        <span className="indicator-label">{row.content?.[0]}</span>
                                    </button>
                                )
                                }
                                {
                                    row.element === 'footer-up-sale' &&
                                    (
                                        <FooterUpSale
                                            row={row}
                                            control={control}
                                            setError={setError}
                                            loading={loading}
                                        />
                                    )
                                }
                                {
                                    row.element === 'service-footer-count' &&
                                    (
                                        <FooterCount
                                            modal={modal[0]}
                                            row={row}
                                            control={control}
                                            setValue={setValue}
                                            watch={watch}
                                        />
                                    )
                                }
                                {
                                    row.element === 'modal-reset-button' && (
                                        <ResetButton
                                            resetRef={resetRef}
                                            row={row}
                                            errors={errors}
                                            loading={loading}
                                        />
                                    )
                                }
                                {
                                    row.element === 'modal-submit-button' && (
                                        <SubmitButton
                                            submitRef={submitRef}
                                            row={row}
                                            errors={errors}
                                            loading={loading}
                                        />
                                    )
                                }
                            </Fragment>
                        ))
                        : null
                    }

                </div>

            </div>
            <button type={'submit'} ref={submitRef} className={'d-none'}/>
            <button type={'reset'} ref={resetRef} className={'d-none'}/>
        </>
    )
}
