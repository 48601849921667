import React, { useEffect, useRef, useState } from 'react'
import { Modal } from 'antd'
import SlideFirst from '../../tutorials/SlideFirst'
import { nanoid } from 'nanoid'
import { useNavigate } from 'react-router-dom'
import { type CarouselRef } from 'antd/lib/carousel'

interface IProps {
    title: string
    imgPath: string
    contentArray: any
}

function SlideManual({ imgPath, title, contentArray }: IProps): JSX.Element {
    const [modalOpen, setModalOpen] = useState(false)

    const [carouselCurrent, setCarouselCurrent] = useState(0)
    const carouselRefManuals = useRef<CarouselRef>(null)
    const [showClose, setShowClose] = useState(false)

    useEffect(() => {
        if (modalOpen) {
            carouselRefManuals.current?.goTo(0)
        }
    }, [modalOpen])

    const handleSlideChange = (index: number): void => {
        setCarouselCurrent(index)
        if (index === contentArray.length - 1) {
            setShowClose(true)
        }
    }
    // console.log(carouselCurrent, 'carouselCurrent')
    useEffect(() => {
        if (!showClose) {
            const interval = setInterval(() => {
                if (contentArray.length - 1 !== carouselCurrent) {
                    carouselRefManuals.current?.next()
                }
            }, 8000)
            return () => { clearInterval(interval) }
        }
    }, [showClose])

    const handleLeftClick = (): void => {
        if (carouselCurrent !== 0) {
            carouselRefManuals.current?.prev()
        }
    }

    const handleNextClick = (): void => {
        if (contentArray.length - 1 !== carouselCurrent) {
            carouselRefManuals.current?.next()
        }
    }

    const handleClose = (): void => {
        setModalOpen(false)
    }


    const { innerHeight, innerWidth } = window

    const aspectRatio = innerHeight / innerWidth;

    const currentHeight = Math.floor(375 * aspectRatio);
    console.log(currentHeight, "currentHeight")
    console.log(innerHeight, "innerHeight")
    return (
        <>
            <div key={nanoid()} className={'w-100px h-100px  '}
                onClick={() => {
                    setModalOpen(true)
                }}
            >
                <div style={{ borderRadius: '0.6rem', verticalAlign: 'bottom' }}
                    className="w-100px h-100px d-flex  position-relative overflow-hidden border border-3 border-gray-500">
                    {/* <div className="z-index-1 position-absolute rounded" style={{ border: '2px solid #fff200', height: 'calc(100% - 4px)', width: 'calc(100% - 4px)', margin: '2px' }} /> */}
                    {/* <div className="p-4 z-index-1"> */}
                    {/*    <p className="fs-6 text-dark fw-bold lh-1 text-shadow">{el.title}</p> */}
                    {/* </div> */}
                    <img style={{ borderRadius: '0.6rem' }} className="position-absolute z-index-0 h-100 " src={imgPath} alt="" />
                    <div className={'d-flex align-items-end w-100  h-100  position-absolute z-index-2 overlay '}
                        style={{ backgroundColor: 'rgba(0, 0, 0, 0.4)', verticalAlign: 'bottom' }}>
                        <div className="px-2 py-0 z-index-1">
                            {/* style={{ background: 'linear-gradient(to top, rgba(0, 0, 0, 0.6),transparente )' }} */}
                            <p className="fs-6  text-white fw-semi-bold  lh-1  ">{title}</p>
                        </div>
                    </div>
                </div>
            </div>
            <Modal
                //  centered
                open={modalOpen}
                style={{
                    left: 0,
                    top: 0,
                    height: `${currentHeight}px`,
                    width: "100% !important",
                    maxWidth: '450px !important',
                    maxHeight: `${currentHeight}px`,
                    overflow: "hidden",
                    zIndex: 1501,
                }}
                closable={false}
                footer={null}
            >

                <div
                    className="position-absolute h-100 w-100  mx-auto"
                    style={{ top: 0, left: 0, zIndex: 9998, pointerEvents: "none", }}
                >
                    <div
                        className="row h-100 w-100"
                        style={{ margin: 0 }}
                    >

                        <div
                            className="col-6 h-100"
                            style={{ opacity: 0, pointerEvents: "auto" }}
                            onClick={handleLeftClick}
                        ></div>

                        <div
                            className="col-6 h-100"
                            style={{ opacity: 0, pointerEvents: "auto" }}
                            onClick={handleNextClick}
                        ></div>
                    </div>
                </div>


                <span
                    className="position-absolute"
                    style={{
                        top: "40px",
                        right: "20px",
                        zIndex: 9999,
                        cursor: "pointer",
                    }}
                    onClick={() => { handleClose(); }}
                >
                    <i className="fs-6x fs-lg-4x ki-duotone ki-cross-square">
                        <span
                            className="path1 text-dark"
                            style={{ filter: "brightness(0)" }}
                        ></span>
                        <span
                            className="path2 text-white"
                            style={{ filter: "brightness(1)" }}
                        ></span>
                    </i>
                </span>


                <SlideFirst onChange={handleSlideChange} ref={carouselRefManuals}>
                    {contentArray.map((el: any) => (
                        <div
                            key={nanoid()}
                            className="h-100 d-flex justify-content-center align-items-center mx-auto"
                        >
                            <div
                                className="position-relative overflow-hidden"
                                style={{

                                    height: `${currentHeight}px`,
                                    zIndex: 10000,
                                    objectFit: "contain"
                                }}
                            >
                                <img
                                    src={el.img}
                                    alt="example"
                                    className="position-relative overflow-hidden"
                                    style={{
                                        // height: `${currentHeight}px`,
                                        maxWidth: '100%', // Ограничиваем ширину по экрану
                                        maxHeight: '100%', // Ограничиваем высоту по экрану
                                        objectFit: 'contain', // Сохраняем пропорции
                                    }}
                                />
                            </div>
                        </div>
                    ))}
                </SlideFirst>
            </Modal>
        </>

    )
}

export default SlideManual
