import { type AppDispatch, type RootState } from '../../../store'
import { dataFetchingFinish, modalSlice } from '../../../slices/modalSlice'
import { ACCESS_KEY, urlAPI } from '../../../../urls'
import QueryString from 'qs'
import { offersSlice } from '../../../slices/offersSlice'
import { getErrorss } from '../../../../functions/errors/GetErrors'
import { setFilterNavigate } from '../../../slices/scheduleSlice'
import {
    deleteAgentToBSOOfferCommunicationStorageInfo, readAgentToOfferCommunicationStorageInfo,
    writeAgentToBSOOfferCommunicationStorageInfo
} from './agentToBSOOfferCommunicationStorage'
import { offersQuery, sendNotification } from '../../offerActions'

export const makeOfferAgentToBSOOfferInfo = (unitID: number, offerID: number) => (data: Record<string, any>) => async (dispatch: AppDispatch, getState: () => RootState) => {
    dispatch(modalSlice.actions.dataFetchingStart())
    try {
        const jwt = window.localStorage.getItem(ACCESS_KEY)
        const unit = getState().units.units.find((unitItem) => unitItem.id === unitID)
        const offer = getState().offers?.offers?.find((offer) => offer.id === Number(offerID))
        if (unit != null && offer != null && offer?.attributes?.Type?.[0]?.__component != null) {
            const isSale = unit.attributes?.Marketing?.Use === 'For sale'
            const offerType = {
                ...offer?.attributes?.Type?.[0],
                Offer: data.Type.Offer,
                Payments: data.Type.Payments,
                AdditionalNotes: data.Type.AdditionalNotes,
                ...(
                    isSale
                        ? {
                            BankName: data.Type.BankName,
                            BankPreApproval: data.Type.PreApprovedAmount != null,
                            PreApprovedAmount: data.Type.PreApprovedAmount,
                            Type: data.Type.Type
                        }
                        : {
                            Duration: data.Type.Duration,
                            ContractStartDate: data.Type.ContractStartDate
                        }
                )
            }
            const bodyObject = {
                method: 'PUT',
                collection: 'offers',
                id: offerID,
                query: offersQuery,

                body: {
                    Type: [offerType],
                    Notify: 'Both',
                }
            }
            const response = await fetch(`${urlAPI}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'BSO-Hash': '02ns30nsBSO!',
                    Authorization: `Bearer ${String(jwt)}`
                },
                body: JSON.stringify(bodyObject)
            })
            const responseJSON = await response.json()
            if (responseJSON.success === true) {
                if (responseJSON.response.error != null) {
                    dispatch(offersSlice.actions.offersFetchingError(getErrorss(responseJSON)))
                    dispatch(dataFetchingFinish())
                    return { ...responseJSON, textNavigate: '', isSuccessful: false, textNotification: getErrorss(responseJSON) }
                } else {
                    dispatch(offersSlice.actions.updateOffer(responseJSON.response.data))
                    // void sendNotification(new Date(new Date().getTime() + 10000), Number(getState().user.currentUser.id), responseJSON.response.data ?? {}, 'admin-offer-in-process', 'Offer - in process')
                    const { variant } = readAgentToOfferCommunicationStorageInfo() ?? {}
                    writeAgentToBSOOfferCommunicationStorageInfo(unitID, Number(responseJSON?.response?.data?.id), 'offer_info', variant ?? 'create')
                    dispatch(modalSlice.actions.dataFetchingFinish())
                    return {
                        textNavigate: `/agent/modal/${variant !== 'resubmit' ? 'agent_to_bso_offer_additional_info' : 'agent_to_bso_resubmit_additional_info'}/${unitID}/${String(responseJSON?.response?.data?.id)}`,
                        isSuccessful: true,
                        textNotification: ''// ,
                        // title: 'Success'
                    }
                }
            }
        }
    } catch (err: any) {
        console.error(`Error in makeOfferAgentToBSOOfferInfo ${String(err.message)}`)
    }
    dispatch(modalSlice.actions.dataFetchingFinish())
    return {
        isSuccessful: false,
        textNotification: 'Something went wrong. Try again later'
    }
}
