import React from 'react'
import { useAppSelector } from '../../../../hooks/redux'
import { type RootState } from '../../../../store/store'
import { Link } from 'react-router-dom'
import { nanoid } from 'nanoid'
import { type User } from '../../../../store/slices/userSlice'
import checkDocumentsForOtherUser from '../../../../functions/agent/documents/checkDocumentsForOtherUser'
import { hideMails } from '../../../../constants'
import { ava_blank, REACT_APP_BSO_SUPPORT_USER_ID, REACT_APP_BSO_USER_ID } from '../../../../urls'

function TopPerformers(): JSX.Element {
    const allUsers = useAppSelector(
        (state: RootState) => state.user.allUsers
    )
    const currentUser = useAppSelector(
        (state: RootState) => state.user.currentUser
    ) as User

    const allUsersWithoutBso = allUsers.filter((user) => {
        const documentsValid = checkDocumentsForOtherUser(user.id)
        const userEmail = user.email
        if (userEmail && !hideMails.includes(userEmail)) {
            return (
                user.id !== Number(REACT_APP_BSO_SUPPORT_USER_ID) &&
                user.id !== Number(REACT_APP_BSO_USER_ID) &&
                user.Category === 'Agent' &&
                user.id !== currentUser.id &&
                documentsValid &&
                user?.emailVerified
            )
        }
        return false
    })
    const compareUsersByAreasSimilarity = (otherUser1: User, otherUser2: User) => {
        const currentUserAreas: string[] = currentUser.Settings?.[0]?.Areas != null ? currentUser.Settings[0].Areas.slice().sort() : []
        const otherUser1Areas: string[] = otherUser1.Settings?.[0]?.Areas != null ? otherUser1.Settings[0].Areas.slice().sort() : []
        const otherUser2Areas: string[] = otherUser2.Settings?.[0]?.Areas != null ? otherUser2.Settings[0].Areas.slice().sort() : []

        const user1MatchCount = currentUserAreas.filter(area => otherUser1Areas.includes(area)).length
        const user2MatchCount = currentUserAreas.filter(area => otherUser2Areas.includes(area)).length
        if (user1MatchCount === user2MatchCount) {
            const user1UpdatedAt = new Date(otherUser1.updatedAt).getTime()
            const user2UpdatedAt = new Date(otherUser2.updatedAt).getTime()
            return user2UpdatedAt - user1UpdatedAt
        }
        return user2MatchCount - user1MatchCount
    }

    const sortedUsers = allUsersWithoutBso
        .slice()
        .sort(compareUsersByAreasSimilarity)

    const truncateText = (text: string, maxLength: number) => {
        if (text.length <= maxLength) return text
        return text.substring(0, maxLength) + '...'
    }
    return (
        <div>
            <h4>Top Performers</h4>
            <div className={`d-flex justify-content-${sortedUsers.length === 1 ? 'start ps-4' : 'evenly'} `}>
                {sortedUsers && sortedUsers.length > 0
                    ? sortedUsers.slice(0, 3).map((agent, i) => {
                        const userCONTACTS = (agent)?.Type?.[0]
                        const userContactsINFO = agent?.ContactInfo
                        const displayName = userContactsINFO?.FirstName
                            ? userContactsINFO?.FirstName
                            : userCONTACTS?.Name
                                ? userCONTACTS?.Name.split(' ')?.[0]
                                : 'No name'

                        return (

                            <div className="text-center text-dark" key={nanoid()}>
                                <Link to={`/agent/profile/${agent.id}`} className="text-decoration-none text-dark">
                                    <div className="symbol symbol-60px symbol-circle">
                                        <div className="symbol-label" style={{ backgroundImage: `url(${userCONTACTS?.Photo ?? ava_blank})` }} ></div>
                                        <span className={'fw-bold fs-6 text-dark'}>
                                            {truncateText(displayName, 7)}
                                        </span>

                                    </div>
                                </Link>
                            </div>
                        )
                    })
                    : <h6 className='my-4'>Not agents yet</h6>}

            </div>
        </div>
    )
}

export default TopPerformers
