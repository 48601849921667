import React from 'react'
import { Link } from 'react-router-dom'
import Icon from '../../../../app/icon/Icon'

export default function ActionBookViewing(): JSX.Element {
    return (
        <Link
            to={'/agent/units/bookViewing'}
            className="text-decoration-none"
        >
            <div className="card shadow-sm mb-4 h-80px ">
                <div className="card-body d-flex justify-content-between align-items-center p-1 ">
                    <div className="px-4 py-1 mb-1">
                        {/* Book viewing */}
                        <span className="fs-3 text-gray-800 fw-bold d-block text-hover-primary">

                            BOOK VIEWING
                        </span>
                        <span className="fs-6 fw-semi-bold text-gray-500"> Make an appointment to show the best units to your clients</span>
                    </div >
                    <div className='align-items-center'>
                        <span className="pe-4">
                            <Icon name={'event_available'} filled={true} className={'fs-6x mx-auto text-gray-700 fs-lg-5x'} />
                        </span>

                        {/* <span className="bi bi-gift fs-4x text-info pe-2"></span> */}
                    </div>
                </div>
            </div>
            {/* <div className='position-relative'
            >
                <img
                    className='w-100 h-100px object-fit-cover mb-2 rounded rounded-2  opacity-75'
                    src={defaultImage}
                    alt={'Lable'}
                />
                <span className="ps-4 fs-4x fw-semibold text-dark font-monospace position-absolute" style={{ top: '8px', left: '0px' }}>
                        Book viewing
                </span>
                <span className="fs-2x fw-bold me-2 lh-1 ls-n2 text-white text-end  px-4 pt-4 pb-2 position-absolute" style={{ bottom: '8px', right: '0px' }}>
                                    Make an appointment and book a viewing
                </span>
            </div> */}
        </Link>
    )
}
