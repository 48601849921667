import React, { useState, useEffect, useRef } from 'react'
import { Modal } from 'antd'

import SlideFirst from './SlideFirst'
import { type CarouselRef } from 'antd/lib/carousel'
import { useNavigate } from 'react-router-dom'
import { nanoid } from 'nanoid'

interface ITutorialContent {
    title?: string
    description?: string
    img: string
}

interface TutorialItemProps {
    route?: string
    label?: string
    description?: string
    img?: string
    contentArray: ITutorialContent[]
}

export default function TutorialItem({
    route,
    label,
    img,
    description,
    contentArray
}: TutorialItemProps): JSX.Element {
    const [modalOpen, setModalOpen] = useState(false)
    const [carouselCurrent, setCarouselCurrent] = useState(0)
    const carouselRef = useRef<CarouselRef>(null)
    const [showClose, setShowClose] = useState(false)

    useEffect(() => {
        if (modalOpen) {
            carouselRef.current?.goTo(0)
        }
    }, [modalOpen])

    const handleSlideChange = (index: number): void => {
        setCarouselCurrent(index)
        if (index === contentArray.length - 1) {
            setShowClose(true)
        }
    }

    useEffect(() => {
        if (!showClose) {
            const interval = setInterval(() => {
                if (contentArray.length - 1 !== carouselCurrent) {
                    carouselRef.current?.next()
                }
            }, 8000)
            return () => { clearInterval(interval) }
        }
    }, [showClose])

    const handleLeftClick = (): void => {
        if (carouselCurrent !== 0) {
            carouselRef.current?.prev()
        }
    }

    const handleNextClick = (): void => {
        if (contentArray.length - 1 !== carouselCurrent) {
            carouselRef.current?.next()
        }
    }

    const handleClose = (): void => {
        setModalOpen(false)
    }

    return (
        <>
            <div
                className="card-body w-100 d-flex justify-content-start align-items-center p-2"
                onClick={() => {
                    setModalOpen(true)
                }}
            >
                <div className={'w-90px h-90px'}>
                    <div style={{ borderRadius: '0.6rem' }} className="w-90px h-90px d-flex position-relative overflow-hidden">
                        <div className="z-index-1 position-absolute rounded" style={{ border: '2px solid #fff200', height: 'calc(100% - 4px)', width: 'calc(100% - 4px)', margin: '2px' }} />
                        <img className="position-absolute z-index-0 h-100" src={img} alt="" />
                    </div>
                </div>
                <div className="ps-2 ms-1">
                    <span className="fs-3 fs-lg-4 lh-1 text-dark fw-semi-bold d-block">
                        {label}
                    </span>
                    <span className="fs-6 fs-lg-7 lh-1 fw-semi-bold text-gray-500">
                        {' '}
                        {description}
                    </span>
                </div>
            </div>

            <Modal
                centered
                open={modalOpen}
                style={{
                    left: 0,
                    maxWidth: "450px",
                    height: '100%',
                    // maxHeight: "1052px !important",
                    // maxWidth : 100
                    //   height: "100vmax"

                }}
                // width="100vw"
                zIndex={1501}
                closable={false}
                footer={null}
            >


                <span
                    className="position-absolute"
                    style={{
                        top: '40px',
                        right: '20px',
                        zIndex: 9999

                    }}
                    onClick={handleClose}
                >
                    <i className="fs-6x fs-lg-4x ki-duotone ki-cross-square " >
                        <span className="path1 text-dark" style={{ filter: 'brightness(0)' }}></span>
                        <span className="path2 text-white" style={{ filter: 'brightness(1)' }}></span>
                    </i>
                </span>

                <div className="position-absolute container" style={{
                    height: '100%',
                    // maxHeight: "1052px",
                    top: 0, left: 0, right: 0, bottom: 'auto', zIndex: 9998
                }}>
                    <div className="row" style={{ height: 'inherit' }}>
                        <div className="col-6  opacity-0" onClick={handleLeftClick}>1</div>
                        <div className="col-6  opacity-0" onClick={handleNextClick}>2</div>
                    </div>
                </div>
                <SlideFirst onChange={handleSlideChange} ref={carouselRef}>
                    {contentArray.map((el: any) => {
                        //             console.log(el, 'el')
                        return (
                            <div key={`${nanoid()}`} className="text-start ">
                                <div className="position-relative  h-100 border border-gray-500 overflow-hidden bgi-no-repeat bgi-size-cover bgi-position-x-center bgi-position-y-center d-flex flex-column justify-content-center align-items-center"
                                    style={{ backgroundImage: `url(${el.img})` }}>

                                    <img src={`${el.img}`} className="w-100 h-100" />

                                </div>
                            </div>
                        )
                    }
                    )}
                </SlideFirst>
            </Modal>

        </>
    )
}
