import React, {type ReactElement} from "react";
import TextButton from "../../components/shared/UIv2/Button/TextButton/TextButton";
import {useNavigate} from "react-router-dom";

export default function WelcomePage (): ReactElement {
    const navigate = useNavigate()
    return (
        <div className='vh-100 d-flex flex-column justify-content-between px-15 pt-15 pb-7 bg-white'>
            <div className={'d-flex flex-column gap-5'}>
                <div>
                    <img src="/assets/media/logo512.png"
                        alt="BSO logo"
                        style={{
                            height: '50px'
                        }}
                    />
                </div>
                <div className={'fs-2x fw-bold'}>
                    WELCOME TO BSO CLUB!
                </div>
                <div className={'fs-5 fw-medium text-gray-500'}>
                    Montes auctor rhoncus gravida, at lacinia dolor tempor facilisi facilisis.
                </div>
            </div>
            <div className={'d-flex gap-3'}>

                <TextButton
                    style={'dark'}
                    width={'full'}
                    text={'SIGN UP'}
                    textSize={'large'}
                    height={'37'}
                    rounded={'48'}
                    onClick={() => {
                        navigate('/auth/v2/register_phone')
                    }}
                />
                <TextButton
                    style={'bso'}
                    width={'full'}
                    text={'SIGN IN'}
                    textSize={'large'}
                    height={'37'}
                    rounded={'48'}
                    onClick={() => {
                        navigate('/auth/v2/login')
                    }}
                />
            </div>
        </div>
    )
}