import React, {type ReactElement} from "react";
import {type Unit} from "../../../../../store/slices/unitsSlice";
import TextButton from "../../../../shared/UIv2/Button/TextButton/TextButton";
import {useNavigate} from "react-router-dom";

interface ServicesFooterProps {
    unit?: Unit
    activeService?: string
}

export default function ServicesFooter ({ unit, activeService }: ServicesFooterProps): ReactElement {
    const navigate = useNavigate()
    return <div className={'px-1 pt-3'}>
        <TextButton
            text={'NEXT'}
            style={activeService != null ? 'dark' : 'gray'}
            width={'full'}
            height={'48'}
            rounded={'48'}
            textSize={'large'}
            onClick={() => {
                if (activeService != null) {
                    navigate(`/landlord/modal/v2/${activeService}/${String(unit?.id)}/-`)
                }
            }}
        />
    </div>
}