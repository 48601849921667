
import React from 'react'
import { nanoid } from 'nanoid'

import { todayImage } from '../../../../urls'

import CardViewing from './CardViewing'
import { days } from '../../../../constants'
import { isToday } from '../../../../functions/shared/date/isToday'
export default function ViewingsListItem(props: any): JSX.Element {
    const currentYear = new Date().getFullYear()
    const currentMonth = new Date().getMonth()
    const currentDate = new Date().getDate()
    const todayViewings = props.viewings.filter((period: any) => {
        const createYear = new Date(period?.attributes?.Datetime ?? period?.attributes?.createdAt).getFullYear()
        const createMonth = new Date(period?.attributes?.Datetime ?? period?.attributes?.createdAt).getMonth()
        const createDate = new Date(period?.attributes?.Datetime ?? period?.attributes?.createdAt).getDate()
        return (
            createYear === currentYear &&
            createMonth === currentMonth &&
            createDate === currentDate
        )
    })

    const datesArray = Array.from(new Set(props.viewings.map((view: any) => (new Date(view?.attributes?.Datetime ?? view?.attributes?.createdAt) as any).toDateString())))

    if (currentYear === props.year && currentMonth === props.month && !datesArray.includes(new Date().toDateString())) {
        datesArray.push(new Date().toDateString())
    }

    const nowDate = new Date().toISOString()

    return (
        <>

            {props.viewingsLength > 0 && props.viewings
                ? <div className="flex-grow-1">
                    {datesArray && datesArray.length > 0 ? (
                        datesArray.sort((a: any, b: any) => (new Date(a) as any) - (new Date(b) as any))
                            .map((dateString: any) => {
                                const date = new Date(dateString)
                                const day = date.getDate().toString().padStart(2, '0')
                                const month = date.toLocaleString('default', { month: 'short' })
                                const dayWeek = date.getDay()

                                const haveDatesViewings = props.viewings.filter((view: any) => (new Date(view?.attributes?.Datetime ?? view?.attributes?.createdAt) as any).toDateString() === dateString)

                                return (
                                    < div key={nanoid()}>
                                        {date.toDateString() === new Date().toDateString() && isToday(nowDate) && (
                                            <div id={`${currentDate}-${currentMonth}-${currentYear}`} className={'position-relative'} ref={props.myRef} >
                                                <img
                                                    className="w-100 h-100px object-fit-cover mb-2 rounded opacity-100"
                                                    src={todayImage}
                                                    alt="Today"
                                                />
                                                <span className="ps-4 fs-5x fs-lg-3x fw-semi-bold text-white position-absolute" style={{ top: '6px', left: '0px', textShadow: '1px 1px 10px #332d2dd9' }}>
                                                    Today
                                                </span>
                                                <span className="d-block fs-2 min-w-30px max-w-30px text-center position-absolute rounded-0 rounded-bottom rounded-end-0 translate-middle badge badge-secondary opacity-75" style={{ top: '12px', right: '-15px' }}>
                                                    {todayViewings.length}
                                                </span>
                                            </div>
                                        )}

                                        <div className="card shadow-sm mb-2 position-relative me-n0 ">
                                            <div className="d-flex justify-content-between">
                                                <div className="w-50px d-flex flex-column align-items-center justify-content-start py-4 px-auto mx-auto  pt-2 col-2">
                                                    <span className="fw-normal fs-5x fs-lg-3x lh-1 my-1">{`${day}`}</span>
                                                    <span className="text-gray-700 fs-4 fs-lg-5">{days[dayWeek]}</span>
                                                </div>

                                                <div className='col-10'>
                                                    {haveDatesViewings && haveDatesViewings.length > 0
                                                        ? haveDatesViewings.map((view: any) => (
                                                            <CardViewing key={nanoid()} viewing={view} />
                                                            //   <CalendarListItems key={nanoid()} viewOrOffer={view} isWhat={'viewings'} isMyTasks={props.isMyTasks} />
                                                        ))
                                                        : <div className={'d-flex align-items-end justify-content-end p-4'}>
                                                            <span className="fs-2x fs-lg-1x text-gray-800 fw-semi-bold text-uppercase lh-1">No viewings for today </span>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                    ) : (
                        <div className="card shadow-sm mb-2 position-relative "> !!!!! </div > // Add an empty div when there are no viewings
                    )}
                </div>

                : <div
                    className="card shadow-sm text-end fw-semi-bold fs-3x pe-4 pt-4 mb-2"

                >
                    {/* You don’t have any active viewings yet */}
                </div>
            }
        </>
    )
}
