import { mounthes } from "../date/timeString"
import { getCurrentSub } from "./getCurrentSub"

export const getSubsDetails = (subChoose: string) => {
    const resultDetails = { areas: '3', advertiseCoins: '50', broadcastCoins: '10', amount: '0' }

    if (subChoose === 'Test-Mode_Broker_Subscription_Standard' || subChoose.includes('Standard')) { 
        resultDetails.areas = '5'
        resultDetails.advertiseCoins = '100'
        resultDetails.broadcastCoins = '20'
        resultDetails.amount = '10'
    } if (subChoose === 'Test-Mode_Broker_Subscription_Premium' || subChoose.includes('Premium')) { 
        resultDetails.areas = 'All'
        resultDetails.advertiseCoins = '150'
        resultDetails.broadcastCoins = '30'
        resultDetails.amount = '20'
    }
    return resultDetails
}

export const getSubscriptionDetails = () => {
    const { activeSub, activeSubPeriod,latestServiceAgentSubscription } = getCurrentSub();
  
    const service = latestServiceAgentSubscription?.Service?.[0];
  
    const startedAtDate = service?.StartDate ? new Date(service.StartDate) : null;
    const finishedAtDate = service?.ExpiryDate ? new Date(service.ExpiryDate) : null;
  
    const startedAtDateText = (startedAtDate != null)
        ? `${startedAtDate.getDate().toString().padStart(2, '0')} ${mounthes[startedAtDate.getMonth()].slice(0, 3)} ${startedAtDate.getFullYear().toString()}`
        : 'N/A'; 
  
    const finishedAtDateText = (finishedAtDate != null)
        ? `${finishedAtDate.getDate().toString().padStart(2, '0')} ${mounthes[finishedAtDate.getMonth()].slice(0, 3)} ${finishedAtDate.getFullYear().toString()}`
        : 'N/A'; 

    const amount =  activeSubPeriod === 'month' ? 
        service?.Package === 'Premium' ? "20" : service?.Package === 'Standard' ? '10'  : '0' 
        : service?.Package === 'Premium' ? "200" : service?.Package === 'Standard' ? '100' : "0"
    const period = activeSubPeriod === 'month' ?  'Mon' : 'Year' 
    const res = {
        Free: {
            title: 'Free',
            description: 'Ideal to explore the app and its features',
            period , 
            amount,
            startedAtDate: startedAtDateText,
            finishedAtDate: finishedAtDateText,
        },
        Standard: {
            title: 'Standard',
            description: 'Perfect for users seeking an affordable way to boost their growth',
            period ,
            amount,
            startedAtDate: startedAtDateText,
            finishedAtDate: finishedAtDateText,
        },
        Premium: {
            title: 'Premium',
            description: 'Enjoy the full suite of benefits and features',
            period ,
            amount,
            startedAtDate: startedAtDateText,
            finishedAtDate: finishedAtDateText,
        },
    };
  
    return res[activeSub as keyof typeof res] || res.Free;
};
  