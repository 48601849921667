import React, {type ReactElement} from "react";
import {Controller} from "react-hook-form";
import TextInput from "../../../../../components/shared/UIv2/Input/TextInput/TextInput";
import InputPasswordSecurity from "./InputPasswordSecurity";

interface InputProps {
    row: Record<string, any>
    control: any
    watch: any
}

export default function Input ({row, control, watch}: InputProps): ReactElement {
    const watchedValue = row.watch != null ? watch(row.watch) : undefined
    return (
        <Controller
            name={row.id}
            control={control}
            defaultValue={row.defaultValue}
            rules={{
                required: row.required === true ? `Field "${row.content[0]}" is required` : undefined,
                validate: (value: string) => {
                    if (row.type === 'number') {
                        if (row.max != null && value != null) {
                            return Number(value) <= row.max
                        }
                        if (row.min != null && value != null) {
                            return Number(value) >= row.min
                        }
                    }
                    return watchedValue != null ? watchedValue === value : true
                },
                pattern: row.regexp != null ? new RegExp(row.regexp) : undefined
            }}
            render={({ field, fieldState: { error } }) => {
                return (
                    <div className={`d-flex flex-column gap-3 ${row.paddingBottom === 'small' ? 'pb-4' : 'pb-8'}`}>
                        <div className={'d-flex gap-2 align-items-center fs-5 fw-medium'} id={`${String(row.id)}-label`}>
                            <span>{row.content[0]}</span>
                            {
                                row.unitsString != null
                                    ? <span className={'fs-7 text-gray-400 fst-italic'}>
                                        { row.unitsString }
                                    </span>
                                    : <></>
                            }
                        </div>
                        <TextInput
                            autoComplete={row.autocomplete}
                            type={row.type ?? 'text'}
                            placeholder={row.content[1]}
                            value={field.value ?? ''}
                            onChange={field.onChange}
                            style={row.disabled !== true ? 'white' : 'gray'}
                            disabled={row.disabled === true}
                            error={ error != null
                                ? error?.message !== ''
                                    ? error?.message
                                    : row.errorMessage
                                : undefined
                            }
                            hideError={row.showPassSecurity === true}
                            unit={row.unit}
                            min={row.min}
                            max={row.max}
                        />
                        {
                            row.type === 'password' && row.showPassSecurity === true
                                ? <InputPasswordSecurity value={field.value}/>
                                : <></>
                        }
                    </div>
                )
            }}
        />
    )
}