import React from 'react';
import { Drawer } from 'antd';
import { useDispatch } from 'react-redux';
import { type RootState } from '../../../store/store';
import { setNotEnoughMarketingCredits } from '../../../store/slices/advertisesSlice';
import AddOnsServiceBricks from '../../agentModule/addOnsServices/AddOnsServicesCards/AddOnsServiceBricks';
import { useAppSelector } from '../../../hooks/redux';
import { setButtonDriverAddOnsParent } from '../../../store/slices/modalSlice';


interface UniversalDrawerProps {
    triggerButton: React.ReactNode;
    drawerKey: string;
    customStyles?: React.CSSProperties;
    customBodyStyles?: React.CSSProperties;
}

export const UniversalBuyBricks: React.FC<UniversalDrawerProps> = ({
    triggerButton,
    drawerKey,
    customStyles = {},
    customBodyStyles = {},
}) => {
    const dispatch = useDispatch();

    const handleOpen = () => {
        dispatch(setButtonDriverAddOnsParent(true));
    };

    const handleClose = () => {
        dispatch(setButtonDriverAddOnsParent(false));
        dispatch(setNotEnoughMarketingCredits(0));
    };
    const buttonDriverAddOnsParent = useAppSelector((state: RootState) => state.modal.buttonDriverAddOnsParent);

    const clonedTriggerButton = React.cloneElement(triggerButton as React.ReactElement, {
        onClick: handleOpen,
    });

    return (
        <>
            {clonedTriggerButton}
            <Drawer
                style={{ borderTopRightRadius: '8px', borderTopLeftRadius: '8px', paddingBottom: '3px', ...customStyles }}
                height="auto"
                styles={{
                    body: {
                        maxHeight: '88vh',
                        marginBottom: '3px',
                        paddingTop: '3px',
                        marginTop: '6px',
                        paddingLeft: '2px',
                        paddingRight: '2px',
                        ...customBodyStyles,
                    },
                }}
                placement="bottom"
                closable={false}
                onClose={handleClose}
                open={buttonDriverAddOnsParent}
                rootStyle={{ zIndex: 1100 }}
                key={drawerKey}
                maskClosable={true}
            >
                <div
                    key={`${drawerKey}-content`}
                    className="content d-flex flex-column flex-fill p-0 m-1 px-2"
                >
                    <div className="w-100 mw-450px mx-auto mh-75">
                        <AddOnsServiceBricks />
                    </div>
                </div>
            </Drawer>
        </>
    );
};

