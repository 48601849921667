import React, { useEffect, useState } from 'react'
import { type IViewing } from '../../../../store/slices/viewingsSlice'
import { type IUnitKey, type Unit } from '../../../../store/slices/unitsSlice'
import { timeStringInScheduler } from '../../../../functions/agent/date/timeStringInScheduler'
import { SchedulerStatus } from '../../../../functions/agent/date/SchedulerStatus'
import { type TimelineItem } from '../ViewingTimeline'
import { Button, Steps } from 'antd'
import Icon from '../../../icon/Icon'

interface IProps {
    viewing: IViewing
    unit: Unit
    unitKey: IUnitKey
    userType: string
}

export const ReasonBlock = ({ statuses, reason }: any) => {

    const typeReason = statuses?.[0]?.includes('Canceled') ? 'Cancel ' : statuses?.[0]?.includes('Rejected') ? 'Rejected ' : 'Reschedule '


    return (
        <div>
            <div className="fs-6 lh-1 fw-bold border-light-dark text-bold text-dark me-2">
                {typeReason} Reason:
            </div>
            <div className="d-block">
                <div className={`badge badge-light-${statuses?.[0]?.includes('Canceled') || statuses?.[0]?.includes('Rejected') ? "danger" : "info"} border border-${statuses?.[0]?.includes('Canceled') || statuses?.[0]?.includes('Rejected') ? "danger" : "info"} fs-7 text-wrap w-100`}>
                    {reason}
                </div>
            </div>
        </div>
    );
};



function TimelineKeysInBso({ viewing, unit, unitKey, userType }: IProps): JSX.Element {
    let process = 0
    let DropOutDate = ''; let PickUpDate = ''
    if (unitKey !== null) {
        DropOutDate = unitKey?.attributes?.DropOutDate
        PickUpDate = unitKey?.attributes?.PickUpDate
        if (process > 0) {
            process++
        }
    }

    const unitStatuses = unit.attributes.Statuses
    const viewingStatuses = viewing?.attributes?.Statuses
    const unitKeyStatus = unitKey?.attributes?.Status

    let stepApprovedOrRejectedKeysBSO = 'Your viewing was approved by the BSO'
    let descriptionApprovedOrRejectedKeysBSO = unitKey !== null ? `You should pick up the keys on ${timeStringInScheduler(PickUpDate).split(',')[0]} - ${timeStringInScheduler(PickUpDate).split(',')[1]}` : 'Please visit the BSO Office in the set time below to pick up the unit keys.'

    let stepInProcessKeysBSO = 'Please wait, soon we will indicate the date and time of receipt of keys.'
    let descriptionInProcessKeysBSO = 'We confirmed the viewing, but the process of interaction with the keys has not been registered by the admin, please wait, soon he will indicate the date and time of receipt of keys.'

    const stepPickUpDateKeysBSO = 'Time to pick up the unit keys at the BSO office'
    const descriptionPickUpDateKeysBSO = unitKey !== null ? `You should pick up the keys on ${timeStringInScheduler(PickUpDate).split(',')[0]} - ${timeStringInScheduler(PickUpDate).split(',')[1]}` : 'Please visit the BSO Office by the time indicated below to pick up the unit keys.'

    const stepDropUpDateKeysBSO = 'Time to return unit keys to BSO Office'
    const descriptionDropUpDateKeysBSO = unitKey !== null ? `You should return the keys by ${timeStringInScheduler(DropOutDate).split(',')[0]} - ${timeStringInScheduler(DropOutDate).split(',')[1]}` : 'Please visit the BSO Office in the set time below to drop out the unit keys.'

    const stepMakeYourViewingKeysBSO = 'You get the keys. Make your viewing'
    const descriptionMakeYourViewingKeysBSO = 'Please return the unit keys after finishing the viewing.'

    const stepFeedback = 'Add feedback about the finished viewing'
    let descriptionFeedback = 'Please add feedback about the results of this viewing by selecting options on the quiz page and try to submit an offer if your client is ready for it.'


    const stepWaitingComplain = 'Wait for your performance review'
    let descriptionWaitingComplain = 'Your viewing is completed! But we have one more step to make free breath about it: our specialist will send you a review of your performance during this viewing.'


    const stepCompleted = 'Viewing completed successfully'
    const descriptionCompleted = 'Congratulations on another completed viewing! Let’s make a new one with the BSO Club!'

    if (unitStatuses.includes('Keys in BSO office')) {
        if (viewingStatuses?.includes('Rescheduled by BSO') && !viewingStatuses?.includes('Rescheduled by agent') && !viewingStatuses?.includes('In-process')) {
            process = 0
        }
        if (viewingStatuses?.includes('In-process') || viewingStatuses?.includes('Rescheduled by agent')) {
            process = 0
        } else if (viewingStatuses?.includes('Canceled')) {
            process = 0
            stepInProcessKeysBSO = 'Viewing cancelled'
            descriptionInProcessKeysBSO = 'Your viewing has been cancelled'
        } else if (viewingStatuses?.includes('Rejected')) {
            process = 1

            stepApprovedOrRejectedKeysBSO = 'Your viewing was rejected by BSO'
            descriptionApprovedOrRejectedKeysBSO = 'Unfortunately, our specialist has decided to reject your viewing because of the valuable reason stated above. Please reschedule the time of this viewing or create a new one'
        } else if (viewingStatuses?.includes('Approved')) {
            if (unitKeyStatus?.includes('Waiting for agent')) {
                process = 1

                stepApprovedOrRejectedKeysBSO = 'Approved! ' + stepPickUpDateKeysBSO
                descriptionApprovedOrRejectedKeysBSO = descriptionPickUpDateKeysBSO
            } else if (unitKeyStatus?.includes('In-process')) {
                process = 2
            }
        } else if (viewingStatuses?.includes('Waiting for feedback')) {
            if (unitKeyStatus?.includes('Waiting for agent') || unitKeyStatus?.includes('In-process')) {
                process = 3
            } else if (unitKeyStatus?.includes('Completed')) {
                process = 4
            }
        } else if (viewingStatuses?.includes('Waiting for complain')) {
            if (viewing?.attributes?.Feedback !== null) {
                descriptionFeedback = viewing?.attributes?.Feedback
            }
            if (unitKeyStatus?.includes('Waiting for agent') || unitKeyStatus?.includes('In-process')) {
                process = 4
            } else if (unitKeyStatus?.includes('Completed')) {
                process = 5
            }
        } else if (viewingStatuses?.includes('Completed')) {
            process = 6

            if (viewing?.attributes?.Feedback !== null) {
                descriptionFeedback = viewing?.attributes?.Feedback
            }
            if (viewing?.attributes?.Complaint !== null) {
                descriptionWaitingComplain = viewing?.attributes?.Complaint
            }
        }
    }
    const [line, setLine] = useState('middle')

    const iconProcess = (step: number, process: number) => {
        if (process + 1 > step || process === 6) {
            return undefined
        }
        return (<div className="ant-steps-item-icon">
            <span className="ant-steps-icon" style={{ color: 'black' }}>
                {step}
            </span>
        </div>
        )
    }


    const groupKeysBSO: TimelineItem[] = [
        {
            title: (
                <div>
                    <div className='lh-sm mb-1'>{stepInProcessKeysBSO}</div>
                    <div className={'d-flex align-items-center mb-2'}>
                        {process === 0 && <div className="fs-6 lh-1 fw-bold border-light-dark text-bold text-dark me-2">Status</div>}
                        <div className="mb-1">{process === 0 ? SchedulerStatus(viewing?.attributes?.Statuses ?? [], 'fs-6') : undefined}</div>

                    </div>
                    {process === 0 && (viewingStatuses?.includes('Rescheduled by BSO') || viewingStatuses?.includes('Canceled')) && viewing?.attributes?.RejectReason &&
                        <ReasonBlock statuses={viewingStatuses} reason={viewing?.attributes?.RejectReason} />
                    }

                </div>

            ),
            description: descriptionInProcessKeysBSO,
            status: process === 0 && viewingStatuses?.includes('Canceled') ? 'finish' : undefined

        } satisfies TimelineItem,

        ...(viewingStatuses?.includes('Canceled')

            ? []
            : [
                {
                    title: (
                        <div>
                            <div className='lh-sm mb-1'> {stepApprovedOrRejectedKeysBSO} </div>
                            <div className={'d-flex align-items-center'}>
                                {process === 1 && <div className="fs-6 lh-1 fw-bold border-light-dark text-bold text-dark me-2">Status</div>}
                                <div className="mb-1">{process === 1 ? SchedulerStatus(viewing?.attributes?.Statuses ?? [], 'fs-6 mt-0') : undefined}</div>
                            </div>
                            {process === 1 && viewingStatuses?.includes('Rejected') && viewing?.attributes?.RejectReason &&
                                <ReasonBlock statuses={viewingStatuses} reason={viewing?.attributes?.RejectReason} />
                            }
                        </div>
                    ),
                    description: descriptionApprovedOrRejectedKeysBSO

                },
                ...(!viewingStatuses?.includes('Rejected')
                    ? [

                        {
                            title: (
                                <div>
                                    <div className='lh-sm mb-1'> {stepMakeYourViewingKeysBSO} </div>
                                    <div className={'d-flex align-items-center'}>
                                        {process === 2 && <div className="fs-6 lh-1 fw-bold border-light-dark text-bold text-dark me-2">Status key</div>}
                                        <div className="subTitle">{process === 2 ? SchedulerStatus([unitKeyStatus ?? '']) : undefined}</div>
                                    </div>    </div>
                            ),
                            description: descriptionMakeYourViewingKeysBSO
                        },
                        ...(viewingStatuses?.includes('Waiting for feedback')
                            ? [
                                ...(unitKeyStatus === 'In-process' || unitKeyStatus === 'Waiting for agent')

                                    ? [{
                                        title: (
                                            <div>
                                                <div className='lh-sm mb-1'> {stepDropUpDateKeysBSO} </div>
                                                <div className={'d-flex align-items-center'}>
                                                    {process === 3 && <div
                                                        className="fs-6 lh-1 fw-bold border-light-dark text-bold text-dark me-2">Status key</div>}
                                                    <div
                                                        className="subTitle">{process === 3 ? SchedulerStatus([unitKeyStatus ?? '']) : undefined}</div>
                                                </div>    </div>
                                        ),
                                        description: descriptionDropUpDateKeysBSO
                                    },

                                    {
                                        title: (
                                            <div>
                                                <div className='lh-sm mb-1'> {stepFeedback} </div>
                                                {process > 4 && <div
                                                    className="mb-1">{SchedulerStatus(['Your Feedback'], 'fs-6 mt-0')}</div>}
                                                <div className={'d-flex align-items-center'}>
                                                    {process === 4 && <div
                                                        className="fs-6 lh-1 fw-bold border-light-dark text-bold text-dark me-2">Status</div>}
                                                    <div
                                                        className="subTitle">{process === 4 ? SchedulerStatus(viewingStatuses?.includes('Waiting for complain') ? ['Completed'] : viewing?.attributes?.Statuses ?? [], 'fs-6') : undefined}</div>
                                                </div>    </div>
                                        ),
                                        description: descriptionFeedback
                                    }]
                                    : [
                                        {
                                            title: (
                                                <div>
                                                    <div className='lh-sm mb-1'> {stepDropUpDateKeysBSO} </div>
                                                    <div className={'d-flex align-items-center'}>
                                                        {process === 3 && <div
                                                            className="fs-6 lh-1 fw-bold border-light-dark text-bold text-dark me-2">Status key</div>}
                                                        <div
                                                            className="subTitle">{process === 3 ? SchedulerStatus([unitKeyStatus ?? '']) : undefined}</div>
                                                    </div>    </div>
                                            ),
                                            description: descriptionDropUpDateKeysBSO
                                        },
                                        {
                                            title: (
                                                <div>
                                                    <div className='lh-sm mb-1'> {stepFeedback} </div>
                                                    {process > 4 && <div
                                                        className="mb-1">{SchedulerStatus(['Your Feedback'], 'fs-6 mt-0')}</div>}
                                                    <div className={'d-flex align-items-center'}>
                                                        {process === 4 && <div
                                                            className="fs-6 lh-1 fw-bold border-light-dark text-bold text-dark me-2">Status</div>}
                                                        <div
                                                            className="subTitle">{process === 4 ? SchedulerStatus(viewingStatuses?.includes('Waiting for complain') ? ['Completed'] : viewing?.attributes?.Statuses ?? [], 'fs-6') : undefined}</div>
                                                    </div>    </div>
                                            ),
                                            description: descriptionFeedback
                                        }
                                    ]

                            ]
                            : [

                                {
                                    title: (
                                        <div>
                                            <div className='lh-sm mb-1'> {stepFeedback} </div>
                                            {process > 3 && <div
                                                className="mb-1">{SchedulerStatus(['Your Feedback'], 'fs-6 mt-0')}</div>}
                                            <div className={'d-flex align-items-center'}>
                                                {process === 3 &&
                                                    <div className="fs-6 lh-1 fw-bold border-light-dark text-bold text-dark me-2">Status</div>}
                                                <div
                                                    className="subTitle">{process === 3 ? SchedulerStatus(viewing?.attributes?.Statuses ?? [], 'fs-6') : undefined}</div>
                                            </div>    </div>
                                    ),
                                    description: descriptionFeedback

                                },
                                {
                                    title: (
                                        <div>
                                            <div className='lh-sm mb-1'> {stepDropUpDateKeysBSO} </div>
                                            <div className={'d-flex align-items-center'}>
                                                {process === 4 &&
                                                    <div className="fs-6 lh-1 fw-bold border-light-dark text-bold text-dark me-2">Status</div>}
                                                <div
                                                    className="subTitle">{process === 4 ? SchedulerStatus([unitKeyStatus ?? '']) : undefined}</div>
                                            </div>    </div>
                                    ),
                                    description: descriptionDropUpDateKeysBSO
                                }

                            ]
                        ),
                        ...(viewingStatuses?.includes('Waiting for complain') && (unitKeyStatus === 'In-process')
                            ? [
                                {
                                    title: (
                                        <div>
                                            <div className='lh-sm mb-1'>{stepWaitingComplain} </div>
                                            {process > 5 && <div
                                                className="mt-2">{SchedulerStatus(['BSO team feedback'], 'fs-6 mt-0')}</div>}
                                            <div className={'d-flex align-items-center'}>
                                                {process === 5 && <div className="fs-6 lh-1 fw-bold border-light-dark text-bold text-dark me-2">Status</div>}
                                                <div className="subTitle">{process === 5 ? SchedulerStatus(viewingStatuses?.includes('Waiting for complain') ? ['Completed'] : viewing?.attributes?.Statuses ?? [], 'fs-6 my-0') : undefined}</div>
                                            </div>    </div>
                                    ),
                                    description: descriptionWaitingComplain
                                }

                            ]
                            : [
                                {
                                    title: (
                                        <div>
                                            <div className='lh-sm mb-1'>{stepWaitingComplain} </div>
                                            {process > 4 && <div
                                                className="mt-2">{SchedulerStatus(['BSO team feedback'], 'fs-6 mt-0')}</div>}
                                            <div className={'d-flex align-items-center'}>
                                                {process === 4 && viewingStatuses?.includes('Waiting for complain') && <div className="fs-6 lh-1 fw-bold border-light-dark text-bold text-dark me-2">Status</div>}
                                                <div className="subTitle">{process === 4 && viewingStatuses?.includes('Waiting for complain') ? SchedulerStatus(['Completed'], 'fs-6 my-0') : undefined}</div>
                                            </div>
                                        </div>
                                    ),
                                    description: descriptionWaitingComplain
                                }
                            ]
                        ),

                        {
                            title: (
                                <div>
                                    <div className='lh-sm mb-1'> {stepCompleted} </div>
                                    <div className={'d-flex align-items-center'}>
                                        {process === 6 && <div className="fs-6 lh-1 fw-bold border-light-dark text-bold text-dark me-2">Status</div>}
                                        <div className="subTitle d-block">{process === 6 ? SchedulerStatus(viewing?.attributes?.Statuses ?? [], 'fs-6 my-0') : undefined}</div>
                                    </div>    </div>
                            ),
                            status: process === 6 ? 'finish' : undefined,
                            description: descriptionCompleted

                        } satisfies TimelineItem
                    ]
                    : [])
            ]
        )

    ]

    const [sliceSteps, setSliceSteps] = useState([groupKeysBSO[process]])

    useEffect(() => {
        groupKeysBSO.map((el, i) => el.icon = iconProcess(i + 1, process))
    }, [sliceSteps, process])

    return (
        <div className='my-8 pt-8' style={{ maxWidth: '450px', width: '100%' }}>

            {<Steps
                current={line === 'next' ? 0 : line === 'middle' ? 0 : process}
                percent={60}
                items={sliceSteps}
                direction='vertical'

            />}

            <div className={'d-flex'} style={{ justifyContent: process === 0 || process === 6 ? 'flex-end' : 'space-evenly' }}>
                <div className="d-flex justify-content-between align-items-center mx-4">
                    {process !== 0 && <div className={'text-end'}>
                        <div
                            className={'btn btn-light-secondary py-2 text-end text-dark px-7'}
                            onClick={line === 'prew'
                                ? () => { setSliceSteps([groupKeysBSO[process]]); setLine('middle') }
                                : () => {
                                    setSliceSteps([...groupKeysBSO].slice(0, process + 1))
                                    setLine('prew')
                                }
                            }
                        >
                            <div className="d-flex justify-content-around align-items-center">
                                <Icon name={line === 'prew' ? 'play_circle' : 'expand_circle_up'} className={'fs-5'} />
                                <span className="ms-1 fs-8">{line === 'prew' ? 'Current step' : 'Previous steps'}</span>
                            </div>
                        </div>
                    </div>}
                    <div className="d-flex justify-content-between align-items-center mx-2">
                        {process !== 6 && !viewing?.attributes?.Statuses?.includes('Canceled') && <div className={'text-end'}>
                            <div
                                className={'btn btn-light-secondary py-2 text-end text-dark px-7'}
                                onClick={
                                    line === 'next'
                                        ? () => { setSliceSteps([groupKeysBSO[process]]); setLine('middle') }
                                        : () => { setSliceSteps([...groupKeysBSO].slice(process)); setLine('next') }}>
                                <div className="d-flex justify-content-between align-items-center">
                                    <Icon name={line === 'next' ? 'play_circle' : 'expand_circle_down'} className={'fs-5'} />
                                    <span className="ms-1 fs-8">   {line === 'next' ? 'Current step' : 'Next steps'}</span>
                                </div>
                            </div>
                        </div>}
                    </div>
                </div>
            </div>

        </div>
    )
}

export default TimelineKeysInBso
