import swalTexts from '../../../app/texts/swalTexts'
import { getNameUser } from '../../../functions/agent/agentModuleComponent/getNameUser'
import { getUnitAddress } from '../../../functions/agent/agentModuleComponent/getUnitAddres'
import { getCurrentDubaiDate } from '../../../functions/agent/date/getCurrentDateStrFormat'
import { timeStringInScheduler } from '../../../functions/agent/date/timeStringInScheduler'
import { toUTCWithOffset } from '../../../functions/agent/date/toUTCWithOffset'
import { getErrorss } from '../../../functions/errors/GetErrors'
import sendMail from '../../../functions/shared/email/sendMail'
import { sendNotification } from '../../../functions/shared/notifications/from-agent/sendNotification'

import { ACCESS_KEY, urlAPI } from '../../../urls'
import { dataFetchingFinish, dataFetchingStart } from '../../slices/modalSlice'
import { setFilterNavigate } from '../../slices/scheduleSlice'

import { updateUnitAfterReservViewing, type Unit } from '../../slices/unitsSlice'
import { type User } from '../../slices/userSlice'
import { addViewing, viewingsFetchingError } from '../../slices/viewingsSlice'
import { type AppDispatch, type RootState } from '../../store'
import { viewingsQuery } from '../viewingsActions'
import { createNotifyViewing } from './createNotifyViewing'

export const createViewing = (bodyClient: any) => {
    const jwt = window.localStorage.getItem(ACCESS_KEY)
    const userID = bodyClient.objectID // window.localStorage.getItem(USER_ID_KEY)
    return async (dispatch: AppDispatch, getState: () => RootState) => {
        try {
            const units: any = getState().units.units
            const unit = units?.filter((el: any) => el.id === Number(bodyClient.unitID))
            const name = unit[0].attributes?.Property.data.attributes.Name ?? ''
            const location = unit[0].attributes?.Property.data.attributes.Area ?? ''
            const unitNumber = unit[0].attributes?.Number ?? ''
            const ownerUserId = (unit[0] as Unit).attributes?.User.data?.id
            const unitStatuses = unit[0].attributes?.Statuses as string[] ?? []
            const unitKey = unit[0].attributes?.UnitKeys?.data[0]

            const textsMessage = swalTexts.successViewingsActions.createViewing
            let status = 'In-process'
            let createReservKey = false
            let createOccupied = false
            let createDigitalLock = false
            let createBuildingManagement = false
            let isDoorOpen = false
            let titleTextAfterCreate = textsMessage.defaultMessage //  'Wait for the viewing confirmation'
            const isAgentUnit = unitStatuses?.includes('Door close')
            if (!isAgentUnit) {
                if (unitStatuses != null && unitStatuses?.includes('Vacant')) {
                    if (unitStatuses?.includes('Door open')) {
                        status = 'Approved'
                        titleTextAfterCreate = ''
                        isDoorOpen = true
                    } else {
                        if (unitStatuses?.includes('Digital lock')) {
                            status = 'In-process'
                            createDigitalLock = true
                            titleTextAfterCreate = textsMessage.digitalLook // 'Shortly after confirmation, we will send you a Digital Lock Password that you can use to enter the apartment.'
                        } else if (unitStatuses?.includes('Keys in BSO office')) {
                            status = 'In-process' //   status = 'Approved'
                            createReservKey = true
                            titleTextAfterCreate = textsMessage.keysInBSO // 'Attention! You have selected a unit that has physical keys. Our specialist will contact you soon, where you will discuss when you can get and return the unit keys, and only after that the viewing will be confirmed.'
                        } else if (unitStatuses?.includes('Building Management')) {
                            status = 'In-process' //   status = 'Approved'
                            createBuildingManagement = true
                            titleTextAfterCreate = textsMessage.buildingManagement //  'Attention! You have selected a vacant unit, but it is under Building Management, our specialist will clarify the information and contact you for confirmation.'
                        } else if (unitStatuses?.includes('Special Request')) {
                            status = 'In-process' 
                            titleTextAfterCreate = textsMessage.specialRequest   
                        }
                    }
                } else if (unitStatuses?.includes('Occupied')) {
                    status = 'In-process' //   status = 'Approved'
                    createOccupied = true
                    titleTextAfterCreate = textsMessage.occupied // 'Attention! You have selected an occupied unit. Our specialist will contact you soon, and you will discuss a convenient viewing time for you!'
                }
            } else {
                status = 'In-process'
                titleTextAfterCreate = 'You created viewing , please wait when agent answered on your request '
            }

            const dateViewing = new Date(`${bodyClient.time_viewing}+04:00`) // +04:00

            dispatch(dataFetchingStart())
            const bodyObject = {
                method: 'POST',
                collection: 'viewings',
                query: viewingsQuery,
                body: {
                    Datetime: dateViewing,
                    Statuses: [status],
                    Name: isAgentUnit ? `${name} <-> ${location} <-> Dubai` : `${unitNumber} <-> ${name} <-> ${location}`,
                    Notify: !isAgentUnit ? 'Both' : 'Other Agent',
                    Unit: `${bodyClient.unitID}`,
                    User: `${userID}`,
                    ...(isAgentUnit && { AgentToAgentID: ownerUserId })
                }
            }

            const response = await fetch(`${urlAPI}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${jwt}`
                },
                body: JSON.stringify(bodyObject)
            })
            const dataJSON = await response.json()

            if (dataJSON.success) {
                if (dataJSON.response.error != null) {
                    dispatch(viewingsFetchingError(getErrorss(dataJSON)))
                    dispatch(dataFetchingFinish())
                    return { ...dataJSON, textNavigate: '', isSuccessful: false, textNotification: getErrorss(dataJSON) }
                } else {
                    const currentUnit = unit?.[0]
                    const currentUser = getState().user.currentUser
                    const nameUser = getNameUser(currentUser as User)
                    const unitAdress = getUnitAddress(currentUnit)
                    dispatch(addViewing(dataJSON.response.data))
                    const currentDubaiDate = getCurrentDubaiDate()
                    if (isAgentUnit) {
                        await sendNotification(toUTCWithOffset(currentDubaiDate), Number(userID), dataJSON?.response?.data?.id, 'admin-viewing-in-process', `Attention , ${nameUser} created viewing on ${unitAdress}, please respond.`)
                    }
                    if (createOccupied) {
                        const inMomentViewingForAdmin = toUTCWithOffset(currentDubaiDate, 1)
                        await sendNotification(inMomentViewingForAdmin, Number(userID), dataJSON?.response?.data?.id, 'admin-viewing-in-process', `Attention admin, ${nameUser} has selected ${unitAdress} which is in Occupied , please respond.`)
                    }

                    if (createReservKey) {
                        const inMomentViewingForAdmin = toUTCWithOffset(currentDubaiDate, 1)
                        await sendNotification(inMomentViewingForAdmin, Number(userID), dataJSON?.response?.data?.id, 'admin-viewing-key', `Attention admin, ${nameUser} has selected ${unitAdress} which contains an Keys in BSO , please respond.`)
                    }
                    if (createDigitalLock) {
                        const inMomentViewingForAdmin = toUTCWithOffset(currentDubaiDate, 1)
                        await sendNotification(inMomentViewingForAdmin, Number(userID), dataJSON?.response?.data?.id, 'admin-viewing-digital-lock', `Attention admin, ${nameUser} has selected ${unitAdress} which contains an digital lock , please respond.`)
                    }
                    if (createBuildingManagement) {
                        const inMomentViewingForAdmin = toUTCWithOffset(currentDubaiDate, 1)
                        await sendNotification(inMomentViewingForAdmin, Number(userID), dataJSON?.response?.data?.id, 'admin-viewing-building-management', `Attention admin, ${nameUser} has selected ${unitAdress} which contains an Building Management , please respond.`)
                    } if (isDoorOpen) {
                        const [dateDubai, timeDubai] = timeStringInScheduler(
                            dataJSON?.response?.data?.attributes?.Datetime
                        ).split(',')
                        const sentMail = await sendMail({
                            email: currentUser?.email as string,
                            templateId: 'd-c319ebc8b1f242eb82936867b49e5667',
                            dynamicTemplateData: { date: dateDubai, time: timeDubai, unit: unitAdress }

                        })
                    }

                    const areThereMoreThanFiveViewings = getState().viewings.areThereMoreThanFiveViewings

                    await createNotifyViewing(dataJSON.response.data, unit, isAgentUnit)
                    if (!areThereMoreThanFiveViewings && status === 'In-process') {
                        if (!areThereMoreThanFiveViewings) {
                            dispatch(setFilterNavigate('viewings'))
                        }
                    }

                    dispatch(updateUnitAfterReservViewing({ viewing: dataJSON.response.data, unit_id: bodyClient.unitID }))
                    dispatch(dataFetchingFinish())

          
                    return {
                        ...dataJSON,
                        textNavigate: (status === 'In-process' && !areThereMoreThanFiveViewings) // && !isAgentUnit
                            ? '/agent/schedule'
                            : `/agent/modalView/viewing_modal/${bodyClient.unitID}/${dataJSON?.response?.data?.id}/create`,
                        isSuccessful: true,
                        textNotification: `${titleTextAfterCreate}`,
                        title: 'Viewing has been created',
                        icon : "Warning",
                        ...(unitStatuses?.includes('Special Request') ? {swalSpecialRequest : true} : {})
                    }
                } 
            } else {
                dispatch(viewingsFetchingError(getErrorss(dataJSON)))
                dispatch(dataFetchingFinish())
                return { textNavigate: '', isSuccessful: false, textNotification: '' }
            }
        } catch (e) {
            dispatch(dataFetchingFinish())
            console.log(e)
        }
    }
}
