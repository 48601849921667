import React, { useEffect, useState } from 'react'
import { type IOffer } from '../../../../store/slices/offersSlice'
import { type Unit } from '../../../../store/slices/unitsSlice'
import { Steps } from 'antd'
import { type TimelineItem } from '../ViewingTimeline'
import { SchedulerStatus } from '../../../../functions/agent/date/SchedulerStatus'
import Icon from '../../../icon/Icon'
import SentToLandlord from '../../../../functions/agent/statuses/SentToLandlord'
import Approved from '../../../../functions/agent/statuses/Approved'
import renderOfferStatus from "../../../../functions/agent/offers/renderOfferStatus";
import { ReasonBlock } from '../viewingTimelines/timelineKeysInBSO'
interface IProps {
    offer: IOffer
    unit: Unit
}
function TimelineRent({ offer, unit }: IProps): JSX.Element {
    let process = 0
    const offerStatuses = [
        renderOfferStatus(offer?.attributes?.Statuses[0], offer.id)
    ]

    const textsOfferTimelineRent = {
        stepInProcess: 'Wait for offer approval',
        descriptionInProcess: 'Our specialist will approve your offer soon',
        stepApprovedOrRejected: 'Your offer was sent to landlord',// 'Your offer was approved',
        descriptionApprovedOrRejected: 'Please wait for the unit’s landlord\'s confirmation of your offer',
        stepConfirmed: 'Your offer was confirmed by the landlord',
        descriptionConfirmed: 'Please send us your client contact info: email address and phone number',

        stepBFSending: 'Wait for the next updates: we sending the booking form to your client',
        descriptionBFSending: 'Please wait until our specialist completes the required steps for this offer process',
        stepPayUploading: 'Upload deposit pay slip',
        descriptionPayUploading: 'Your client successfully signed the booking form, next step: ask him to make a deposit payment regarding instructions in the email or the app and please upload the deposit pay slip in the app',
        stepBFSinging: 'Wait for the next updates: your client is only one step away from signing the booking form',
        descriptionBFSinging: 'Please wait until your client signed the booking form that we sent to his email',
        stepPaySlip: 'Wait for deposit pay slip approval',
        descriptionPaySlip: 'Our specialist will approve the deposit pay slip for the offer that you sent to us before as soon as possible',

        stepConfirmSlip: 'Deposit pay slip was approved by BSO',
        descriptionConfirmSlip: 'Our specialist  successfully approved your uploaded deposit pay slip for this offer, now you are ready to finalize this offer, and your client will contact you soon about the offer finalization',
        stepCompleted: 'Deal closed successfully',
        descriptionCompleted: 'Congratulations on another completed offer! Let’s make a new one with the BSO Club!',
        addTitle: ''
    }

    if (offerStatuses?.includes('In-process')) {
        process = 0
    } else if (offerStatuses?.includes('Resubmit required')) {
        process = 0
        textsOfferTimelineRent.stepInProcess = 'Resubmit and wait approve offer'
        textsOfferTimelineRent.descriptionInProcess = 'Our specialist will approve your offer soon after you resubmit'
    } else if (offerStatuses?.includes('Canceled') || offerStatuses?.includes('Canceled by yourself') || offerStatuses?.includes('Canceled by agent')) {
        process = 0
        textsOfferTimelineRent.stepInProcess = 'You canceled Offer!'
        textsOfferTimelineRent.descriptionInProcess = '' // 
    } else if (offerStatuses?.includes('Rejected')) {
        process = 1
        textsOfferTimelineRent.stepApprovedOrRejected = 'Your offer was rejected by BSO'
        textsOfferTimelineRent.descriptionApprovedOrRejected = 'Unfortunately, our specialist has decided to reject your offer because of the reason stated above'
    } else if (offerStatuses?.includes('Approved')) {
        process = 1
    } else if (offerStatuses?.includes('Confirmed')) {
        process = 2
        textsOfferTimelineRent.stepConfirmed = 'Landlord confirmed offer.'
        textsOfferTimelineRent.addTitle = 'Share client\'s contact details!'
        textsOfferTimelineRent.descriptionConfirmed = 'Share client\'s contact details '
    } else if (offerStatuses?.includes('Negotiated by agent') || offerStatuses?.includes('Negotiated by yourself')) {
        process = 2
        textsOfferTimelineRent.stepConfirmed = 'You requested the offer negotiation'
        textsOfferTimelineRent.descriptionConfirmed = 'Please wait until our specialist or the landlord takes action about your negotiation of this offer'
    } else if (offerStatuses?.includes('Negotiated by BSO')) {
        process = 2
        textsOfferTimelineRent.stepConfirmed = 'The BSO offered another option from landlord'
        textsOfferTimelineRent.descriptionConfirmed = 'If you are satisfied, send the customer data, or provide updated offer details'
    } else if (offerStatuses?.includes('Negotiated by landlord')) {
        process = 2
        textsOfferTimelineRent.stepConfirmed = 'The landlord offered another option'
        textsOfferTimelineRent.descriptionConfirmed = 'If you are satisfied, send the customer data, or provide updated offer details.'
    } else if (offerStatuses?.includes('Waiting booking form send')) {
        process = 3
    } else if (offerStatuses?.includes('Waiting booking form sign')) {
        process = 4
    } else if (offerStatuses?.includes('Waiting deposit pay slip')) {
        process = 5
    } else if (offerStatuses?.includes('Waiting for slip approval')) {
        process = 6
    } else if (offerStatuses?.includes('Slip approved')) {
        process = 7
    } else if (offerStatuses?.includes('Slip rejected')) {
        process = 7
        textsOfferTimelineRent.stepConfirmSlip = 'Slip rejected'
        // textsOfferTimelineRent.descriptionConfirmSlip = 'Description for slip rejected'
        textsOfferTimelineRent.descriptionConfirmSlip = 'Our specialist has rejected the deposit pay slip that you sent to us before. Please upload it in a better quality'
    } else if (offerStatuses?.includes('Completed')) {
        process = 8
    }
    const [line, setLine] = useState('middle')

    const iconProcess = (step: number, process: number) => {
        if (process + 1 > step || process === 8) {
            return undefined
        }
        return (<div className="ant-steps-item-icon">
            <span className="ant-steps-icon" style={{ color: 'black' }}>
                {step}
            </span>
        </div>
        )
    }

    const groupRent: TimelineItem[] = [
        {
            title: (
                <div>
                    <div className='lh-sm mb-1'>{textsOfferTimelineRent.stepInProcess}</div>
                    <div className={'d-flex align-items-center'}>
                        {process === 0 && <div className="fs-6 lh-1 fw-bold border-light-dark text-bold text-dark me-2">Status</div>}
                        <div className="subTitle">{process === 0 ? SchedulerStatus(offerStatuses ?? [], 'fs-6') : undefined}</div>
                    </div></div>
            ),
            description: textsOfferTimelineRent.descriptionInProcess,
            status: process === 0 && (offerStatuses?.includes('Canceled') || offerStatuses?.includes('Rejected')) ? 'finish' : undefined
        } satisfies TimelineItem,

        ...(offerStatuses?.includes('Canceled')

            ? []
            : [
                {
                    title: (
                        <div>
                            <div className='lh-sm mb-1'> {textsOfferTimelineRent.stepApprovedOrRejected} </div>
                            <div className={'d-flex align-items-center'}>
                                {process === 1 && <div className="fs-6 lh-1 fw-bold border-light-dark text-bold text-dark me-2">Status</div>}
                                <div className="subTitle">{
                                    process === 1
                                        ? offerStatuses.includes('Approved')
                                            ? <Approved fontsize={'fs-6'} statusText={'Sent to landlord'} />
                                            : SchedulerStatus(offerStatuses ?? [], 'fs-6')
                                        : undefined
                                }
                                </div>
                            </div>
                            {process === 1 && offerStatuses?.includes('Rejected') && offer?.attributes?.RejectReason &&
                                <ReasonBlock statuses={offerStatuses} reason={offer?.attributes?.RejectReason} />

                            }
                        </div>
                    ),
                    description: textsOfferTimelineRent.descriptionApprovedOrRejected
                },
                ...(!offerStatuses?.includes('Rejected')
                    ? [{
                        title: (
                            <div>
                                <div className='lh-sm mb-1'> {textsOfferTimelineRent.stepConfirmed} </div>
                                {textsOfferTimelineRent.addTitle ? <div className='lh-sm mb-1'> {textsOfferTimelineRent.addTitle} </div> : null}
                                <div className={'d-flex align-items-center'}>
                                    {process === 2 && <div className="fs-6 lh-1 fw-bold border-light-dark text-bold text-dark me-2">Status</div>}
                                    <div className="subTitle">{process === 2 ? SchedulerStatus(offerStatuses ?? [], 'fs-6') : undefined}</div>
                                </div></div>
                        ),
                        description: textsOfferTimelineRent.descriptionConfirmed
                    },

                    {
                        title: (
                            <div>
                                <div className='lh-sm mb-1'>{textsOfferTimelineRent.stepBFSending} </div>
                                <div className={'d-flex align-items-center'}>
                                    {process === 3 && <div className="fs-6 lh-1 fw-bold border-light-dark text-bold text-dark me-2">Status</div>}
                                    <div className="subTitle">{process === 3 ? SchedulerStatus(offerStatuses ?? [], 'fs-6 my-0') : undefined}</div>
                                </div>
                            </div>
                        ),
                        description: textsOfferTimelineRent.descriptionBFSending
                    },

                    {
                        title: (
                            <div>
                                <div className='lh-sm mb-1'>{textsOfferTimelineRent.stepBFSinging} </div>
                                <div className={'d-flex align-items-center'}>
                                    {process === 4 && <div className="fs-6 lh-1 fw-bold border-light-dark text-bold text-dark me-2">Status</div>}
                                    <div className="subTitle">{process === 4 ? SchedulerStatus(offerStatuses ?? [], 'fs-6 my-0') : undefined}</div>
                                </div>
                            </div>
                        ),
                        description: textsOfferTimelineRent.descriptionBFSinging
                    },
                    {
                        title: (
                            <div>
                                <div className='lh-sm mb-1'>{textsOfferTimelineRent.stepPayUploading} </div>
                                <div className={'d-flex align-items-center'}>
                                    {process === 5 && <div className="fs-6 lh-1 fw-bold border-light-dark text-bold text-dark me-2">Status</div>}
                                    <div className="subTitle">{process === 5 ? SchedulerStatus(offerStatuses ?? [], 'fs-6 my-0') : undefined}</div>
                                </div>
                            </div>
                        ),
                        description: textsOfferTimelineRent.descriptionPayUploading
                    },
                    {
                        title: (
                            <div>
                                <div className='lh-sm mb-1'>{textsOfferTimelineRent.stepPaySlip} </div>
                                <div className={'d-flex align-items-center'}>
                                    {process === 6 && <div className="fs-6 lh-1 fw-bold border-light-dark text-bold text-dark me-2">Status</div>}
                                    <div className="subTitle">{process === 6 ? SchedulerStatus(offerStatuses ?? [], 'fs-6 my-0') : undefined}</div>
                                </div>
                            </div>
                        ),
                        description: textsOfferTimelineRent.descriptionPaySlip
                    }, {
                        title: (
                            <div>
                                <div className='lh-sm mb-1'>{textsOfferTimelineRent.stepConfirmSlip} </div>
                                <div className={'d-flex align-items-center'}>
                                    {process === 7 && <div className="fs-6 lh-1 fw-bold border-light-dark text-bold text-dark me-2">Status</div>}
                                    <div className="subTitle">{process === 7 ? SchedulerStatus(offerStatuses ?? [], 'fs-6 my-0') : undefined}</div>
                                </div>
                            </div>
                        ),
                        description: textsOfferTimelineRent.descriptionConfirmSlip
                    },
                    {
                        title: (
                            <div >
                                <div className='lh-sm mb-1'> {textsOfferTimelineRent.stepCompleted} </div>
                                <div className={'d-flex align-items-center'}>
                                    {process === 8 && <div className="fs-6 lh-1 fw-bold border-light-dark text-bold text-dark me-2">Status</div>}
                                    <div className="subTitle d-block">{process === 8 ? SchedulerStatus(offerStatuses ?? [], 'fs-6 my-0') : undefined}</div>
                                </div></div>
                        ),
                        description: textsOfferTimelineRent.descriptionCompleted,
                        status: process === 8 ? 'finish' : undefined
                    } satisfies TimelineItem]
                    : [])

            ])

    ]
    const [sliceSteps, setSliceSteps] = useState([groupRent[process]])

    useEffect(() => {
        groupRent.map((el, i) => el.icon = iconProcess(i + 1, process))
    }, [sliceSteps, process])

    return (
        <div className='my-8 pt-8' style={{ maxWidth: '450px', width: '100%' }}>

            <Steps
                current={line === 'next' ? 0 : line === 'middle' ? 0 : process}
                percent={60}
                items={sliceSteps}
                direction='vertical'
            />

            <div className={'d-flex'} style={{ justifyContent: process === 0 || process === 8 ? 'flex-end' : 'space-evenly' }}>
                <div className="d-flex justify-content-between align-items-center mx-4">
                    {process !== 0 && <div className={'text-end'}>
                        <div
                            className={'btn btn-light-secondary py-2 text-end text-dark px-7'}
                            onClick={line === 'prew'
                                ? () => { setSliceSteps([groupRent[process]]); setLine('middle') }
                                : () => {
                                    setSliceSteps([...groupRent].slice(0, process + 1))
                                    setLine('prew')
                                }
                            }
                        >
                            <div className="d-flex justify-content-around align-items-center">
                                <Icon name={line === 'prew' ? 'play_circle' : 'expand_circle_up'} className={'fs-5'} />
                                <span className="ms-1 fs-8">{line === 'prew' ? 'Current step' : 'Previous steps'}</span>
                            </div>
                        </div>
                    </div>}
                    <div className="d-flex justify-content-between align-items-center mx-2">
                        {process !== 8 && !offerStatuses.includes('Canceled') && <div className={'text-end'}>
                            <div
                                className={'btn btn-light-secondary py-2 text-end text-dark px-7'}
                                onClick={
                                    line === 'next'
                                        ? () => { setSliceSteps([groupRent[process]]); setLine('middle') }
                                        : () => { setSliceSteps([...groupRent].slice(process)); setLine('next') }}
                            >
                                <div className="d-flex justify-content-between align-items-center">
                                    <Icon name={line === 'next' ? 'play_circle' : 'expand_circle_down'} className={'fs-5'} />
                                    <span className="ms-1 fs-8">   {line === 'next' ? 'Current step' : 'Next steps'}</span>
                                </div>
                            </div>
                        </div>}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TimelineRent
