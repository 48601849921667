import React, { useState } from 'react'
import { type Unit } from '../../../../store/slices/unitsSlice'
import Icon from '../../../icon/Icon'
import { Image } from 'antd'

export interface VideoForMyCustomModalForViewProps {
    currentUnit: Unit
}

export default function FloorPlanForMyCustomModalForView({ currentUnit }: VideoForMyCustomModalForViewProps): React.ReactElement {
    const [previewOpen, setPreviewOpen] = useState(false)
    return currentUnit?.attributes?.FloorPlan != null
        ? <div className={'mb-4'}>
            <div className="container mb-2 m-0 py-1 w-100 mt-4 position-relative" onClick={() => { setPreviewOpen(true) }} >
                <div className="row">
                    <div className="col-3 btn btn-bso max-w-100px px-auto opacity-75 align-items-center justify-content-center">
                        {/* <i className="fs-7x las la-map-marked-alt"></i> */}
                        <Icon name={'square_foot'} className="fs-7x  fs-lg-4x" imageSize={48} />
                    </div>

                    <div className="col-9 d-flex align-items-center bg-light-secondary">
                        <span className="d-flex flex-column align-items-start justify-content-center mx-2">
                            <span className="fs-3 fw-bold">
                                Floor plan
                            </span>
                            <span className="fs-7">Open the unit floor plan<i className="las la-angle-right"></i></span>
                        </span>
                    </div>
                </div>
            </div>
            {previewOpen
                ? <Image
                    wrapperStyle={{ display: 'none' }}
                    preview={{
                        visible: true,
                        zIndex: 10000,
                        onVisibleChange: (visible) => { setPreviewOpen(false) },
                        toolbarRender: () => (<></>)
                    }}
                    src={currentUnit?.attributes?.FloorPlan}
                />
                : <></>
            }
        </div>
        : <></>
}
