import React from 'react'
import UnitCard from '../../../units/cards/UnitCard'
import ChatUtils from '../../../../../functions/chats/ChatUtils'
import _ from 'lodash'
import Avatar from '../../../elements/Avatar'
import { useLocation } from 'react-router-dom'

interface UnitMessageProps {
    unit: any // Rep;ace with IUnit,
    sentBy: 'me' | 'other'
    time: Date
    senderName?: string
    user?: {
        FirstName: string
        FamilyName: string
        Avatar: string
    }
}

const UnitMessage = ({ unit, sentBy, time, senderName, user }: UnitMessageProps): JSX.Element => {
    const location = useLocation()
    // console.log({ sentBy })
    // console.log({ user })

    return (
        <div className={`w-100 d-flex justify-content-${sentBy === 'me' ? 'end' : 'start'}`}>
            <div className={'rounded position-relative'} style={{
                maxWidth: '80%',
                border: `solid 1px ${sentBy === 'me' ? '#fff200' : '#FFFFFF'}`
            }}>
                {sentBy === 'other'
                    ? <div className={'position-absolute top-0 start-0 rounded p-1 px-2 ms-1 mt-1 text-gray-600'}
                        style={{
                            background: '#FFFFFF',
                            zIndex: 100
                        }}
                    >
                        {senderName}
                    </div>
                    : <></>
                }
                <UnitCard unit={unit} inMessage sentBy={sentBy} />

                <div className={`w-100 px-4 py-2 position-absolute bottom-0 end-0 d-flex align-items-center ${user == null || _.includes(location.pathname, 'chats') ? 'justify-content-end' : 'justify-content-between'} fs-8 text-gray-600`}>
                    {
                        user != null && !_.includes(location.pathname, 'chats')
                            ? <div className={'d-flex align-items-center gap-2'}>
                                <Avatar
                                    image={user?.Avatar}
                                    size={'18px'}
                                />
                                <div>{user?.FirstName} {user?.FamilyName}</div>
                            </div>
                            : <></>
                    }
                    <span>{ChatUtils.formatTime(time)}</span>
                </div>
                {/* <div className={'position-absolute bottom-0 end-0 rounded p-1 px-2 me-1 mb-1 fs-8 text-gray-600'}
                    style={{
                        background: sentBy === 'me' ? '#fff200' : '#FFFFFF'
                    }}
                >
                    {ChatUtils.formatTime(time)}
                </div> */}
            </div>
        </div>
    )
}

export default UnitMessage
