import React, { useState } from 'react'
import { type Unit } from '../../../../store/slices/unitsSlice'
import FullScreenPlayer from '../../../../components/shared/videoPlayer/FullScreenPlayer'
import Icon from '../../../icon/Icon'

export interface VideoForMyCustomModalForViewProps {
    currentUnit: Unit
}

export default function VideoForMyCustomModalForView({ currentUnit }: VideoForMyCustomModalForViewProps): React.ReactElement {
    // const [playerOpen, setPlayerOpen] = useState(false)
    return currentUnit?.attributes?.Video != null
        ? <div className={'mb-4'}>
            <div className="container mx-auto mb-2 m-0 py-1 w-100 mt-4 position-relative" onClick={() => { window.open(currentUnit?.attributes?.Video, '_blank') }} >
                <div className="row">
                    <div className="col-3 btn btn-bso max-w-100px text-center opacity-75 mx-auto">
                        <Icon name={'play_arrow'} className="fs-7x fs-lg-4x" imageSize={48} />
                    </div>

                    <div className="col-9 d-flex align-items-center bg-light-secondary">
                        <span className="d-flex flex-column align-items-start justify-content-center mx-2">
                            <span className="fs-3 fw-bold">
                                Unit video
                            </span>
                            <span className="fs-7">Watch the unit video<i className="las la-angle-right"></i></span>
                        </span>
                    </div>

                </div>
            </div>
            {/* <FullScreenPlayer */}
            {/*    open={playerOpen} */}
            {/*    onClose={() => { */}
            {/*        setPlayerOpen(false) */}
            {/*    }} */}
            {/*    video={currentUnit?.attributes?.Video} */}
            {/*    name={currentUnit?.attributes?.Marketing?.Headline ?? ''} */}
            {/* /> */}
        </div>
        : <></>
}
