import React, {type ReactElement} from "react";
import {type IconName} from "../../../../../app/icon/iconsConfig";
import Badge, {type BadgeStyle} from "../../Badge/Badge";
import Icon from "../../../../../app/icon/Icon";
import styles from './TextButton.module.sass'
import SpinnerBorder from "../../Spinner/SpinnerBorder/SpinnerBorder";

interface TextButtonProps {
    text: string
    width: 'full' | 'fit-content'
    height: '34' | '37' | '42' | '48'
    textSize: 'small' | 'large'
    icon?: {
        position: 'start' | 'end',
        name: IconName
        filled?: boolean
    }
    badge?: {
        position: 'start' | 'end'
        text: string
        style: BadgeStyle
    }
    style: 'dark' | 'white' | 'gray' | 'bso'
    rounded?: '8' | '48'
    key?: string
    isLoading?: boolean
    onClick?: () => void
}

export default function TextButton ({text, icon, badge, width, height, style, key, isLoading, onClick, textSize, rounded = '8'}: TextButtonProps): ReactElement {
    let className = styles.button
    switch (rounded) {
        case '8':
            className += ` ${styles.button__br8}`
            break
        case '48':
            className += ` ${styles.button__br48}`
            break
    }
    switch (height) {
        case '34':
            className += ` ${styles.button__h34}`
            break
        case '37':
            className += ` ${styles.button__h37}`
            break
        case '42':
            className += ` ${styles.button__h42}`
            break
        case '48':
            className += ` ${styles.button__h38}`
            break
        default:
            break
    }
    switch (width) {
        case 'full':
            className += ` ${styles.button__wFull}`
            break
        case 'fit-content':
            className += ` ${styles.button__wFit}`
            break
        default:
            break
    }
    switch (style) {
        case 'dark':
            className += ` ${styles.button__dark}`
            break
        case 'white':
            className += ` ${styles.button__white}`
            break
        case 'gray':
            className += ` ${styles.button__gray}`
            break
        case 'bso':
            className += ` ${styles.button__bso}`
            break
        default:
            break
    }
    switch (textSize) {
        case 'small':
            className += ' fs-8'
            break
        case 'large':
            className += ` ${styles.button__large}`
            break
        default:
            break
    }
    return <div key={key} onClick={onClick} className={className}>
        { isLoading === true
            ? <SpinnerBorder/>
            : <>
                {
                    icon?.position === 'start'
                        ? <Icon name={icon.name} className={'fs-1'}/>
                        : <></>
                }
                {
                    badge?.position === 'start'
                        ? <Badge text={badge.text} style={badge.style} size={'13'}/>
                        : <></>

                }
                <span>
                    {text}
                </span>
                {
                    icon?.position === 'end'
                        ? <Icon name={icon.name} className={'fs-1'}/>
                        : <></>
                }
                {
                    badge?.position === 'end'
                        ? <Badge text={badge.text} style={badge.style} size={'13'}/>
                        : <></>

                }
            </>
        }
    </div>
}