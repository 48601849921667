import React, {type ReactElement, useRef} from "react";
import TextInput from "../../../components/shared/UIv2/Input/TextInput/TextInput";
import TextButton from "../../../components/shared/UIv2/Button/TextButton/TextButton";
import Separator from "../../../components/shared/UIv2/Separator/Separator";
import {Controller, useForm} from "react-hook-form";
import {login} from "../../../store/actionsCreators/authActions";
import {useAppSelector, useAppDispatch} from "../../../hooks/redux";
import {type IResponseObjectJSON} from "../../../types/userTypes/TAuth";
import {swalError, swalSuccess} from "../../../functions/swalOptions";
import {authSlice} from "../../../store/slices/authSlice";
import {useNavigate} from "react-router-dom";
import {regExps} from "../../../constants";
import styles from './SignInPage.module.sass'

export default function SignInPage (): ReactElement {
    const isLoading = useAppSelector((state) => state.auth.loading)
    const { control, handleSubmit, formState: { errors } } = useForm({
        mode: 'onChange'
    });
    const formRef = useRef<HTMLFormElement>(null)
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    console.log(errors)
    function submitForm (): void {
        void handleSubmit(async (data) => {
            const json: IResponseObjectJSON = await dispatch(login(data))
            if (json?.isSuccessful) {
                dispatch(authSlice.actions.authFetching(false))
                if (json.textNotification !== '') {
                    void swalSuccess(
                        json.textNotification,
                        () => {
                            navigate(json.textNavigate)
                        },
                        json?.title,
                        json?.icon
                    )
                } else {
                    navigate(json.textNavigate)
                    const currentForm = formRef.current
                    if (currentForm != null) {
                        const emailInput = currentForm.querySelector('input[name="email"]')
                        if (emailInput != null) {
                            emailInput.setAttribute('email', 'username')
                            currentForm.submit()
                        }
                    }
                }
            } else {
                void swalError(json?.textNotification, () => {
                    dispatch(authSlice.actions.authFetching(false))
                    navigate(json?.textNavigate)
                })
            }

        })()
    }
    return (
        <div className={styles.page}>
            <div className={styles.page__content}>
                <div>
                    <img src="/assets/media/logo512.png"
                        alt="BSO logo"
                        className={styles.page__logo}
                    />
                </div>
                <div className={styles.page__title}>
                    Welcome to BSO Club!
                </div>
                <form className={styles.page__form}
                    onSubmit={submitForm}
                    ref={formRef}
                >
                    <Controller
                        name={'email'}
                        control={control}
                        rules={{
                            required: "Email is required",
                            pattern: {
                                value: new RegExp(regExps.isAuthEmail),
                                message: "Invalid email address"
                            }
                        }}
                        render={({field}) => (
                            <TextInput
                                style={'white'}
                                value={field.value ?? ''}
                                onChange={field.onChange}
                                name={field.name}
                                placeholder={'whiterabbit@follow.me'}
                                type={'email'}
                                autoComplete={'username'}
                                error={errors.email?.message as string}
                            />
                        )}
                    />
                    <Controller
                        name={'password'}
                        control={control}
                        rules={{
                            required: "Password is required",
                            pattern: {
                                value: new RegExp(regExps.isAuthPassword),
                                message: "Invalid password"
                            }
                        }}
                        render={({field}) => (
                            <TextInput
                                style={'white'}
                                value={field.value ?? ''}
                                onChange={field.onChange}
                                name={field.name}
                                placeholder={'Password'}
                                type={'password'}
                                autoComplete={'current-password'}
                                error={errors.password?.message as string}
                            />
                        )}
                    />
                    <TextButton
                        isLoading={isLoading}
                        style={'bso'}
                        width={'full'}
                        text={'SIGN IN'}
                        textSize={'large'}
                        height={'48'}
                        rounded={'48'}
                        onClick={!isLoading ? submitForm : undefined}
                    />
                    <div className={styles.page__separatorWrapper}>
                        <Separator color={'gray-400'}/>
                        <div>OR</div>
                        <Separator color={'gray-400'}/>
                    </div>
                    <TextButton
                        style={'dark'}
                        width={'full'}
                        text={'SIGN UP'}
                        textSize={'large'}
                        height={'48'}
                        rounded={'48'}
                        onClick={() => {
                            navigate('/auth/v2/register_phone')
                        }}
                    />
                    <div className={styles.page__textAction}>
                        Forgot password? <span>Reset here</span>
                    </div>
                </form>
            </div>
        </div>
    )
}