
import { useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../hooks/redux";
import { type ISettings } from "../../../store/slices/userSlice";
import { type RootState } from "../../../store/store";
import { updateProfileDetails } from "../../../store/actionsCreators/userActions/userUpdateNotifies";
import { Space, Switch } from "antd";

export default function ProfileDetailsShowList(): JSX.Element {
    const currentUser = useAppSelector((state: RootState) => state.user.currentUser);
    const settings = currentUser?.Settings as ISettings[];

    const dispatch = useAppDispatch();


    const [showStats, setShowStats] = useState(settings?.[0]?.ShowStats || false);
    const [isLoadingStats, setIsLoadingStats] = useState(false);
    const [showLocation, setShowLocation] = useState(false);

    const delay = 1000;
    let timeoutStats: NodeJS.Timeout | null = null;

    const handleShowStatsChange = (checked: boolean) => {
        setShowStats(checked);
        setIsLoadingStats(true);

        if (timeoutStats != null) {
            clearTimeout(timeoutStats);
        }
        timeoutStats = setTimeout(() => {
            dispatch(updateProfileDetails({ checked })).finally(() => {
                setIsLoadingStats(false);
            });
        }, delay);
    };

    return (
        <>
            <div className="card-body p-0 my-4">
                <div className="d-flex flex-wrap align-items-center">
                    <div className="fs-6 fw-bold mb-1 text-uppercase">Show my statistic</div>
                    <div className="ms-auto">

                        <Space direction="vertical">
                            <Switch
                                checkedChildren={<span style={{ color: "#504f4f", fontWeight: "bold" }}>ON</span>}
                                unCheckedChildren="OFF"
                                checked={showStats}
                                className={"switch_broadcast"}
                                onChange={handleShowStatsChange}
                                loading={isLoadingStats}
                            />
                        </Space>
                    </div>
                </div>
                {/* <div className="separator separator-dashed my-4"></div>
                <div className="d-flex flex-wrap align-items-center">
                    <div className="fs-6 fw-bold mb-1 text-uppercase">Show my location</div>
                    <div className="ms-auto">
                        <Space direction="vertical">
                            <Switch
                                unCheckedChildren="OFF"
                                checkedChildren={<span style={{ color: "#504f4f", fontWeight: "bold" }}>ON</span>}
                                checked={showLocation}
                                onChange={(checked) => { setShowLocation(checked); }}
                            />
                        </Space>
                    </div>
                </div> */}
            </div>
        </>
    );
}
