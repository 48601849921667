import React, { useState, useEffect, useRef } from 'react'
import { Modal } from 'antd'
import { useNavigate } from 'react-router-dom'
import { type CarouselRef } from 'antd/lib/carousel'
import SlideFirst from './SlideFirst'
import { useAppDispatch } from '../../../hooks/redux'
import { setTutorialViewed } from '../../../store/slices/agentSlice'

import { verifyUser } from '../../../store/actionsCreators/authActions/registerAction'
import { FIRST_TUTORIAL_KEY, tutorials } from '../../../urls'


export default function TutorialLoadDocuments(): JSX.Element {
    const navigate = useNavigate()
    const [tutorialOpen, setTutorialOpen] = useState(true)
    const dispatch = useAppDispatch()
    const carouselRef = useRef<CarouselRef>(null)

    const image1 = '/assets/media/tutorials/1STARTUSINGBSOCLUB/1.png'
    const image2 = '/assets/media/tutorials/1STARTUSINGBSOCLUB/2.png'
    const image3 = '/assets/media/tutorials/1STARTUSINGBSOCLUB/3.png'
    const image4 = '/assets/media/tutorials/1STARTUSINGBSOCLUB/4.png'
    const image5 = '/assets/media/tutorials/1STARTUSINGBSOCLUB/5.png'

    const defaultImage = '/assets/media/istockphoto.jpg'

    const containerImages = [
        { imgStatic: image1, imgSrc: tutorials['start-using-bso-club']['slide-1'] },
        { imgStatic: image2, imgSrc: tutorials['start-using-bso-club']['slide-2'] },
        { imgStatic: image3, imgSrc: tutorials['start-using-bso-club']['slide-3'] },
        // { imgStatic: image4,imgSrc : tutorials['start-using-bso-club']['slide-4'] },
        { imgStatic: image5, imgSrc: tutorials['start-using-bso-club']['slide-5'] }
    ]

    const [carouselCurrent, setCarouselCurrent] = useState(0)
    const [showClose, setShowClose] = useState(false)
    const [verifyingLoading, setVerifyingLoading] = useState(false)

    const handleSlideChange = (index: number): void => {
        setCarouselCurrent(index)
        if (index === containerImages.length - 1) {
            setShowClose(true)
            dispatch(setTutorialViewed())
            window.localStorage.setItem(FIRST_TUTORIAL_KEY, 'true')
        }
    }

    useEffect(() => {
        if (!showClose) {
            const interval = setInterval(() => {
                if (containerImages.length - 1 !== carouselCurrent) {
                    carouselRef.current?.next()
                }
            }, 8000)
            return () => { clearInterval(interval) }
        }
    }, [showClose, carouselCurrent])

    const handleLeftClick = (): void => {
        if (carouselCurrent !== 0) {
            carouselRef.current?.prev()
        }
    }

    const handleNextClick = (): void => {
        if (containerImages.length - 1 !== carouselCurrent) {
            carouselRef.current?.next()
        }
    }

    const handleClose = (): void => {
        if (verifyingLoading) {
            return
        }

        setTutorialOpen(false)
        navigate('/agent/units/nav')
        dispatch(verifyUser(true))
        window.localStorage.setItem(FIRST_TUTORIAL_KEY, 'true')
    }

    return (
        <>
            <Modal
                centered
                open={tutorialOpen}
                style={{ height: '100vh', left: 0 }}
                width="100vw"
                zIndex={1501}
                closable={false}
                footer={null}
            >
                <div className="position-absolute container" style={{ height: '90vh', top: 0, left: 0, right: 0, bottom: 'auto', zIndex: 9990 }}>
                    <div className="row" style={{ height: 'inherit' }}>
                        <div className="col-6  opacity-0" onClick={handleLeftClick}>1</div>
                        <div className="col-6  opacity-0" onClick={handleNextClick}>2</div>
                    </div>
                </div>
                {showClose &&
                    <>
                        <div className="d-flex flex-row w-100 gap-2 position-absolute fixed-bottom pb-8" onClick={() => { handleClose() }} style={{ zIndex: 9999 }}
                        >
                            <div className="btn btn-primary fs-6 p-1 py-5 w-100 mx-4 fw-bold fs-2x">
                                GET START
                            </div>
                        </div>

                    </>}
                <div>
                    <SlideFirst onChange={handleSlideChange} ref={carouselRef} dotPosition={'top'}>
                        {containerImages.map((el, index) => (
                            <div key={`${index}`} className="text-start" style={{ position: 'relative', zIndex: 1 }}>
                                <div
                                    className="position-relative w-100vw h-100vh border border-gray-500 overflow-hidden bgi-no-repeat bgi-size-cover bgi-position-x-center bgi-position-y-center d-flex flex-column justify-content-center align-items-center"
                                    style={{ backgroundImage: `url(${el.imgStatic})` }}
                                >
                                    <img src={`${el.imgSrc}`} className="w-100" style={{ height: '100vh' }} />
                                </div>
                            </div>
                        ))}
                    </SlideFirst>
                </div>
            </Modal>
        </>
    )
}
