
import { getSubsDetails } from '../../../functions/agent/subscriptions/getSubsDetails'
import { getErrorss } from '../../../functions/errors/GetErrors'
import { ACCESS_KEY,  USER_ID_KEY, urlAPI } from '../../../urls'
import { dataFetchingFinish, modalSlice } from '../../slices/modalSlice'
import { type IOrder, userFetchingSuccess, type ISettings } from '../../slices/userSlice'
import { type AppDispatch, type RootState } from '../../store'
import { getQueryCurrentUser } from '../../../functions/shared/api/queries/getQueryCurrentUser'
import { getCurrentDateAndNextMonthDate } from '../../../functions/agent/date/getCurrentDateAndNextMonthDate'

export const createFreeSubscriptionAgent = (fromCancel? : boolean ) => async (dispatch: AppDispatch, getState: () => RootState) => {

    const chooseSubscription = 'Free'
    const jwt = window.localStorage.getItem(ACCESS_KEY)
    const userID = window.localStorage.getItem(USER_ID_KEY) ?? ''
    const currentUser = getState().user.currentUser
    const userOrders = currentUser.Orders as IOrder[] | null
    const userSettings = currentUser.Settings as ISettings[] | null
    let currentUserCoins = userSettings?.[0]?.MarketingCredits ?? 0
    let currentUserBroadcastCoins = userSettings?.[0]?.BroadcastCredits ?? 0
    currentUserBroadcastCoins = Number(currentUserBroadcastCoins) + Number(getSubsDetails(chooseSubscription).broadcastCoins)
    currentUserCoins = Number(currentUserCoins) + Number(getSubsDetails(chooseSubscription).advertiseCoins)

    const updatedSettings: ISettings | null = (userSettings != null)
        ? 
        {
            ...userSettings[0],
            MarketingCredits: currentUserCoins,
            BroadcastCredits: currentUserBroadcastCoins,
            ...(fromCancel
                ? 
                {    Areas : []  } :
                {} )
                    
        }
        
        
        : null

    console.log(updatedSettings,"updatedSettings")

    try {
        dispatch(modalSlice.actions.dataFetchingStart())
        const service = {
            __component: 'services.broker-subscription',
            Package: chooseSubscription,
            ExpiryDate: getCurrentDateAndNextMonthDate().oneMonthLaterFormatted,
            StartDate: getCurrentDateAndNextMonthDate().todayFormatted
        }

        const sendObject = {
            method: 'POST',
            collection: 'orders',
            query: 'populate[Service][fields][0]=*',
            body: {
                Name: `${currentUser.id}_${currentUser.email}_${chooseSubscription}_${new Date().getTime()}`,
                Statuses: ['Paid'],
                Amount: getSubsDetails(chooseSubscription).amount,
                Service: [service],
                User: `${userID}`
            }
        }
        const response = await fetch(`${urlAPI}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${jwt}`
            },
            body: JSON.stringify(sendObject)
        })

        const dataJSON = await response.json()
        console.log(dataJSON,"dataJSON")
        if (dataJSON.success) {
            if (dataJSON.response.error != null) {
                dispatch(dataFetchingFinish())

                return { ...dataJSON, textNavigate: '', isSuccessful: false, textNotification: getErrorss(dataJSON) }
            } else {
                dispatch(dataFetchingFinish())
                const userOrdersCopy = Array.isArray(userOrders) ? [...userOrders] : []
                userOrdersCopy.push({ id: dataJSON.response.data.id, ...dataJSON.response.data.attributes })

                const response = await fetch(`${urlAPI}`, {
                    method: 'POST',
                    body: JSON.stringify({

                        method: 'PUT',
                        collection: 'users',
                        query: getQueryCurrentUser(),
                        id: Number(userID),
                        body: {
                            Settings: [updatedSettings],
                            Orders: userOrdersCopy
                        }
                    }),
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${jwt}`
                    }
                })
                const responseJSON = await response.json()
                console.log(responseJSON,"responseJSON")
                if (responseJSON.success) {
                    if (responseJSON.response.error != null) {
                        dispatch(dataFetchingFinish())
                        return { ...responseJSON, textNavigate: '', isSuccessful: false, textNotification: '' }
                    } else {
                        dispatch(dataFetchingFinish())
                        dispatch(userFetchingSuccess(responseJSON?.response))
                        return { ...responseJSON, textNavigate: `/agent/modal/agent_settings_for_subscriptions/${userID}/Free`, isSuccessful: true, textNotification: '' }
                    }
                } else {
                    dispatch(dataFetchingFinish())
                    return { textNavigate: '', isSuccessful: false, textNotification: '' }
                }
            }
        } else {
            dispatch(dataFetchingFinish())
            return { textNavigate: '', isSuccessful: false, textNotification: '' }
        }
    } catch (error) {
        console.log(error)
        dispatch(dataFetchingFinish())
    }
}


