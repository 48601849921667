
import React from 'react'
import { useAppDispatch, useAppSelector } from '../../../../hooks/redux'
import { type RootState } from '../../../../store/store'
import { setScheduleFilters } from '../../../../store/slices/modalSlice'


export default function ScheduleAdminHeader(): JSX.Element {
    const scheduleFilters = useAppSelector((state: RootState) => state.modal.scheduleFilters)
    const dispatch = useAppDispatch()

    return (
        <div className="row w-100 g-0 fs-8">

            <span
                onClick={() => dispatch(setScheduleFilters({ ...scheduleFilters, sortUnitGroup: 'active' }))}
                className="col d-inline-block position-relative text-center cursor-pointer"
            >
                <div className="d-inline-block link-dark fw-bold my-4">ACTIVE</div>
                <span className={`d-inline-block position-absolute h-4px bottom-0 end-0 start-0  translate rounded-top ${scheduleFilters.sortUnitGroup === 'active' ? 'bg-dark' : 'bg-light'
                    }`}
                ></span>
            </span>
            <span
                onClick={() => dispatch(setScheduleFilters({ ...scheduleFilters, sortUnitGroup: 'sold' }))}
                className="col d-inline-block position-relative text-center cursor-pointer"
            >
                <div className="d-inline-block link-dark fw-bold my-4">SOLD / OFF MARKET</div>
                <span
                    className={`d-inline-block position-absolute h-4px bottom-0 end-0 start-0  translate rounded-top ${scheduleFilters.sortUnitGroup === 'sold' ? 'bg-dark' : 'bg-light'
                        }`}
                ></span>
            </span>
            {
                <span
                    onClick={() => dispatch(setScheduleFilters({ ...scheduleFilters, sortUnitGroup: 'archive' }))}
                    className="col d-inline-block position-relative text-center cursor-pointer"
                >
                    <div className="d-inline-block link-dark fw-bold my-4"> ARCHIVE</div>
                    <span
                        className={`d-inline-block position-absolute h-4px bottom-0 end-0 start-0  translate rounded-top ${scheduleFilters.sortUnitGroup === 'archive' ? 'bg-dark' : 'bg-light'
                            }`}
                    ></span>
                </span>
            }

        </div>
    )
}
