import React, { useCallback, useEffect, useRef, useState } from 'react'
import VoiceRecorder from './VoiceRecorder'
import FileAttacher from './FileAttacher'
import { useAppDispatch, useAppSelector } from '../../../../hooks/redux'
import { type RootState } from '../../../../store/store'
import { removePendingMessage, sendMessage, setPendingMessage } from '../../../../store/actionsCreators/chatActions'
import { setCurrentMessage } from '../../../../store/slices/chatSlice'
import { useParams } from 'react-router'
import ChatUtils from '../../../../functions/chats/ChatUtils'
import Icon from '../../../../app/icon/Icon'
import _ from 'lodash'

interface ChatInputProps {
    scrollRef: React.RefObject<HTMLDivElement>
    id?: number
    embedded?: boolean
    showLandlordWarning?: boolean
}
const ChatInput = ({ scrollRef, id, embedded, showLandlordWarning }: ChatInputProps): JSX.Element => {
    const adjustHeight = (e: any): void => {
        e.target.style.height = 'auto'; // '1px'
        // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
        e.target.style.height = (Math.min(e.target.scrollHeight, 150)) + 'px'
        // el.style.height = (el.scrollHeight > el.clientHeight) ? `${el.scrollHeight + 10}px` : '30px'
    }
    const dispatch = useAppDispatch()
    let chatID: string = String(id)
    if (chatID == null || id == null) {
        chatID = useParams().id as string ?? '0'
    }
    // const { id: chatID = '0' } = useParams()
    const { chat, currentMessage: message, pendingMessages } = useAppSelector((state: RootState) => state.chat)
    const inputRef = useRef<HTMLTextAreaElement>(null)
    const inputBarRef = useRef<HTMLDivElement>(null)
    const userDevice = useAppSelector((state) => state.app.userDevice)
    const [offsetTop, setOffsetTop] = useState(0)
    const userAgent = navigator.userAgent
    const isIPhone = _.includes(userAgent.toLowerCase(), 'iphone')
    const isAndroid = _.includes(userAgent.toLowerCase(), 'android')

    //  console.log({isIPhone, isAndroid})

    const openKeyboardHandler = useCallback((offsetTopArg: number) => {
        const offsetTop = offsetTopArg
        const chatWrapper: HTMLDivElement = document.querySelector('#chat-wrapper') as HTMLDivElement
        if (offsetTop != null && inputBarRef.current != null && chatWrapper != null && scrollRef.current != null && (isIPhone || userDevice === 'IPhone')) {
            chatWrapper.style.transition = 'height 400ms'
            chatWrapper.style.height = `calc(100vh - ${offsetTop}px)`

            scrollRef.current.style.transition = 'height 400ms'
            scrollRef.current.style.height = `calc(100vh - ${userDevice === 'IPhone' ? '12rem' : '10rem'} - ${offsetTop}px )`

            inputBarRef.current.style.transition = 'bottom 400ms'
            inputBarRef.current.style.bottom = `${offsetTop}px`

            window.scroll({
                top: 0,
                behavior: 'smooth'
            })
            window.onscroll = function () {
                window.scrollTo(0, 0)
            }
        }
    }, [inputBarRef, scrollRef])


    const scrollToBottom = () => {
        if (scrollRef.current != null) {
            scrollRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' });
        }
    };


    useEffect(() => {
        function handleKeyboardOpen(): void {
            if (offsetTop === 0) {
                setTimeout(() => {
                    const offsetTopArg = window?.visualViewport?.offsetTop ?? 0
                    console.log(offsetTopArg, "offsetTopArg")
                    openKeyboardHandler(offsetTopArg)
                    setOffsetTop(offsetTopArg)
                }, 1000)
            } else {
                //     for test
                //    openKeyboardHandler(offsetTop)
            }
        }

        function handleKeyboardClose(): void {
            setTimeout(() => {
                const activeElement = document.activeElement
                if (activeElement?.getAttribute('id') !== 'chat-input-textarea') {
                    window.onscroll = function () {
                    }
                    const chatWrapper: HTMLDivElement = document.querySelector('#chat-wrapper') as HTMLDivElement
                    if (chatWrapper != null) {
                        chatWrapper.style.height = '100vh'
                    }
                    if (scrollRef.current != null) {
                        scrollRef.current.style.height = `calc(100vh - ${userDevice === 'IPhone' ? '12rem' : '10rem'})`
                    }
                    if (inputBarRef.current != null) {
                        inputBarRef.current.style.bottom = '0px'
                    }
                }
            }, 100)
        }

        if (inputRef?.current != null) {
            inputRef?.current?.addEventListener('focus', handleKeyboardOpen)
            inputRef?.current?.addEventListener('blur', handleKeyboardClose)
            return () => {
                handleKeyboardClose()
                inputRef?.current?.removeEventListener('focus', handleKeyboardOpen)
                inputRef?.current?.removeEventListener('blur', handleKeyboardClose)
            }
        }
    }, [inputRef, inputBarRef, scrollRef, offsetTop])

    return (
        ChatUtils.isBSOBroadcast(chat)
            ? <></>
            : <>
                <div ref={inputBarRef} className={'position-fixed w-100 p-4 pb-8 bg-white'}
                    style={{ bottom: 0, zIndex: '100' }}
                >
                    {showLandlordWarning === true
                        ? <div className={'fs-6 text-center text-muted mb-2'}>
                            To keep the process of chatting confidential please use only the in application chat to connect to this broker
                        </div>
                        : <></>
                    }
                    <div className={'d-flex align-items-end gap-4'}>
                        <div className={'h-30px d-flex align-items-center'}>
                            <FileAttacher />
                        </div>
                        <textarea className={'scroll min-h-35px border-0 outline-0 w-100 p-2 bg-secondary rounded'}
                            id={'chat-input-textarea'}
                            ref={inputRef}
                            style={{ outline: 'none', wordWrap: 'break-word', resize: 'none', height: '30px' }}
                            value={message}
                            onKeyUp={adjustHeight}
                            onChange={(e) => {
                                dispatch(setCurrentMessage(e.target.value))
                            }}
                        />
                        {
                            message.trim().length > 0
                                ? <div className={'h-30px d-flex align-items-center'}
                                    onClick={() => {
                                        if (inputRef.current != null) {
                                            inputRef.current.focus()
                                            inputRef.current.style.height = '30px'
                                        }
                                        setTimeout(() => {
                                            openKeyboardHandler(offsetTop)
                                        }, 100)

                                        const id = pendingMessages.length
                                        dispatch(setPendingMessage(message, id))
                                        dispatch(sendMessage(+chatID)).finally(() => {
                                            dispatch(removePendingMessage(id))
                                        })
                                        scrollToBottom()
                                    }}
                                >
                                    <Icon
                                        name={'send'}
                                        className={'text-muted fs-3x'}
                                    />
                                </div>
                                : <div className={'h-30px d-flex align-items-center'}>
                                    <VoiceRecorder />
                                </div>
                        }
                    </div>
                </div>
                {embedded !== true
                    ? <div style={{ height: '54px' }} />
                    : <></>
                }
            </>
    )
}

export default ChatInput
