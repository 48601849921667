import React, {type ReactElement} from "react";
import TextButton from "../../../../components/shared/UIv2/Button/TextButton/TextButton";

interface SubmitButtonProps {
    loading: boolean
    row: Record<string, any>
    errors: any[]
    submitRef: React.RefObject<HTMLButtonElement>
}

export default function SubmitButton ({loading, row, errors, submitRef}: SubmitButtonProps): ReactElement {
    return (
        <TextButton
            isLoading={loading}
            text={row.content[0]}
            width={'full'}
            style={
                errors != null && Object.keys(errors).length > 0
                    ? 'gray'
                    : row.style === 'bso'
                        ? 'bso'
                        : 'dark'
            }
            height={'48'}
            textSize={'large'}
            rounded={'48'}
            onClick={() => {
                if ((errors == null || Object.keys(errors).length === 0) && !loading) {
                    submitRef?.current?.click()
                }
            }}
        />
    )
}