import { List } from 'antd'
import React, { useState } from 'react'

import { nanoid } from 'nanoid'

import {
    othrImage,
    placeholders,
    broadcastImageActive,
    broadcastImageCompleted
} from '../../../../urls'
import { type IChatMessage } from '../../../../types'
import BroadcastsListItem from './BroadcastsListItem'
import { getStatusBroadcast } from '../../../../functions/shared/date/getStatusBroadcast'

interface IProps {
    broadcastMessages: IChatMessage[]
}
export default function BroadcastList({ broadcastMessages }: IProps): JSX.Element {
    const [typeBroadcast, setTypeBroadcast] = useState('unit')
    const statuses: any = []

    const activeBroadcasts = broadcastMessages?.filter((messageBr) => messageBr.HideDate && getStatusBroadcast(messageBr.HideDate) === 'Active') // && messageBr.Text.split(':')[1] === typeBroadcast
    const completedBroadcasts = broadcastMessages?.filter((messageBr) => messageBr.HideDate && getStatusBroadcast(messageBr.HideDate) === 'Completed')//  && messageBr.Text.split(':')[1] === typeBroadcast
    statuses.push({
        status: 'Active',
        broadcasts: activeBroadcasts,
        broadcastsLength: activeBroadcasts?.length ?? 0

    })

    statuses.push({
        status: 'Completed',
        broadcasts: completedBroadcasts,
        broadcastsLength: completedBroadcasts?.length ?? 0

    })

    return (
        <>
            {/* <div className="bg-gray-300 my-2 mt-6 rounded rounded-4">
                <div className=" row w-100 g-3">
                    <span
                        onClick={() => { setTypeBroadcast('client') }}
                        className="col text-center ">
                        <div className={` fw-bold fs-6 my-2 py-3 ${typeBroadcast === 'client' ? 'bg-white  w-100 mx-2 rounded rounded-4' : ''}`}>CLIENTS</div>
                    </span>
                    <span
                        onClick={() => { setTypeBroadcast('unit') }}
                        className="col text-center">
                        <div className={` fw-bold fs-6 my-2 py-3 ${typeBroadcast === 'unit' ? 'bg-white  w-100 mx-2 rounded rounded-4 ms-1 ' : ''}`}>UNITS</div>
                    </span>
                </div>
            </div> */}
            {statuses[0].broadcastsLength > 0 || statuses[1].broadcastsLength > 0
                ? <>

                    <List
                        dataSource={statuses}
                        renderItem={(item: any, index: number) => {
                            const { status } = item
                            let srcImage = othrImage
                            switch (status) {
                                case 'Completed':
                                    srcImage = broadcastImageCompleted
                                    break

                                case 'Active':
                                    srcImage = broadcastImageActive
                                    break

                                default:
                                    break
                            }
                            return (
                                <>
                                    {item.broadcastsLength > 0
                                        ? <div key={nanoid()} className={'position-relative'}>
                                            <>
                                                <img
                                                    className=" w-100 h-100px object-fit-cover mb-2 rounded opacity-100 bg-light-info"
                                                    src={srcImage}
                                                    alt={status}
                                                />
                                                <span className="d-block fs-2 min-w-30px max-w-30px text-center position-absolute rounded-0 rounded-bottom rounded-end-0 translate-middle badge badge-secondary opacity-75" style={{ top: '12px', right: '-15px' }}>
                                                    {item.broadcastsLength}
                                                </span>
                                                <span className="ps-4 fs-5x fs-lg-3x fw-semi-bold text-white position-absolute " style={{ top: '6px', left: '0px', textShadow: '1px 1px 10px #332d2dd9' }}>
                                                    {status}
                                                </span> </>
                                        </div>
                                        : null}
                                    <BroadcastsListItem key={item.id} {...item} typeBroadcast={typeBroadcast} />

                                </>
                            )
                        }}
                    />
                </>
                : <div className={'d-flex position-relative align-items-end'}>
                    <img
                        className="w-75 object-fit-cover m-auto rounded rounded-4 opacity-100  mt-8"
                        src={placeholders.Broadcasts}
                        alt={'liable_picture'}
                    // style={{ height: '50vh' }}
                    />
                    <span className="fs-2x fs-lg-2 fw-bold text-white text-uppercase position-absolute text-center lh-sm" style={{ bottom: '14%', left: '50%', transform: 'translateX(-50%)', textShadow: '1px 1px 10px #332d2dd9' }}>
                        You don’t have any active {typeBroadcast === 'client' ? 'client preferences' : 'units'} broadcasts yet
                    </span>
                </div>
            }

        </>
    )
}
