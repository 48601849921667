import React, { useEffect } from 'react'
import { Link, useSearchParams } from 'react-router-dom'
import TabButton from '../../elements/TabButton'
import { useAppDispatch, useAppSelector } from '../../../../hooks/redux'
import { type RootState } from '../../../../store/store'
import { setChatSearchString, setInvitesSearchString } from '../../../../store/slices/chatSlice'
import { setSearchSearchString, unitsSlice } from '../../../../store/slices/unitsSlice'
import { userSetSearchString, userSlice } from '../../../../store/slices/userSlice'
import { setPage, setPageCount, setStopPagination } from '../../../../store/slices/paginationSlice'
import useUserType from '../../../../hooks/UseUserType'
import { swalSearchInfo } from '../../../../functions/swalOptions'
import { applyUnitSearchSearch } from '../../../../store/actionsCreators/unitsActions'
import Icon from '../../../../app/icon/Icon'

export const SEARCH_INFO_SWAL_SHOWN = 'bso-search-info-swal-shown'

const SearchHeader = (): JSX.Element => {
    const { userType } = useUserType()
    const currentUser = useAppSelector((state: RootState) => state.user.currentUser)
    const { searchString: messagesSearchString } = useAppSelector((state: RootState) => state.chat)
    const { searchSearchString: unitsSearchString } = useAppSelector((state: RootState) => state.units)
    const { searchString: usersSearchString } = useAppSelector((state: RootState) => state.user)
    const { invitesSearchString } = useAppSelector((state: RootState) => state.chat)
    const userDevice = useAppSelector((state: RootState) => state.app.userDevice)
    const dispatch = useAppDispatch()
    const [searchParams] = useSearchParams()
    const tab = searchParams.get('type') ?? (!(userType === 'Landlord' && currentUser.id !== Number(process.env.REACT_APP_BSO_USER_ID)) ? 'profiles' : 'messages')

    useEffect(() => {
        if (window.localStorage.getItem(SEARCH_INFO_SWAL_SHOWN) !== 'true') {
            void swalSearchInfo()
            window.localStorage.setItem(SEARCH_INFO_SWAL_SHOWN, 'true')
        }
    }, [])

    const getSearchData = (): any => {
        const data: any = {
            callback: (e: any) => { },
            searchString: ''
        }
        switch (tab) {
            case 'messages':
                data.callback = (e: any) => {
                    dispatch(setChatSearchString(e.target.value))
                }
                data.searchString = messagesSearchString
                break
            case 'invites':
                data.callback = (e: any) => {
                    dispatch(setInvitesSearchString(e.target.value))
                }
                data.searchString = invitesSearchString
                break
            case 'units':
                data.callback = (e: any) => {
                    dispatch(setSearchSearchString(e.target.value))
                    dispatch(applyUnitSearchSearch())
                }
                data.searchString = unitsSearchString
                break
            case 'profiles':
                data.callback = (e: any) => {
                    // dispatch(userSlice.actions.setProfiles([]))
                    dispatch(setPage(1))
                    dispatch(setPageCount(-1))
                    dispatch(setStopPagination(false))
                    dispatch(userSetSearchString(e.target.value))
                }
                data.searchString = usersSearchString
                break
            default:
                break
        }
        return data
    }
    return (
        <>
            <div className={` shadow-sm w-100 bg-white mb-lg-12 ${userDevice === 'IPhone' ? 'pt-10' : ''}`} style={{ position: 'absolute', zIndex: 1200 }}>
                <div className="row w-100 g-0 px-4">
                    <div className="d-flex align-items-center pt-4">
                        <Link to={`/${userType.toLowerCase()}/chats`}>
                            <Icon
                                name={'arrow_circle_left'}
                                className={'text-muted fs-4x fs-lg-3x me-4'}
                            />
                        </Link>
                        <div className="position-relative w-100">
                            <input
                                type="text"
                                className="form-control form-control-solid form-control-sm"
                                placeholder=""
                                autoComplete="false"
                                value={getSearchData().searchString}
                                onChange={getSearchData().callback}
                            />
                            <span className="btn btn-sm btn-icon position-absolute translate-middle top-50 end-0 me-n6 mt-0"
                                onClick={() => {
                                    switch (tab) {
                                        case 'messages':
                                            dispatch(setChatSearchString(''))
                                            break
                                        case 'units':
                                            dispatch(setSearchSearchString(''))
                                            break
                                        case 'profiles':
                                            dispatch(userSetSearchString(''))
                                            break
                                        default:
                                            break
                                    }
                                }}
                            >
                                <Icon
                                    name={'close'}
                                    className={'text-muted fs-1 me-4'}
                                    weight={300}
                                />
                            </span>
                        </div>
                        <span>
                            <Icon
                                name={'info'}
                                className={'text-muted fs-4x fs-lg-3x ms-4'}
                                onClick={() => {
                                    void swalSearchInfo()
                                }}
                            />
                        </span>
                        { /* <i className="bi bi-search fs-4 m-0 p-0"></i> */}
                    </div>
                </div>
                <div className={'d-flex'}>
                    { !(userType === 'Landlord' && currentUser.id !== Number(process.env.REACT_APP_BSO_USER_ID))
                        ? <TabButton
                            filter={'type'}
                            value={'profiles'}
                            label={'ACCOUNTS'}
                            isDefault
                        />
                        : <></>
                    }
                    <TabButton
                        filter={'type'}
                        value={'messages'}
                        label={'MESSAGES'}
                    />
                    <TabButton
                        filter={'type'}
                        value={'units'}
                        label={'AREAS'}
                    />
                    {/* <TabButton
                        filter={'type'}
                        value={'invites'}
                        label={'INVITES'}
                    /> */}
                </div>
            </div>
        </>
    )
}

export default SearchHeader
