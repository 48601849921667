import { nanoid } from 'nanoid'
import React from 'react'
import { useAppSelector } from '../../../../hooks/redux'
import { type RootState } from '../../../../store/store'

interface IProps {
    title: string
    imgPath: string
    callback: () => void
    notifies: number
    total: number
}

export default function ScheduleNavigateAction({ imgPath, title, callback, notifies, total }: IProps): JSX.Element {
    const filterNavigate = useAppSelector((state: RootState) => state.schedule.filterNavigate)
    return (
        <>

            <div className={'w-100px h-100px position-relative'} onClick={callback} >

                <div className={`w-100px h-100px d-flex flex-column align-items-center position-relative overflow-hidden rounded-4 ${filterNavigate === title.toLowerCase() ? 'bg-bso-semi-back' : 'bg-light-secondary'}`}>

                    <div className="position-relative w-100 h-100 ">
                        {notifies > 0
                            ? <span className="d-block fs-7 min-w-25px max-w-25px text-center position-absolute rounded-0 rounded-bottom-4   rounded-end-0 translate-middle badge badge-danger opacity-75" style={{ top: '9px', right: '-13px' }}>
                                {notifies > 0 ? <span className="text-white fs-7">{notifies} </span> : null}
                            </span>
                            : null
                        }
                        <img className="z-index-0" src={imgPath} alt="" style={{ height: 'calc(100% - 10px)', width: '100%' }} />
                    </div>
                    <div className={'d-flex align-items-center justify-content-center w-100 px-auto py-2 position-absolute bottom-0 z-index-2 overlay '} style={{ marginBottom: total > 0 ? '' : '5px' }}>
                        {total > 0
                            ? (
                                <span className=" fs-8  badge badge-secondary me-1">
                                    <span className="text-dark fs-8 ">{total}</span>
                                </span>)
                            : null

                        }
                        <span className={`fs-8 px-auto text-dark  ${filterNavigate === title.toLowerCase() ? 'fw-bold ' : 'fw-semibold'}  lh-1 text-center`}>{title}</span>
                    </div>
                </div>
            </div>

        </>

    )
}
