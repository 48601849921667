/* eslint-disable @typescript-eslint/no-misused-promises */
import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router'
import CustomFieldsHook from '../app/custom/CustomFieldsHook'
import CustomValidatorHook from '../app/custom/CustomValidatorHook'
import { AgentModals } from '../functions/agent/AgentModals'
import { confirmResetPasswordEmail, confirmResetPasswordPhone, login, resetPasswordEmail, resetPasswordPhone, verifyPhoneConfirm, verifyPhoneCreate, verifyResetPasswordEmail, verifyResetPasswordPhone } from '../store/actionsCreators/authActions'
import RulesModals from '../components/shared/auth/RulesModals'
import { useSearchParams } from 'react-router-dom'
import { type AppDispatch, type RootState, store } from '../store/store'
import {
    register,
    registerEnd,
    showLandlordDocumentRegistrationSwal
} from '../store/actionsCreators/authActions/registerAction'

import { scanFields } from '../store/actionsCreators/authActions/scanDocumentForRegisterActions'

import BackLoader from '../app/custom/loaders/BackLoader'
import { BSO_DEVELOP_MODE_ON_FRONT } from '../urls'
import { useAppDispatch } from '../hooks/redux'
import { uploadPOA } from "../store/actionsCreators/authActions/landlordAuthCallbacks/uploadPOAForLandlordRegisterActions";
import { chooseLandlordType } from "../store/actionsCreators/authActions/landlordAuthCallbacks/chooseLandlordType";

export default function AuthPage(): JSX.Element {

    const location = useLocation().pathname.slice(6)
    const [searchParams] = useSearchParams()
    const [referralID, setReferralID] = useState<number | undefined>()

    // const dispatch = useAppDispatch()
    useEffect(() => {
        const id = searchParams.get('referral-id')
        if (id != null) {
            setReferralID(+id)
        }
        // if (store.getState().auth.userCATEGORY === 'Landlord') {
        //     dispatch(showLandlordDocumentRegistrationSwal(location))
        // }
    }, [location])

    let callback

    switch (location) {
        case 'login':
            callback = login
            break
        case 'register1':
            callback = verifyPhoneCreate
            break
        case 'phone_verify':
            callback = verifyPhoneConfirm
            break
        case 'register_scan_emirates':
            callback = scanFields
            break
        case 'register_scan_emirates_front':
            callback = scanFields
            break
        case 'register_scan_passport':
            callback = scanFields
            break
        case 'register_choose_landlord_type':
            callback = chooseLandlordType
            break
        case 'register_upload_poa':
            callback = uploadPOA
            break
        case 'write_yourself_fields':
            callback = registerEnd
            break

        case 'register2':
            callback = (data: any) =>
                async (dispatch: AppDispatch, getState: (state: RootState) => RootState) =>
                    await dispatch(register(data, referralID))
            break

        // reset password step 1
        case 'reset_password_way_email':
            callback = verifyResetPasswordEmail
            break
        case 'reset_password_way_phone':
            callback = verifyResetPasswordPhone
            break

        // reset password step 2  send otp
        case 'password_verify_email':
            callback = confirmResetPasswordEmail
            break

        case 'password_verify':
            callback = confirmResetPasswordPhone
            break

        // reset password step 3 confirm
        case 'reset_password_final_email':
            callback = resetPasswordEmail
            break
        case 'reset_password_final':
            callback = resetPasswordPhone
            break

        default:
            break
    }

    const modal = [AgentModals().find((modal: any) => modal.id === location)]

    const isDevFront = window.localStorage.getItem(BSO_DEVELOP_MODE_ON_FRONT)
    return (
        <>

            <div

                className={'page d-flex flex-column flex-row-fluid '}
            >

                <div
                    className="header fixed-top bg-white h-auto shadow-sm fw-bold mw-450px mx-auto"
                    style={{ paddingTop: '39px' }}
                >

                    {isDevFront === 'true' ? <span className="badge badge-danger position-fixed" style={{ zIndex: '9999999', left: 2, top: 52 }}>DEV MODE FRONT</span> : null}

                    <div className="d-flex flex-row flex-fill justify-content-center m-4 ">
                        <img
                            alt="Logo"
                            src="/assets/media/logo.png"
                            className="h-60px mx-auto"
                        />
                    </div>
                </div>

                <div className="h-95px my-4 m-0"></div>
                <div className="content d-flex flex-center flex-fill p-4 mw-450px">
                    <div className="card shadow-sm m-0  w-100 mw-450px">
                        <div className="card-body p-4">
                            <CustomValidatorHook id={location} callback={callback}>
                                <CustomFieldsHook modal={modal} isAuth={true} />
                            </CustomValidatorHook>
                        </div>
                    </div>
                </div>

                <div className="m-0" style={{ height: '60px' }}></div>
                {(location === 'login' || location === 'register1') && <div
                    className="footer fixed-bottom bg-white shadow-sm fw-bold mw-450px mx-auto"
                >
                    <div className="d-flex flex-center flex-column-auto align-self-center p-4">
                        <div className="d-flex align-items-center fw-bold">
                            <div
                                className="text-muted text-hover-warning px-2 pb-6"
                            >
                                <RulesModals row={'About BSO Club'} />
                            </div>
                        </div>
                    </div>
                </div>}

            </div>

            <BackLoader />
        </>
    )
}
