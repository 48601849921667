import React from 'react'

export default function CallToBSO(): JSX.Element {
    const handleCall = () => {
        window.location.href = "tel:+971526477228";
    };
    return (
        <>
            <div className="container mb-2 m-0 py-1 w-100 mt-4 position-relative"
                onClick={handleCall}
                style={{ cursor: 'pointer' }}
            >
                <div className="row">

                    <div className="col-3 btn btn-flex btn-bso max-w-100px px-auto opacity-75">
                        <i className="fs-7x fs-lg-4x  las la-phone"></i>
                    </div>

                    <div className="col-9 d-flex align-items-center bg-light-secondary">
                        <span className="d-flex flex-column align-items-start justify-content-center mx-2">
                            <span className="fs-3 fw-bold">
                                Call to BSO
                            </span>
                            <span className="fs-7"> Connect with BSO for instructions <i className="las la-angle-right"></i></span>
                        </span>
                    </div>

                </div>
            </div>
        </>
    )
}
