import {type AppDispatch, type RootState} from "../../../../store";
import {dataFetchingFinish, dataFetchingStart} from "../../../../slices/modalSlice";
import {
    landlordAddUnitClearStorageV2,
    landlordAddUnitReadFromStorageV2,
    landlordAddUnitWriteToStorageV2
} from "../utils/landlordAddUnitHandleStorageV2";
import {addProperty} from "../../../propertyActions";
import dayjs from "dayjs";
import {createSingleLandlord, fetchUnits, singleUnitFetchingSuccess} from "../../../unitsActions";
import {type User} from "../../../../slices/userSlice";
import {nanoid} from "nanoid";
import {getQueryUnits} from "../../../../../functions/shared/api/queries/getQueryUnits";
import {ACCESS_KEY, urlAPI} from "../../../../../urls";
import {fetchUser} from "../../../userActions";
import {createDocument, fetchDocuments} from "../../../documentActions";
import nanToNull from "../../../../../functions/adapters/universal/nanToNull";
import indexedDBHandler from "../../../../../functions/utils/indexedDB/indexedDBHandler";

export const landlordSaveUnit = (unitID?: number | null) => async (dispatch: AppDispatch, getState: () => RootState): Promise<any> => {
    const jwt = window.localStorage.getItem(ACCESS_KEY) ?? ''
    dispatch(dataFetchingStart())
    const storedData = landlordAddUnitReadFromStorageV2()
    const currentUser = getState().user.currentUser as User
    const documents = getState().documents.documents
    try {
        // create title deed
        let titleDeedID: number | undefined = storedData?.landlord_create_unit_upload_title_deed?.id
        if (
            storedData?.landlord_create_unit_upload_title_deed?.isSaved !== true || // If not created yet
            storedData?.landlord_create_unit_upload_title_deed?.id == null
        ) {
            const titleDeedFile = await indexedDBHandler.getFile(storedData?.landlord_create_unit_upload_title_deed?.indexedDBKey)
            if (titleDeedFile != null) {
                const titleDeed = await dispatch(createDocument(
                    [titleDeedFile],
                    {
                        Category: 'Title Deed',
                        Type: [
                            {
                                __component: 'documents.title-deed',
                                Number: String(storedData?.landlord_create_unit_upload_title_deed?.response?.document?.number),
                                Type: ['Unit', 'Land', 'Villa'].includes(storedData?.landlord_create_unit_upload_title_deed?.response?.document?.type) ? storedData?.landlord_create_unit_upload_title_deed?.response?.document?.type : 'Unit',
                            }
                        ],
                        Statuses: ['In-process'],
                    },
                    'units',
                    undefined,
                    undefined,
                    `TitleDeed-${String(storedData?.landlord_create_unit_upload_title_deed?.response?.document?.property?.number)}-${String(storedData?.landlord_create_unit_property_info?.Property.Name)}-${nanoid()}`,
                ))
                // titleDeedID = titleDeed?.response?.data?.id
                if (titleDeed?.response?.data?.id != null) {
                    landlordAddUnitWriteToStorageV2('landlord_create_unit_upload_title_deed', {
                        ...storedData?.landlord_create_unit_upload_title_deed,
                        id: titleDeed?.response?.data?.id,
                        isSaved: true
                    })
                    titleDeedID = titleDeed?.response?.data?.id
                } else {
                    dispatch(dataFetchingFinish())
                    return {
                        isSuccessful: false,
                        textNotification: 'An error occurred saving Title Deed. Try to upload it again',
                        textNavigate: '/landlord/modal/v2/landlord_create_unit_upload_title_deed/-/-'
                    }
                }
            }
        }
        // create property
        let propertyID: number | undefined = storedData?.landlord_create_unit_property_info?.id
        if (
            storedData?.landlord_create_unit_property_info?.isSaved !== true || // If not created yet
            storedData?.landlord_create_unit_property_info?.id == null
        ) {
            const propertyResponse = await dispatch(addProperty({
                ...(storedData?.landlord_create_unit_property_info?.Property ?? {}),
                Type: 'Community'
            }, true))
            if (propertyResponse?.response?.data?.id != null) {
                landlordAddUnitWriteToStorageV2('landlord_create_unit_property_info', {
                    ...storedData?.landlord_create_unit_property_info,
                    isSaved: true,
                    id: propertyResponse?.response?.data?.id
                })
                propertyID = propertyResponse?.response?.data?.id
            } else {
                return {
                    isSuccessful: false,
                    textNotification: 'An error occurred saving property info. Try to input it again',
                    textNavigate: '/landlord/modal/v2/landlord_create_unit_property_info/-/-'
                }
            }
        }
        // create landlords
        let landlords: any
        const usersPassport = documents.find((doc) => (
            doc.attributes.User?.data?.id === currentUser.id &&
            doc.attributes?.Type?.[0]?.__component === 'documents.passport' &&
            dayjs().isBefore(dayjs(doc.attributes.ExpiryDate))
        ))
        let userLandlordID: number | undefined
        // if (usersPassport != null) {
        if ((usersPassport?.attributes as any)?.Landlord?.data?.id != null) {
            userLandlordID = (usersPassport?.attributes as any).Landlord.data.id
        } else {
            userLandlordID = await dispatch(createSingleLandlord({
                FirstName: currentUser?.ContactInfo?.FirstName,
                FamilyName: currentUser?.ContactInfo?.FamilyName,
                Country: currentUser?.ContactInfo?.Country,
                DateOfBirth: currentUser?.ContactInfo?.DateOfBirth,
                Gender: currentUser?.ContactInfo?.Gender,
                Passport: usersPassport?.id,
                DocumentNumber: usersPassport?.attributes?.Type?.[0]?.Number as string
            }))
        }
        // }
        if (storedData?.landlord_create_unit_choose_role?.role === 'owner') {
            // if user is landlord
            landlords = [
                {
                    Landlord: userLandlordID
                }
            ]
        } else {
            const landlordID = await dispatch(createSingleLandlord({
                FirstName: storedData.landlord_create_unit_id_verification?.owner_first_name,
                FamilyName: storedData.landlord_create_unit_id_verification?.owner_last_name,
                Country: storedData.landlord_create_unit_id_verification?.owner_country,
                DateOfBirth: storedData.landlord_create_unit_id_verification?.owner_dob,
                Gender: storedData.landlord_create_unit_id_verification?.owner_gender,
                Passport: storedData.landlord_create_unit_owner_passport?.id,
                DocumentNumber: storedData.landlord_create_unit_owner_passport?.Number
            }))
            landlords = [
                {
                    Landlord: landlordID,
                    Representatives: [
                        userLandlordID
                    ]
                }
            ]
        }
        // create unit
        const unitData = {
            RefID: `${String(storedData?.landlord_create_unit_upload_title_deed?.response?.document?.property?.number)}-${String(storedData?.landlord_create_unit_property_info?.Property.Name)}-${nanoid()}`,
            Number: storedData?.landlord_create_unit_unit_info?.Number,
            SizeArea: nanToNull(storedData?.landlord_create_unit_unit_info?.SizeArea),
            Bedrooms: nanToNull(storedData?.landlord_create_unit_unit_info?.Bedrooms),
            Bathrooms: nanToNull(storedData?.landlord_create_unit_unit_info?.Bathrooms),
            Type: storedData?.landlord_create_unit_unit_info?.Type,
            GuestBathroom: storedData?.landlord_create_unit_unit_info?.GuestBathroom,
            MaidsRoom: storedData?.landlord_create_unit_unit_info?.MaidsRoom,
            // Fit: storedData?.landlord_create_unit_unit_features?.Fit,
            // View: storedData?.landlord_create_unit_unit_features?.View,
            // Amenities: storedData?.landlord_create_unit_unit_features?.Amenities,
            // Appliances: storedData?.landlord_create_unit_unit_features?.Appliances,
            // Features: storedData?.landlord_create_unit_unit_features?.Features,
            Utilities: {
                ACCount: storedData?.landlord_create_unit_unit_info?.Utilities?.ac_count_unknown === true
                    ? undefined
                    : nanToNull(storedData?.landlord_create_unit_unit_info?.Utilities?.ACCount)
            },
            Documents: [
                titleDeedID
            ],
            Landlords: landlords,
            Property: propertyID,
            User: currentUser.id
        }

        const bodyObject = {
            method: 'POST',
            collection: 'units',
            body: unitData,
            query: getQueryUnits()
        }

        const response = await fetch(`${urlAPI}`, {
            method: 'POST',
            body: JSON.stringify(bodyObject),
            headers: {
                'Content-Type': 'application/json',
                'BSO-Hash': '02ns30nsBSO!',
                Authorization: `Bearer ${jwt}`
            }
        })

        const responseJSON = await response?.json() as unknown as any
        responseJSON.isSuccessful = responseJSON?.response?.error == null
        if (responseJSON.isSuccessful === true) {
            responseJSON.textNotification = ''
            responseJSON.textNavigate = '/landlord/my_units'
            dispatch(dataFetchingFinish())
            const {isSuccessful} = dispatch(singleUnitFetchingSuccess(responseJSON))
            if (!isSuccessful) {
                void dispatch(fetchUser(true))
                void dispatch(fetchUnits(true))
            }

            void dispatch(fetchDocuments(true))
            void indexedDBHandler.deleteFile(storedData?.landlord_create_unit_upload_title_deed?.indexedDBKey)
            landlordAddUnitClearStorageV2()
        }
        return responseJSON
    } catch (err) {
        console.log('Error while saving unit', err)
    }
    dispatch(dataFetchingFinish())
    return {
        isSuccessful: false
    }
}