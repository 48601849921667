import React, { useEffect, useState } from 'react'
import StartSub from './StartSub'

import MidSub from './MidSub'
import EndSub from './EndSub'
import { useAppDispatch, useAppSelector } from '../../../../hooks/redux'
import { store, type RootState } from '../../../../store/store'
import { mainSwalDeal, swalJustAlertAndRedirect } from '../../../../functions/swalOptions'

import { Navigate, useNavigate } from 'react-router-dom'
import { type IOrder, type OrderService } from '../../../../store/slices/userSlice'
import { createFreeSubscriptionAgent } from '../../../../store/actionsCreators/subscriptionsActions/createFreeSubscriptionAgent'

import { Switch } from 'antd'
import swalsConfig from '../../../../app/texts/swalTexts'

import { BSO_DEVELOP_MODE_ON_FRONT, USER_ID_KEY } from '../../../../urls'

import { createSubscriptionAgent } from '../../../../store/actionsCreators/subscriptionsActions/createSubscriptionAgent'
import { upgradeDowngradeSubscriptionAgent } from '../../../../store/actionsCreators/subscriptionsActions/upgradeDowngradeSubscriptionAgent'
import { cancelSubscriptionAgent } from '../../../../store/actionsCreators/subscriptionsActions/cancelSubscriptionAgent'
import { getCurrentSub } from '../../../../functions/agent/subscriptions/getCurrentSub'




export default function SubscriptionsList(): JSX.Element {
    const isDev = process.env.REACT_APP_IS_DEV === 'true' || window.localStorage.getItem(BSO_DEVELOP_MODE_ON_FRONT) === 'true'
    const userID = window.localStorage.getItem(USER_ID_KEY)
    const { activeSub, activeSubPeriod, latestServiceAgentSubscription } = getCurrentSub()






    const currentUser = useAppSelector((state: RootState) => state.user.currentUser)

    const agentsFreeSubscriptions = (currentUser.Orders as IOrder[])?.filter(
        (order: IOrder) => order?.Service?.some((service) => service.__component === 'services.broker-subscription' && service.Package.includes("Free"))
    )
    // && agentsFreeSubscriptions.length

    const [selectedSubscription, setSelectedSubscription] = useState<string>(activeSub)
    const [selectedPeriod, setSelectedPeriod] = useState<string>(activeSubPeriod ?? "month")
    const isChangingSwitch = !(activeSub !== "" && activeSub !== 'Free') // false // latestServiceAgentSubscription?.SessionID?.includes('cs_live') 
    const [isTestMode, setIsTestMode] = useState(latestServiceAgentSubscription?.SessionID?.includes('cs_live') ? false : isDev) //  ? false : isDev
    console.log({ activeSub, selectedSubscription, activeSubPeriod, selectedPeriod })
    const { buttonSubscriptionsOpen } = useAppSelector((state: RootState) => state.modal)
    const dispatch = useAppDispatch()
    const navigate = useNavigate()


    useEffect(() => {
        if (!activeSub) {
            setSelectedSubscription('Free')
        } else {
            setSelectedSubscription(activeSub)
        }
    }, [buttonSubscriptionsOpen, activeSub, isDev])

    const handleSubscriptionChange = async (renew?: string): Promise<void> => {
        let isUpgradeSub = false
        let orderID: number | undefined
        if (Boolean(activeSub) && activeSub !== 'Free') {
            if (activeSub !== selectedSubscription || activeSubPeriod !== selectedPeriod) {
                isUpgradeSub = true
                orderID = latestServiceAgentSubscription.id
            }
        }
        console.log({ isUpgradeSub, orderID, isTestMode })

        if (isDev) {
            void mainSwalDeal(
                swalsConfig.mainDeal.textSubscriptionListRenew(selectedSubscription === 'Free' && latestServiceAgentSubscription && orderID),
                swalsConfig.mainDeal.titleSubscriptionList,
                swalsConfig.mainDeal.confirmButtonSubscriptionList(selectedSubscription === 'Free'),
                swalsConfig.mainDeal.denyButtonSubscriptionList
            ).then(async (result: any) => {

                if (result.isConfirmed) {
                    let createOrder: any
                    if (selectedSubscription === 'Free') {
                        if (latestServiceAgentSubscription && orderID) {
                            createOrder = await dispatch(cancelSubscriptionAgent({ orderID, isTestMode }))
                        } else {
                            createOrder = await dispatch(createFreeSubscriptionAgent())
                        }
                    } else {
                        if (isUpgradeSub) {
                            if (orderID === undefined) {
                                console.error('Variable orderID is not assigned')
                                return
                            }
                            createOrder = await dispatch(upgradeDowngradeSubscriptionAgent(selectedSubscription, { orderID, selectedPeriod, isTestMode })) // : isDev 
                        } else {
                            createOrder = await dispatch(createSubscriptionAgent(selectedSubscription, undefined, { selectedPeriod, isTestMode })) // : isDev 
                        }
                    }

                    if (createOrder?.isSuccessful) {
                        if (isUpgradeSub) {
                            await swalJustAlertAndRedirect(swalsConfig.justAlertAndRedirect.messageAfterUpgradeSubscription(selectedSubscription)).then(async (result: any) => {
                                if (result.isConfirmed) {
                                    if (selectedSubscription === 'Premium') {
                                        navigate('/agent/profile/me')
                                    } else {
                                        navigate(`/agent/modal/agent_settings_for_subscriptions/${userID}/${selectedSubscription}`)
                                    }
                                }
                            })
                        } else {
                            if (selectedSubscription === 'Free') {
                                navigate(`/agent/modal/agent_settings_for_subscriptions/${userID}/${selectedSubscription}`)
                            } else {
                                window.open(createOrder?.url, '_blank')
                            }
                        }
                    } else {
                        if (selectedSubscription === 'Free') {
                            navigate(`/agent/modal/agent_settings_for_subscriptions/${userID}/${selectedSubscription}`)
                        } else {
                            window.open(createOrder?.url, '_blank')
                        }
                    }
                }
            })
        } else {
            let createOrder: any
            if (selectedSubscription === 'Free') {
                if (latestServiceAgentSubscription && orderID) {
                    createOrder = await dispatch(cancelSubscriptionAgent({ orderID, isTestMode })) // : isDev 
                } else {

                    createOrder = await dispatch(createFreeSubscriptionAgent())
                }
            } else {
                if (isUpgradeSub) {
                    if (orderID === undefined) {
                        console.error('Variable orderID is not assigned')
                        return
                    }
                    createOrder = await dispatch(upgradeDowngradeSubscriptionAgent(selectedSubscription, { orderID, selectedPeriod, isTestMode })) // : isDev 
                } else {
                    createOrder = await dispatch(createSubscriptionAgent(selectedSubscription, undefined, { selectedPeriod, isTestMode })) // : isDev 
                }
            }

            if (createOrder?.isSuccessful) {
                if (isUpgradeSub) {
                    if (selectedSubscription === 'Premium') {
                        await swalJustAlertAndRedirect(swalsConfig.justAlertAndRedirect.messageAfterUpgradeSubscription(selectedSubscription)).then(async (result: any) => {
                            if (result.isConfirmed) {
                                navigate('/agent/profile/me')
                            }
                        })
                    } if (selectedSubscription === 'Standard') {
                        await swalJustAlertAndRedirect(swalsConfig.justAlertAndRedirect.messageAfterUpgradeSubscription(selectedSubscription)).then(async (result: any) => {
                            if (result.isConfirmed) {
                                navigate(`/agent/modal/agent_settings_for_subscriptions/${userID}/Standard`)
                            }
                        })
                    } if (selectedSubscription === 'Free') {
                        navigate(`/agent/modal/agent_settings_for_subscriptions/${userID}/${selectedSubscription}`)
                    }
                } else {
                    if (selectedSubscription === 'Free') {
                        navigate(`/agent/modal/agent_settings_for_subscriptions/${userID}/${selectedSubscription}`)
                    } else {
                        window.open(createOrder?.url, '_blank')
                    }
                }
            }
        }
    }


    const sharedProps = {
        handleSubscriptionChange,
        activeSub,
        activeSubPeriod,
        selectedPeriod,
        selectedSubscription,
        setSelectedSubscription,
    };

    console.log(isDev && !isTestMode && !(activeSub === 'Free' || activeSub === ''))
    console.log(isDev, !isTestMode, activeSub)

    return (

        <div>

            <div className="d-flex flex-row flex-fill justify-content-center m-4 ">
                <img
                    alt="Logo"
                    src="/assets/media/logo.png"
                    className="h-60px mx-auto"
                />
            </div>


            {isDev
                ?
                <>
                    <div className='text-end my-2'>
                        <Switch checkedChildren="TEST MODE" size="default" unCheckedChildren="REAL MONEY MODE" defaultValue={isTestMode}
                            defaultChecked={isTestMode}
                            disabled={!isChangingSwitch}
                            onChange={(checked) => {
                                if (isChangingSwitch) {
                                    setIsTestMode(checked);
                                }
                            }}
                        />
                    </div>
                    <div className="nav-group nav-group-outline mx-auto mb-4 text-center" >
                        {/* <button className={`btn btn-color-gray-400 btn-active btn-active-secondary px-8 py-3 me-2 ${selectedPeriod === 'weekly' ? 'active' : ''} `} onClick={() => { setSelectedPeriod('weekly') }}>
                        Weekly
                    </button> */}
                        <button className={`btn btn-color-gray-400 btn-active btn-active-secondary px-8 py-3  ${selectedPeriod === 'month' ? 'active' : ''} `} onClick={() => { setSelectedPeriod('month') }} >
                            Monthly
                        </button>
                        <button className={`btn btn-color-gray-400 btn-active btn-active-secondary px-8 py-3 ${selectedPeriod === 'annual' ? 'active' : ''} `} onClick={() => { setSelectedPeriod('annual') }}>
                            Annual
                        </button>
                    </div></>
                : null
            }
            {/* && activeSub !== 'Free'  !isTestMode &&  isDev && */}
            <div className="mb-lg-12">
                <div className="nav flex-column" role="tablist">
                    {<StartSub  {...sharedProps} />}
                    {isDev
                        ? <>
                            <MidSub  {...sharedProps} />
                            <EndSub  {...sharedProps} />
                        </>
                        : null}

                </div>

            </div>

        </div>
    )
}
