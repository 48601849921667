import {type NavigateFunction} from "react-router-dom";
import {type AppDispatch, type RootState} from "../../../../store";
import _ from "lodash";
import {AgentModals} from "../../../../../functions/agent/AgentModals";
import {landlordAddUnitReadFromStorageV2} from "../utils/landlordAddUnitHandleStorageV2";
import searchFuse from "../../../../../functions/search/searchFuse";

export const fillValuesForAddUnitModalV2 = (modalID: string, unitID: number, navigate: NavigateFunction) => (dispatch: AppDispatch, getState: () => RootState): { modal: [Record<string, unknown>], initialState: Record<string, unknown> } => {
    const modal = _.cloneDeep(
        AgentModals().find((modal: any) => modal.id === modalID)
    )
    const initialState: Record<string, unknown> = {}
    const storedData = landlordAddUnitReadFromStorageV2()
    const rawUnit = getState().units.units.find((unit) => unit.id === unitID)

    if (rawUnit == null) {
        modal.form.forEach((row: Record<string, any>) => {
            switch (row.id) {
                case 'owner_first_name':
                    initialState[row.id] = storedData.landlord_create_unit_owner_passport?.Name?.split(' ')?.[1]
                    break
                case 'owner_last_name':
                    initialState[row.id] = storedData.landlord_create_unit_owner_passport?.Name?.split(' ')?.[0]
                    break
                case 'owner_gender':
                    initialState[row.id] = storedData.landlord_create_unit_owner_passport?.Gender
                    break
                case 'owner_dob':
                    initialState[row.id] = storedData.landlord_create_unit_owner_passport?.DateOfBirth
                    break
                case 'owner_country':
                    initialState[row.id] = storedData.landlord_create_unit_owner_passport?.Country
                    break


                case 'title_deed_info':
                    initialState.title_deed_number = storedData.landlord_create_unit_upload_title_deed?.response?.document?.number
                    initialState.title_deed_year = storedData.landlord_create_unit_upload_title_deed?.response?.document?.year
                    break
                case 'unit_type':
                    initialState[row.id] = storedData.landlord_create_unit_upload_title_deed?.response?.document?.property?.type
                    break
                case 'owner_name':
                    initialState[row.id] = storedData.landlord_create_unit_upload_title_deed?.response?.document?.owners?.[0]?.name
                    break
                case 'Property.Name':
                    initialState[row.id] = storedData.landlord_create_unit_upload_title_deed?.response?.document?.property?.name
                    break
                case 'Property.Area':
                    if (storedData.landlord_create_unit_upload_title_deed?.response?.document?.property?.area != null) {
                        // eslint-disable-next-line @typescript-eslint/no-unused-vars
                        const searchedAreas = searchFuse(
                            getState()?.app?.config?.all?.Area ?? [],
                            storedData.landlord_create_unit_upload_title_deed.response.document.property.area,
                            0.4,
                            []
                        )
                        initialState[row.id] = searchedAreas[0]?.item
                    }
                    break
                case 'Property.GoogleMapsLink':
                    initialState[row.id] = storedData.landlord_create_unit_property_info?.Property?.GoogleMapsLink
                    break
                case 'Type':
                    initialState[row.id] = storedData.landlord_create_unit_unit_info?.Type
                    row.onChange = (value: string) => {
                        const bathrooms = document.querySelector('#Bathrooms')
                        const bedrooms = document.querySelector('#Bedrooms')
                        const maidsRoom = document.querySelector('#MaidsRoom')
                        const guestBathroom = document.querySelector('#GuestBathroom')

                        const bathroomsLabel = bathrooms?.querySelector('#Bathrooms-label')
                        const bedroomsLabel = bedrooms?.querySelector('#Bedrooms-label')

                        if (value === 'Commercial') {
                            if (bathroomsLabel != null) {
                                bathroomsLabel.innerHTML = 'Restrooms Count'
                            }
                            if (bedroomsLabel != null) {
                                bedroomsLabel.innerHTML = 'Rooms Count'
                            }
                            maidsRoom?.classList?.add('d-none')
                            guestBathroom?.classList?.add('d-none')
                        } else {
                            if (bathroomsLabel != null) {
                                bathroomsLabel.innerHTML = 'Bathrooms Count'
                            }
                            if (bedroomsLabel != null) {
                                bedroomsLabel.innerHTML = 'Bedrooms Count'
                            }
                            maidsRoom?.classList?.remove('d-none')
                            guestBathroom?.classList?.remove('d-none')
                        }
                    }
                    break
                case 'ac_count_unknown':
                    row.onChange = (value: boolean) => {
                        const acCountInputWrapper = document.querySelector('[id="Utilities.ACCount"]')
                        if (value) {
                            acCountInputWrapper?.classList?.add('d-none')
                        } else {
                            acCountInputWrapper?.classList?.remove('d-none')
                        }
                    }
                    break
                case 'SizeArea':
                    initialState[row.id] = storedData.landlord_create_unit_unit_info?.SizeArea
                    break
                case 'Bedrooms':
                    initialState[row.id] = storedData.landlord_create_unit_unit_info?.Bedrooms
                    break
                case 'Bathrooms':
                    initialState[row.id] = storedData.landlord_create_unit_unit_info?.Bathrooms
                    break
                case 'MaidsRoom':
                    initialState[row.id] = storedData.landlord_create_unit_unit_info?.MaidsRoom
                    break
                case 'GuestBathroom':
                    initialState[row.id] = storedData.landlord_create_unit_unit_info?.GuestBathroom
                    break
                case 'Number':
                    initialState[row.id] = storedData.landlord_create_unit_upload_title_deed?.response?.document?.property?.number
                    break
                case 'Utilities.ACCount':
                    initialState[row.id] = storedData.landlord_create_unit_unit_features?.Utilities?.ACCount
                    break
                case 'Fit':
                    initialState[row.id] = storedData.landlord_create_unit_unit_features?.Fit
                    break
                case 'Appliances':
                    initialState[row.id] = storedData.landlord_create_unit_unit_features?.Appliances
                    break
                case 'Features':
                    initialState[row.id] = storedData.landlord_create_unit_unit_features?.Features
                    break
                case 'Amenities':
                    initialState[row.id] = storedData.landlord_create_unit_unit_features?.Amenities
                    break
                case 'View':
                    initialState[row.id] = storedData.landlord_create_unit_unit_features?.View
                    break
                default:
                    break
            }
        })
    }

    return {
        modal: [modal],
        initialState
    }
}