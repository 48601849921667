import {  urlAPI, urlAUTH, ACCESS_KEY, USER_ID_KEY } from '../../../urls'
import { getErrorss } from '../../../functions/errors/GetErrors'
import { type ResponseObject, type ApiResponse, type IVerifyPhoneFormValues, type IVerifyPhoneJSON, type IRegisterForm } from '../../../types/userTypes/TAuth'
import { authSlice } from '../../slices/authSlice'
import { type AppDispatch, type RootState } from '../../store'
import { modalSlice } from '../../slices/modalSlice'
import { type User } from '../../slices/userSlice'
import { getQueryCurrentUser } from '../../../functions/shared/api/queries/getQueryCurrentUser'

// change email
export const changeEmailVerifyPhone = (formValues: IVerifyPhoneFormValues) => async (dispatch: AppDispatch) => {
    dispatch(modalSlice.actions.dataFetchingStart())
    try {
        const sendObject = {
            ...formValues, renew: true // ??? false
        }
        // console.log(sendObject, 'sendObject')
        const response = await fetch(`${urlAUTH}/sendMobileOTP`, {
            method: 'POST',
            body: JSON.stringify(sendObject),
            headers: {
                'Content-Type': 'application/json'
            }
        })

        const dataJSON: IVerifyPhoneJSON = await response.json()
        if (dataJSON.response?.sent) {
            dispatch(modalSlice.actions.dataFetchingFinish())
            dispatch(authSlice.actions.numberSuccess(formValues.phone))
            return { ...dataJSON, textNavigate: '/agent/modal/change_email_for_contacts_my_check_otp/not/not', isSuccessful: true, textNotification: 'A code was sent to your mobile phone, please enter this code to confirm the email change ' }
        }
        return { ...dataJSON, textNavigate: '', isSuccessful: false, textNotification: getErrorss(dataJSON) }
    } catch (error) {
        console.log(error)
    }
}

export const changeEmailVerifyPhoneConfirm = (formValues: IVerifyPhoneFormValues) => async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
        dispatch(modalSlice.actions.dataFetchingStart())
        // dispatch(authSlice.actions.authFetching(true))
        const number = getState().auth.userNUMBER

        const sendObject = {
            ...formValues, renew: true, phone: number //  renew: false,
        }

        const response = await fetch(`${urlAUTH}/checkMobileOTP`, {
            method: 'POST',
            body: JSON.stringify(sendObject),
            headers: {
                'Content-Type': 'application/json'
            }
        })
        const dataJSON = await response.json() as ResponseObject

        if (dataJSON.success && dataJSON.response?.approved) {
            dispatch(modalSlice.actions.dataFetchingFinish())
            // The codes match, you can change the email
            return { ...dataJSON, textNavigate: `/${(getState().user.currentUser as User).Category.toLowerCase()}/modal/change_email_for_contacts_my/not/not`, isSuccessful: true, textNotification: '' }
        } else {
            dispatch(modalSlice.actions.dataFetchingFinish())
            return { ...dataJSON, textNavigate: '', isSuccessful: false, textNotification: getErrorss(dataJSON) }
        }
    } catch (error) {
        dispatch(modalSlice.actions.dataFetchingFinish())
        console.log(error)
        //  dispatch(authSlice.actions.registerFailed(error))
    }
}

export const changeEmail = (formValues: IRegisterForm) => async (dispatch: AppDispatch, getState: () => RootState) => {
    const jwt = window.localStorage.getItem(ACCESS_KEY)
    const userID = window.localStorage.getItem(USER_ID_KEY)
    const currentUser = getState().user.currentUser
    const userContactInfo = { ...(currentUser as User).ContactInfo, Email: formValues.email }

    try {
        dispatch(modalSlice.actions.dataFetchingStart())
        //  console.log(userContactInfo, 'userContactInfo')
        const sendObject = {
            method: 'PUT',
            collection: 'users',

            query: getQueryCurrentUser(),
            id: Number(userID),
            body: {
                // email: formValues.email,
                newEmail: formValues.email,
                emailVerified: false,
                ContactInfo: userContactInfo
            }
        }
        //  console.log(sendObject, 'sendObject register')
        const response = await fetch(`${urlAPI}`, {
            method: 'POST',
            body: JSON.stringify(sendObject),
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${jwt}`
            }
        })

        const dataJSON = await response.json() as ApiResponse

        // console.log(dataJSON, 'dataJSON<<<<<<<<<<<<<<<<?????CHANGE EMAIL')

        if (dataJSON.success) {
            if (dataJSON.response.error != null) {
                //   dispatch(authSlice.actions.registerFailed(responseRegisterPutStep2JSON.response))
                dispatch(modalSlice.actions.dataFetchingFinish())
                return { ...dataJSON, textNavigate: '', isSuccessful: false, textNotification: getErrorss(dataJSON) }
            } else {
                const response2 = await fetch(`${urlAUTH}/sendVerifyMail`, {
                    method: 'POST',
                    body: JSON.stringify({ email: formValues.email, renew: true }), // , testing: process.env.REACT_APP_IS_DEV === 'true'
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })

                const dataJSONVerify = await response2.json() as ApiResponse
                if (dataJSONVerify.success) {
                    if (dataJSONVerify.response.error != null) {
                        dispatch(modalSlice.actions.dataFetchingFinish())
                        window.localStorage.setItem('changingEmail', 'true')
                        return { ...dataJSON, textNavigate: '', isSuccessful: false, textNotification: getErrorss(dataJSON) }
                    } else {
                        dispatch(modalSlice.actions.dataFetchingFinish())

                        return { ...dataJSON, textNavigate: `/${(getState().user.currentUser as User).Category.toLowerCase()}/settings`, isSuccessful: true, textNotification: 'Your email has been changed, please confirm the new email' }
                    }
                } else {
                    dispatch(modalSlice.actions.dataFetchingFinish())
                    return { ...dataJSON, textNavigate: '', isSuccessful: false, textNotification: getErrorss(dataJSON) }
                }
            }
        } else {
            // dispatch(authSlice.actions.registerFailed(dataJSON))
            dispatch(modalSlice.actions.dataFetchingFinish())
            return { textNavigate: '', isSuccessful: false, textNotification: 'Some error occurred,please try again' }
        }
    } catch (error) {
        console.log(error)
    }
}
