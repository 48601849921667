import React from 'react'
import { type Unit } from '../../../../store/slices/unitsSlice'
import Icon from '../../../../app/icon/Icon'

interface IProps {
    unitsByBSO: Unit[]
}

export default function ScheduleAdminUnitsList({ unitsByBSO }: IProps): JSX.Element {
    const defaultImage = '/assets/media/istockphoto.jpg'
    return (
        <div className="container my-2">
            <div className="row">
                {unitsByBSO && unitsByBSO.length > 0 && unitsByBSO.map((unit, i) => (
                    <div key={`unit-${i}`} className="col-6  mb-4">
                        <div className="position-relative">
                            <div
                                className="w-150px h-75px position-absolute z-index-2 rounded rounded-2"
                                style={{ backgroundColor: 'rgba(0, 0, 0, 0.4)', top: 0 }}
                            >
                                <span
                                    className="d-block ps-2 fw-semi-bold me-2 fs-6 lh-1 text-white "
                                >
                                    {unit?.attributes?.Marketing?.Headline}
                                </span>
                                <div className="text-white ps-4">
                                    <span
                                        className="d-block  fs-7 fw-semi-bold ms-4"
                                    >
                                        AED {unit?.attributes?.Marketing?.Price}
                                    </span>
                                    <span
                                        className="text-end d-block pe-4 fs-7 fw-semi-bold"
                                    >
                                        <Icon name="location_on" filled className="fs-7 text-white" />
                                        {unit?.attributes?.Property?.data?.attributes?.Area}
                                    </span>
                                </div>
                            </div>
                            <img
                                className="w-150px h-75px object-fit-cover rounded rounded-2 opacity-100"
                                src={unit?.attributes?.Images?.[0] || defaultImage}
                                alt="img_unit"
                            />
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}

