
import React, { useCallback } from 'react'
import { useAppDispatch, useAppSelector } from '../../../../hooks/redux'
import { setFilterNavigate } from '../../../../store/slices/scheduleSlice'
import { type RootState } from '../../../../store/store'
import ScheduleNavigateAction from './ScheduleNavigateAction'
import { nanoid } from 'nanoid'
import { REACT_APP_BSO_USER_ID, USER_ID_KEY } from '../../../../urls'
import getUserType from '../../../../functions/shared/user/getUserType'
import { setViewingsListOffsetPosition } from '../../../../store/slices/viewingsSlice'

interface Action {
    title: string
    imgPath: string
    callback: any
    notifies: number
    total: number
}
interface IProps {
    countView: number
    countOffers: number
    countAdvertises: number
    broadcastMessagesCount: number
    viewingsNotifies: number
    offersNotifies: number
    advertisesNotifies: number
    allAttention: number
}

export default function SchedulerNavigateNewVersion({
    countAdvertises,
    countView,
    countOffers,
    broadcastMessagesCount,
    viewingsNotifies,
    offersNotifies,
    advertisesNotifies,
    allAttention
}: IProps): JSX.Element {
    const userID = window.localStorage.getItem(USER_ID_KEY)
    const dispatch = useAppDispatch()
    const filterNavigate = useAppSelector((state: RootState) => state.schedule.filterNavigate)
    const isDev = process.env.REACT_APP_IS_DEV === 'true'

    const navigateToAttentions = useCallback(() => {
        dispatch(setFilterNavigate('attention'));
        dispatch(setViewingsListOffsetPosition(0))
    }, [dispatch])
    const navigateToViewings = useCallback(() => dispatch(setFilterNavigate('viewings')), [dispatch])
    const navigateToOffers = useCallback(() => dispatch(setFilterNavigate('offers')), [dispatch])
    const navigateToAdvertisements = useCallback(() => dispatch(setFilterNavigate('adverts')), [dispatch])
    const navigateToBroadcasts = useCallback(() => dispatch(setFilterNavigate('broadcasts')), [dispatch])
    const navigateToReferrals = useCallback(() => dispatch(setFilterNavigate('referrals')), [dispatch])

    const navigations: Action[] = [
        {
            title: 'NOTIFICATIONS',
            imgPath: '/assets/media/schedule/navigation/Notification.png',
            callback: navigateToAttentions,
            notifies: allAttention,
            total: 0
        },
        {
            title: 'VIEWINGS',
            imgPath: '/assets/media/schedule/navigation/55.png',
            callback: navigateToViewings,
            notifies: viewingsNotifies,
            total: countView
        },
        {
            title: 'OFFERS',
            imgPath: '/assets/media/schedule/navigation/56.png',
            callback: navigateToOffers,
            notifies: offersNotifies,
            total: countOffers
        },
        ...(
            getUserType() !== 'Landlord'
                ? [{
                    title: 'ADVERTS',
                    imgPath: '/assets/media/schedule/navigation/58.png',
                    callback: navigateToAdvertisements,
                    notifies: advertisesNotifies,
                    total: countAdvertises
                }]
                : []
        ),
        ...(
            isDev
                ? Number(userID) === Number(REACT_APP_BSO_USER_ID) || getUserType() === 'Landlord'
                    ? []
                    : [{
                        title: 'BROADCASTS',
                        imgPath: '/assets/media/schedule/navigation/57.png',
                        callback: navigateToBroadcasts,
                        notifies: 0,
                        total: broadcastMessagesCount
                    },
                        // {
                        //     title: 'REFERRALS',
                        //     imgPath: '/assets/media/schedule/navigation/59.png',
                        //     callback: navigateToReferrals,
                        //     notifies: 1,
                        //     total: 4
                        // }
                    ]
                : []

        ),
        ...(
            getUserType() === 'Landlord'
                ? [
                    // {
                    //     title: 'SERVICES',
                    //     imgPath: '/assets/media/schedule/navigation/55.png',
                    //     callback: navigateToServices,
                    //     notifies: 0,
                    //     total: servicesCount
                    // }
                ]
                : []
        )
    ]
    const userDevice = useAppSelector((state) => state.app.userDevice)
    return (
        <>
            <div className="d-flex flex-row gap-4 w-100 mw-450px scrollbar-width overflow-x-auto px-4 mb-4 mt-2 pt-3">
                {userDevice === 'IPhone'
                    ? <div className="h-25px"></div>
                    : <></>
                }



                <div className={'w-100px h-100px position-relative'} onClick={navigations[0].callback} >

                    <div className={`w-100px h-100px d-flex flex-column align-items-center position-relative overflow-hidden rounded-4 ${filterNavigate === "attention" ? 'bg-bso-semi-back' : 'bg-light-secondary'}`}>

                        <div className="position-relative w-100 h-100 ">
                            <span className="d-block fs-7 min-w-25px max-w-25px text-center position-absolute rounded-0 rounded-bottom-4   rounded-end-0 translate-middle badge badge-danger opacity-75" style={{ top: '9px', right: '-13px' }}>
                                <span className="text-white fs-7">{navigations[0].notifies ?? 0} </span>
                            </span>

                            <img className="z-index-0" src={navigations[0].imgPath} alt="" style={{ height: 'calc(100% - 10px)', width: '100%' }} />
                        </div>
                        <div className={'d-flex align-items-center justify-content-center w-100 px-auto py-4  pt-3 position-absolute bottom-0 z-index-2 overlay '}>
                            <span className={`fs-8 px-auto text-dark  ${filterNavigate === "attention" ? 'fw-bold ' : 'fw-semibold'}  lh-1 text-center`}>{navigations[0].title}</span>
                        </div>
                    </div>
                </div>



                {navigations.slice(1).map((el: Action) => (
                    <ScheduleNavigateAction
                        key={nanoid()}
                        title={el.title}
                        imgPath={el.imgPath}
                        callback={el.callback}
                        notifies={el.notifies}
                        total={el.total}
                    />
                ))}
            </div>
        </>
    )
}
