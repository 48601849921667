import React, { useState, type Dispatch, type SetStateAction } from 'react'
import { Link } from 'react-router-dom'
import { useAppSelector } from '../../../../../hooks/redux'
import { type RootState } from '../../../../../store/store'

import { Modal } from 'antd'
import { type User } from '../../../../../store/slices/userSlice'
import { ava_blank } from '../../../../../urls'

interface IProps {
    previewOpen?: boolean
    setPreviewOpen: Dispatch<SetStateAction<boolean>>
    currentUser: User

}
// "other"
export default function MyProfileHead({
    previewOpen,
    setPreviewOpen,
    currentUser
}: IProps): JSX.Element {
    const userCONTACTS = (currentUser)?.Type?.[0]
    const userContactsINFO = (currentUser)?.ContactInfo

    const [imageUrl, setImageUrl] = useState<string>(
        userCONTACTS?.Photo ?? ava_blank
    )
    const licenseApproved = useAppSelector(
        (state: RootState) => state.agentStates.statusesApproved.licenseApproved
    )
    return (
        <>
            <div className="d-flex flex-sm-nowrap">
                <div className="me-7 mb-4">
                    <div
                        onClick={() => {
                            setPreviewOpen(true)
                        }}
                        className="symbol symbol-100px symbol-lg-120px symbol-fixed position-relative"
                    >
                        <img
                            className="object-fit-cover border rounded"
                            src={imageUrl}
                            alt="image"
                        />
                        {/* <div className="position-absolute translate-middle bottom-0 start-100 mb-6 bg-success rounded-circle border border-4 border-body h-20px w-20px"></div> */}
                    </div>
                    <Modal
                        open={previewOpen}
                        footer={null}
                        onCancel={() => {
                            setPreviewOpen(false)
                        }}
                        centered
                        styles={{
                            body: {
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                height: '50vh'
                            }
                        }}
                    >
                        <img
                            alt="example"
                            style={{ maxWidth: '100%', maxHeight: '100%' }}
                            src={imageUrl}
                        />
                    </Modal>
                </div>
                <div className="flex-grow-1">
                    <div className="d-flex justify-content-between align-items-start flex-wrap mb-2">
                        <div className="d-flex flex-column">
                            <div className="d-flex align-items-center mb-2">
                                {/* text-dots-app max-w-100px */}
                                <span className="text-gray-900 text-hover-primary fs-2 fw-bold me-1 ">
                                    {`${userContactsINFO?.FirstName
                                        ? userContactsINFO?.FirstName
                                        : userCONTACTS?.Name
                                        } ${userContactsINFO?.FamilyName
                                            ? userContactsINFO?.FamilyName
                                            : ''
                                        }`}
                                </span>
                                <span>
                                    {licenseApproved
                                        ? <i className="ki-duotone ki-verify fs-1 text-warning">
                                            <span className="path1"></span>
                                            <span className="path2"></span>
                                        </i>
                                        : <i className="ki-duotone ki-verify fs-1 text-primary">
                                            <span className="path1"></span>
                                            <span className="path2"></span>
                                        </i>}

                                </span>
                            </div>
                            {/* <div className={'text-gray-400 ms-4'}>Rating 5</div> */}
                            <div className="d-flex flex-wrap fw-semibold fs-6 pe-2">
                                <a
                                    href="#"
                                    className="d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2"
                                >
                                    <i className="bi bi-bar-chart-fill fs-4 me-1"></i>
                                    Rating 4.8
                                </a>
                            </div>

                            <div className="d-flex flex-wrap fw-semibold fs-6 pe-2">
                                <span className="d-flex align-items-center text-gray-400 text-hover-primary mb-2">
                                    <i className="ki-duotone ki-sms fs-4 me-1">
                                        <span className="path1"></span>
                                        <span className="path2"></span>
                                    </i>
                                    {(currentUser)?.email}
                                </span>
                                <br />
                            </div>
                            <div className={'mb-4 fw-semibold'}>
                                <a
                                    href="#"
                                    className="d-flex align-items-center text-gray-400 text-hover-primary mb-2"
                                >
                                    <i className="bi bi-telephone fs-5 me-1"></i>
                                    {(currentUser)?.phone
                                        ? (currentUser)?.phone
                                        : ' Not phone'}
                                </a>
                            </div>

                            {/* <div className="d-flex">
                                        <i className="ki-duotone ki-messages fs-2x ms-2">
                                            <i className="path1"></i>
                                            <i className="path2"></i>
                                            <i className="path3"></i>
                                            <i className="path4"></i>
                                            <i className="path5"></i>
                                        </i>
                                        <i className="ki-duotone ki-bookmark fs-2x ms-2">
                                            <i className="path1"></i>
                                            <i className="path2"></i>
                                        </i>

                                        <i className="ki-duotone ki-cross-circle  fs-2x ms-2">
                                            <i className="path1"></i>
                                            <i className="path2"></i>
                                        </i>
                                    </div> */}

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
