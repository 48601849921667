import React, { useEffect } from 'react'

import AgentProfileSecurityOld from './AgentProfileSecurityOld'
import { useAppDispatch, useAppSelector } from '../../../hooks/redux'
import AgentFooter from '../AgentFooter'
import { type RootState } from '../../../store/store'
import { mainButtonClosing } from '../../../store/slices/modalSlice'
import AgentProfileSecurity from './AgentProfileSecurity'
import AgentBilling from './AgentBilling'
import AgentProfileSettings from './AgentProfileSettings'
import AgentNotifies from './AgentNotifies'
import AgentHeader from '../AgentHeader'
import OrdersSubscriptions from './ordersSubscriptions/OrdersSubscriptions'
import CreditsBSO from './creditsBSO/CreditsBSO'
import { type User, type IOrder, type ISettings } from '../../../store/slices/userSlice'
import { getLatestServiceAgentSubscription } from '../../../functions/agent/subscriptions/getLatestServiceAgentSubscription'
import { BSO_DEVELOP_MODE_ON_FRONT } from '../../../urls'

export default function AgentSettings(): JSX.Element {
    const { mainButtonOpen, buttonOrdersHistoryOpen, buttonSubscriptionsOpen } = useAppSelector((state: RootState) => state.modal)

    const currentUser = useAppSelector(
        (state: RootState) => state.user.currentUser as User
    )

    const documentsLoaded = useAppSelector((state: RootState) => state.agentStates.documentsLoaded)
    const loadSts = documentsLoaded.emiratesLoaded || documentsLoaded.passportLoaded

    const userID = currentUser.id

    const dispatch = useAppDispatch()
    const settings = currentUser?.Settings as ISettings[]

    const notifies = settings?.[0]?.Notifies ?? null
    const orders = currentUser.Orders as IOrder[]

    const activeSub = getLatestServiceAgentSubscription(orders)?.Service?.[0]?.Package ?? ''
    const latestServiceAgentSubscription = getLatestServiceAgentSubscription(orders)
    useEffect(() => {
        dispatch(mainButtonClosing())
    }, [])
    const isDev = process.env.REACT_APP_IS_DEV === 'true' || window.localStorage.getItem(BSO_DEVELOP_MODE_ON_FRONT) === 'true'

    return (
        <>
            <div
                className="page d-flex flex-column flex-row-fluid fs-5"
            >
                <AgentHeader active="Settings" />
                <div
                    className="content d-flex flex-column flex-fill p-0 m-4"
                >
                    <div className="w-100 mw-450px mx-auto">
                        <AgentProfileSecurity />
                        <AgentProfileSettings userID={userID} loadSts={loadSts} />
                        {loadSts &&
                            <OrdersSubscriptions orders={orders} latestServiceAgentSubscription={latestServiceAgentSubscription} />}

                        {/* {isDev
                            ? <>
                                <AgentBilling />
                                <CreditsBSO currentUser={currentUser} /> </>
                            : null} */}
                        <AgentNotifies notifies={notifies} />
                        <AgentProfileSecurityOld />
                    </div>
                </div>
                <div className="m-0" style={{ height: `${mainButtonOpen ? '0' : '115px'}` }} />
            </div>
            {mainButtonOpen || buttonOrdersHistoryOpen || buttonSubscriptionsOpen
                ? null
                : <>
                    <AgentFooter />
                </>}

        </>
    )
}
