import React, { type CSSProperties, useEffect, useState } from 'react'
import { useAppSelector } from '../../../hooks/redux'
import Icon from '../../../app/icon/Icon'

interface IProps {
    onCancel: () => void
    cls?: string | undefined
    rightPosition?: string | undefined
    style?: CSSProperties
}

function CloseIconBasic({ onCancel, cls, rightPosition = '25', style }: IProps): JSX.Element {
    const [bottomOffset, setBottomOffset] = useState(0)
    const userDevice = useAppSelector((state) => state.app.userDevice)

    useEffect(() => {
        const updateBottomOffset = () => {
            const windowHeight = window.innerHeight
            setBottomOffset(windowHeight * 0.05)
        }

        window.addEventListener('resize', updateBottomOffset)
        updateBottomOffset()

        return () => {
            window.removeEventListener('resize', updateBottomOffset)
        }
    }, [])

    const topPosition = cls || `${bottomOffset}px`
    return (
        <div
            className={`position-absolute ${cls && userDevice === 'IPhone' ? 'mt-8' : ''}`}
            // style={{ top: topPosition, right: `${rightPosition}px`, zIndex: 9999 }}
            style={{
                top: topPosition,
                right: `${rightPosition}px`,
                zIndex: 9999,
                ...style
            }}
            onClick={onCancel}
        >
            {/* <i className="fs-6x ki-duotone ki-cross-square " > */}
            {/*    <span className="path1 text-dark" style={{ filter: 'brightness(0)' }}></span> */}
            {/*    <span className="path2 text-white" style={{ filter: 'brightness(1)' }}></span> */}
            {/* </i> */}
            <div className={'p-1'} style={{ borderRadius: '1rem', background: '#00000066' }}>
                <Icon name={'close'} imageSize={24} className={'fs-4x text-white fs-lg-3x'} styles={{ fill: 'white' }} />
            </div>
        </div>
    )
}

export default CloseIconBasic
