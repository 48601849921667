import React from 'react'
import MenuItem from './MenuItem'
import AgentHeader from '../AgentHeader'
import { BSO_DEVELOP_MODE_ON_FRONT } from '../../../urls'
const isDevFront = window.localStorage.getItem(BSO_DEVELOP_MODE_ON_FRONT)
function BusinessMenuSection(): JSX.Element {
    return (
        <>
            <div
                className="page d-flex flex-column flex-row-fluid fs-5"
                id='header_business_section'
            >

                <AgentHeader
                    active="Business"

                />

                <div className="content d-flex flex-column flex-fill p-0 m-4">

                    <div className="w-100 mw-450px mx-auto">
                        <div className="card shadow-sm mb-4 ">
                            <div className="card-body p-4">
                                <MenuItem
                                    label={'My Units'}
                                    route={'/agent/my_units'}
                                    description={'Add new unit, set up your units catalogue'}
                                    bgColor={'danger'}
                                    icon={
                                        <i className="bi bi-building-gear fs-3x fs-lg-2x mx-auto text-white"></i>

                                    }
                                />
                                {isDevFront === 'true'
                                    ? <>
                                        <MenuItem
                                            description={'Manage all of your clients'}
                                            label={'My Clients'}
                                            route={'/agent/myClients'}
                                            bgColor={'success'}
                                            icon={
                                                <i className="bi bi-people fs-3x fs-lg-2x mx-auto text-white"></i>

                                            }
                                        />
                                        <MenuItem
                                            description={'Refer and earn'}
                                            label={'My Referrals'}
                                            route={'/agent/referrals'}
                                            bgColor={'info'}
                                            icon={
                                                <i className="bi bi-person-check fs-3x fs-lg-2x mx-auto text-white"></i>

                                            }
                                        /> </>
                                    : null}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default BusinessMenuSection
