import { type IOffer } from "../../../store/slices/offersSlice";
import { type Unit } from "../../../store/slices/unitsSlice";
import { type User } from "../../../store/slices/userSlice";
import { type IViewing } from "../../../store/slices/viewingsSlice";
import { store } from "../../../store/store";
import { USER_ID_KEY } from "../../../urls";

export const getBelongsUnitForViewing = (currentViewing: IViewing) => {
    const userID = window.localStorage.getItem(USER_ID_KEY);
    const storeUnits = store.getState().units.units;
    const isUnitsBSOIds = storeUnits
        .filter((__unit) => __unit.attributes.ManagedBy === "BSO")
        .map((_unit) => _unit.id);

    const task = {
        type: "TYPE UNIT",
        color: "secondary",
    };
    if (
        currentViewing.attributes?.Unit?.data?.attributes?.User?.data?.id ===
        Number(userID)
    ) {
        task.type = "MY UNIT";
        task.color = "primary";
    }
    if (
        currentViewing?.attributes?.User?.data?.id === Number(userID) &&
        isUnitsBSOIds.includes(
            currentViewing?.attributes?.Unit?.data?.id as number
        )
    ) {
        task.type = "BSO UNIT";
        task.color = "warning";
    }
    if (
        currentViewing?.attributes?.User?.data?.id === Number(userID) &&
        currentViewing?.attributes?.AgentToAgentID &&
        !isUnitsBSOIds.includes(
            currentViewing?.attributes?.Unit?.data?.id as number
        )
    ) {
        task.type = "OTHER AGENT UNIT";
        task.color = "info";
    }

    return task;
};

export const getBelongsUnitForOffer = (currentOffer: IOffer) => {
    const userID = window.localStorage.getItem(USER_ID_KEY);
    const storeUnits = store.getState().units.units;
    const isUnitsBSOIds = storeUnits
        .filter((__unit) => __unit.attributes.ManagedBy === "BSO")
        .map((_unit) => _unit.id);
    const currentUser = store.getState().user.currentUser;
    const agentUnitsIds =
        (currentUser as User)?.Units != null &&
        Array.isArray(currentUser?.Units) &&
        currentUser?.Units?.length > 0
            ? currentUser?.Units.map((_unit: Unit) => _unit.id)
            : [];

    const task = {
        type: "TYPE UNIT",
        color: "secondary",
        redirect: "catalog",
    };

    if (
        currentOffer?.attributes?.Unit?.data?.attributes?.User?.data?.id ===
            Number(userID) &&
        Number(userID) !== Number(process.env.REACT_APP_BSO_USER_ID)
    ) {
        task.type = "MY UNIT";
        task.color = "primary";
        task.redirect = "my_unit";
    }

    if (Number(userID) === Number(process.env.REACT_APP_BSO_USER_ID)) {
        if (
            isUnitsBSOIds.includes(
                currentOffer?.attributes?.Unit?.data?.id as number
            ) ||
            Number(userID) === Number(process.env.REACT_APP_BSO_USER_ID)
        ) {
            task.type = "BSO UNIT";
            task.color = "warning";
            task.redirect = "catalog";
        }
    } else {
        if (
            currentOffer?.attributes?.User?.data?.id === Number(userID) &&
            (isUnitsBSOIds.includes(
                currentOffer?.attributes?.Unit?.data?.id as number
            ) ||
                Number(userID) === Number(process.env.REACT_APP_BSO_USER_ID))
        ) {
            task.type = "BSO UNIT";
            task.color = "warning";
            task.redirect = "catalog";
        }
    }

    if (
        currentOffer?.attributes?.User?.data?.id === Number(userID) &&
        !agentUnitsIds.includes(
            currentOffer?.attributes?.Unit?.data?.id as number
        ) &&
        !isUnitsBSOIds.includes(
            currentOffer?.attributes?.Unit?.data?.id as number
        ) &&
        Number(userID) !== Number(process.env.REACT_APP_BSO_USER_ID)
    ) {
       
        task.type = "OTHER AGENT UNIT";
        task.color = "info";
        task.redirect = "other_agent_s_unit";
    }
  
    // if (currentOffer?.attributes?.AgentToLandlordID != null && currentOffer?.attributes?.Unit?.data?.attributes?.User?.data?.id !== Number(userID)) {
    //     task.type = 'LANDLORD UNIT'
    //     task.color = 'info'
    //     task.redirect = 'catalog'
    // }
    return task
}
