import { useEffect } from "react";

export const useDynamicDeviceScaling = () => {
    useEffect(() => {
        const applyDynamicScaling = () => {
            const { screen, devicePixelRatio, innerWidth, innerHeight } = window;


            // physicalWidth: window.screen.width * devicePixelRatio,
            // physicalHeight: window.screen.height * devicePixelRatio,


            // параметры устройства
            const screenWidth = screen.width;
            const screenHeight = screen.height;
            const dpr = devicePixelRatio;          


            console.log({screenWidth , screenHeight , innerWidth, innerHeight, devicePixelRatio})
  
    

            // scale
       
            const aspectRatio = innerHeight / innerWidth;
            const baseWidth = 375; 
            const baseHeight = Math.floor(375 * aspectRatio); 
            const scale = (innerWidth / baseWidth);

         
            // центр 
            const body = document.body;
           
            const offsetLeft = (innerWidth - baseWidth * scale) / 2;
            const offsetTop = (innerHeight - baseHeight * scale) / 2;

            
            body.style.position = "absolute";
            body.style.width = `${baseWidth}px`;
            body.style.height =  `${baseHeight}px`;
            body.style.transform = `scale(${scale})`;
            body.style.transformOrigin = "top left";
            body.style.left = `${offsetLeft}px`;
            body.style.top = `${offsetTop}px`;
            document.body.style.maxHeight  = `${baseHeight}px`;
            // const swalElement = document.querySelector('body.swal2-height-auto') as HTMLElement;
            // if (swalElement) {
            //     swalElement.style.maxHeight  = `${baseHeight}px`;
            //     swalElement.style.height  = `${100}%`;
            // }
        };

  
        applyDynamicScaling();
        window.addEventListener("resize", applyDynamicScaling);

        return () => {
            window.removeEventListener("resize", applyDynamicScaling);
        };
    }, []);
};
