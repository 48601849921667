import React, { useMemo, useState } from 'react'
import MyCarousel from '../../../app/MyCarousel'
import { nanoid } from 'nanoid'
import { type Unit } from '../../../store/slices/unitsSlice'
import { removeDataAttributes } from '../../../functions/adapters/universal/removeDataAttributes'
import _ from 'lodash'
import { type CarouselRef } from 'antd/es/carousel'

interface UnitCarouselCardProps {
    unit: Unit
}
const defaultImage = '/assets/media/placeholders/image_unavailable.png'

function useUnitCarouselCard({ unit }: UnitCarouselCardProps): [images: string[], afterChange: (ref: React.Ref<CarouselRef>) => (current: number) => void] {
    const unitCopy = useMemo(() => removeDataAttributes(_.cloneDeep(unit)), [unit])
    const [images, setImages] = useState<string[]>((unitCopy?.Images ?? []).slice(0, 2))
    const [isTeleporting, setIsTeleporting] = useState<boolean>(false)
    function afterChange(ref: React.Ref<CarouselRef>) {
        return function (current: number) {
            if (!isTeleporting) {
                setIsTeleporting(true)
                if (unitCopy?.Images?.[current + 1] !== undefined && images[current + 1] === undefined) {
                    setImages((prev) => [...prev, unitCopy?.Images?.[current + 1]])
                }
                if ((ref as React.RefObject<CarouselRef>)?.current != null) {
                    (ref as React.RefObject<CarouselRef>).current?.goTo(current, true)
                }
                setTimeout(() => {
                    setIsTeleporting(false)
                })
            }
        }
    }
    return [
        images,
        afterChange
    ]
}

export default function UnitCarouselCard({ unit }: UnitCarouselCardProps): JSX.Element {
    const [images, afterChange] = useUnitCarouselCard({ unit })
    return (
        <>
            {
                images?.length > 0
                    ? (
                        <div key={`agent_unit_key${unit.id}`}>
                            <MyCarousel showTwoColumns={false} afterChange={afterChange}>
                                {images.map((el: string) => (
                                    <div key={`${el}`} className="text-center">
                                        <div
                                            className="card-rounded w-100  unit-image-height mw-450px mx-auto border border-gray-500 overflow-hidden bgi-no-repeat bgi-size-cover bgi-position-x-center bgi-position-y-center"
                                            style={{ backgroundImage: `url(${el})` }}
                                        >
                                            <div className="unit-image-height w-100 mw-450px mx-auto"></div>
                                        </div>
                                    </div>
                                ))}
                            </MyCarousel>
                        </div>
                    )
                    : (
                        <div key={nanoid()} className="text-center">
                            <div
                                className="card-rounded w-100 unit-image-height mw-450px mx-auto border border-gray-500 overflow-hidden bgi-no-repeat bgi-size-cover bgi-position-x-center bgi-position-y-center"
                                style={{ backgroundImage: `url(${defaultImage})` }}
                            >
                                <div className="unit-image-height w-100 mw-450px mx-auto "></div>
                            </div>
                        </div>

                    )
            }
        </>
    )
}
