import React, {type ReactElement, useState} from "react";
import Icon from "../../../../../app/icon/Icon";
import styles from "./TextInput.module.sass";

type HTMLInputTypeAttribute = "button" | "checkbox" | "color" | "date" | "datetime-local" | "email" | "file" | "hidden" | "image" | "month" | "number" | "password" | "radio" | "range" | "reset" | "search" | "submit" | "tel" | "text" | "time" | "url" | "week";


interface TextInputProps {
    style: 'white' | 'gray'
    value: string
    onChange: (value: string) => void
    name?: string
    type?: HTMLInputTypeAttribute
    placeholder?: string
    error?: string
    hideError?: boolean
    disabled?: boolean
    autoComplete?: string
    unit?: string
    max?: number
    min?: number
}

export default function TextInput ({value, onChange, name, type, placeholder, error, disabled = false, autoComplete, hideError = false, style, unit, max, min }: TextInputProps): ReactElement {
    const [passwordShown, setPasswordShown] = useState(false)
    return (
        <div className={styles.textInput}>
            <div
                className={`${style === 'white' ? styles.textInput__container_white : styles.textInput__container_gray} ${styles.textInput__container} ${error == null ? '' : styles.textInput__container_error}`}
            >
                <input
                    type={
                        type === 'password' && passwordShown ? 'text' : type
                    }
                    value={value}
                    onInput={(e: React.FormEvent<HTMLInputElement>) => {
                        onChange(e.currentTarget.value)
                    }}
                    name={name}
                    placeholder={placeholder}
                    className={styles.textInput__input}
                    disabled={disabled}
                    autoComplete={autoComplete as any}
                />
                { type === 'password'
                    ? <div
                        onClick={() => {
                            setPasswordShown(!passwordShown)
                        }}
                        className={styles.textInput__passwordToggle}
                    >
                        <Icon name={passwordShown ? 'visibility' : 'visibility_off'}/>
                    </div>
                    : type === 'number'
                        ? <div className={'d-flex flex-column border border-gray-300 border-top-0 border-right-0 border-bottom-0'}
                            style={{
                                width: '51px'
                            }}
                        >
                            <div className={'flex-grow-1 d-flex align-items-center justify-content-center border border-gray-300 border-top-0 border-left-0 border-right-0'}
                                onClick={() => {
                                    if (Number.isFinite(Number(value)) && (max == null || Number(value) < max)) {
                                        onChange((Number(value) + 1).toString(10))
                                    }
                                }}
                            >
                                <Icon name={'keyboard_arrow_up'} className={'fs-2x'}/>
                            </div>
                            <div className={'flex-grow-1 d-flex align-items-center justify-content-center'}
                                onClick={() => {
                                    if (Number.isFinite(Number(value)) && (min == null || Number(value) > min)) {
                                        onChange((Number(value) - 1).toString(10))
                                    }
                                }}
                            >
                                <Icon name={'keyboard_arrow_down'} className={'fs-2x'}/>
                            </div>
                        </div>
                        : unit != null
                            ? <div className={'d-flex align-items-center justify-content-center border border-gray-300 border-top-0 border-right-0 border-bottom-0 px-1 fs-6 fw-medium'}
                                style={{
                                    minWidth: '45px'
                                }}
                            >
                                {
                                    unit
                                }
                            </div>
                            : <></>
                }
            </div>
            { error != null && !hideError
                ? <div className={styles.textInput__error}>
                    <div>
                        {error}
                    </div>
                    <Icon name={'error'} className={styles.textInput__error__icon} imageSize={48}/>
                </div>
                : <>
                </>
            }
        </div>
    )
}