import { useAppDispatch, useAppSelector } from '../redux'
import { type RootState } from '../../store/store'
import { useEffect, useState } from 'react'
import dayjs from 'dayjs'
import _ from 'lodash'
import moment from 'moment'
import { useNavigate } from 'react-router'
import useUserType from '../UseUserType'
import { setImages } from '../../store/slices/modalSlice'
import checkIfUnitHasOngoingTransactions from "../../functions/shared/units/checkIfUnitHasOngoingTransactions";

/**
 * Use for unit marketing update modal only
 * TODO move to a plain function, the functionality doesn't require a hook
 * @param modal1
 * @param unitID
 * @param isFirstTime
 */
const useEditUnit = (modal1: any, unitID: number, isFirstTime: boolean = false): { modal: any, initialState: Record<string, any> } => {
    const { units } = useAppSelector((state: RootState) => state.units)
    const dispatch = useAppDispatch()
    const currentUnit = units?.filter((el: any) => el.id === Number(unitID))[0]
    const [filledModal, setFilledModal] = useState(modal1)
    const [initialState, setInitialState] = useState<Record<string, any>>({})
    /* const navigate = useNavigate()
    const { userType } = useUserType() */

    useEffect(() => {
        const modal = _.cloneDeep(modal1)
        const state: Record<string, any> = {}
        _.forEach(modal[0]?.form, (row) => {
            let path: string[]
            switch (row.id) {
                case 'Statuses':
                    if ((currentUnit as any)?.attributes?.Statuses?.includes('Mortgage') === true) {
                        row.default = 'Available'
                    } else {
                        row.default = 'Unavailable'
                    }
                    break
                case 'CurrentRent':
                    if ((currentUnit as any)?.attributes?.Marketing?.Use === 'For rent') {
                        row.defaultHidden = true
                    } else {
                        row.default = _.get(currentUnit?.attributes ?? {}, row.id)
                    }
                    break
                case 'LegalNoticeServed':
                    if ((currentUnit as any)?.attributes?.LegalNoticeServed === true) {
                        row.default = 'Yes'
                    } else {
                        row.default = 'No'
                    }
                    if ((currentUnit as any)?.attributes?.Marketing?.Use === 'For rent') {
                        row.defaultHidden = true
                    }
                    break
                case 'sold-rented':
                    if ((currentUnit as any)?.attributes?.Statuses?.includes('Sold') === true) {
                        row.default = 'Rented/Sold'
                    } else if ((currentUnit as any)?.attributes?.Statuses?.includes('Drafted') === true) {
                        row.default = 'Drafted'
                    } else {
                        if (checkIfUnitHasOngoingTransactions(currentUnit?.id)) {
                            row.selectors = row.selectors?.filter((selector: string) => selector !== 'Drafted')
                        }
                        row.default = 'In-process'
                    }

                    if (isFirstTime) {
                        row.default = 'Drafted'
                    }
                    break
                case 'Marketing.MarketingFormTemplate':
                    if ((currentUnit as any)?.attributes?.Marketing?.MarketingFormTemplate != null) {
                        row.uploadedBase64 = (currentUnit as any)?.attributes?.Marketing?.MarketingFormTemplate
                    }
                    break
                case 'Video':
                    if ((currentUnit as any)?.attributes?.Video != null) {
                        row.uploadedBase64 = (currentUnit as any)?.attributes?.Video
                    }
                    break
                case 'FloorPlan':
                    if ((currentUnit as any)?.attributes?.FloorPlan != null) {
                        row.uploadedBase64 = (currentUnit as any)?.attributes?.FloorPlan
                    }
                    break
                case 'Marketing.Status':
                    console.log((currentUnit as any)?.attributes?.Marketing?.Status)
                    if ((currentUnit as any)?.attributes?.Marketing?.Status === 'Enabled') {
                        row.default = 'Enabled'
                    } else {
                        row.default = 'Disabled'
                    }
                    row.onChange = (e: any) => {
                        const fieldsToToggle: NodeListOf<HTMLElement> = document.querySelectorAll('#Marketing\\.DiscountHeadline,#Marketing\\.DiscountStartDatetime,#Marketing\\.DiscountEndDatetime,#Marketing\\.DiscountPrice')
                        if (e === 'Enabled') {
                            fieldsToToggle.forEach((field) => {
                                try {
                                    field.style.visibility = 'visible'
                                    field.style.position = 'static'
                                } catch (e) {
                                    console.error('Error while revealing marketing discount field: ', 'field', e)
                                }
                            })
                        } else {
                            fieldsToToggle.forEach((field) => {
                                try {
                                    field.style.visibility = 'hidden'
                                    field.style.position = 'absolute'
                                } catch (e) {
                                    console.error('Error while hiding marketing discount field: ', 'field', e)
                                }
                            })
                        }
                    }
                    break
                case 'Marketing.DiscountHeadline':
                case 'Marketing.DiscountStartDatetime':
                case 'Marketing.DiscountEndDatetime':
                case 'Marketing.DiscountPrice':
                    if ((currentUnit as any)?.attributes?.Marketing?.Status !== 'Enabled') {
                        row.defaultHidden = true
                    }
                    path = _.split(row.id, '.')
                    row.default = _.reduce(
                        path,
                        (object, key) => object?.[key],
                        ((currentUnit as any)?.attributes ?? {}))
                    break

                    // TODO CHECK ARSENII ATTENTION
                case 'Marketing.Use':
                    if (checkIfUnitHasOngoingTransactions(currentUnit?.id)) {
                        row.disabled = true
                    }
                    row.default = _.get((currentUnit as any)?.attributes, row.id)
                    row.onChange = (type: string) => {
                        const fieldsToToggle: NodeListOf<HTMLElement> = document.querySelectorAll('#CurrentRent, #LegalNoticeServed')
                        if (type === 'For rent') {
                            fieldsToToggle.forEach((field) => {
                                try {
                                    field.style.visibility = 'hidden'
                                    field.style.position = 'absolute'
                                } catch (e) {
                                    console.error('Error while hiding marketing discount field: ', 'field', e)
                                }
                            })
                        } else {
                            fieldsToToggle.forEach((field) => {
                                try {
                                    field.style.visibility = 'visible'
                                    field.style.position = 'static'
                                } catch (e) {
                                    console.error('Error while revealing marketing discount field: ', 'field', e)
                                }
                            })
                        }
                    }
                    break
                /* case 'Property':
                    row.default = (currentUnit as any)?.attributes?.Property?.data?.id
                    break
                case 'Statuses[0]':
                case 'Statuses[2]':
                    _.forEach((currentUnit as any)?.attributes?.Statuses ?? [], (status: string) => {
                        if (_.includes(row.selectors, status)) {
                            row.default = status
                        }
                    })
                    break
                case 'Statuses[1]':
                    row.default = _.includes((currentUnit as any)?.attributes?.Statuses ?? [], row.selectors[0].value)
                        ? row.selectors[0].value
                        : row.selectors[1].value
                    break */
                default:
                    path = _.split(row.id, '.')
                    row.default = _.reduce(
                        path,
                        (object, key) => object?.[key],
                        ((currentUnit as any)?.attributes ?? {}))
            }
            if (row.id === 'Marketing.DiscountStartDatetime' || row.id === 'Marketing.DiscountEndDatetime' || row.id === 'DateServed') {
                if (row.default != null) {
                    row.default = dayjs(row.default)
                }
            }
            if (row.element === 'label-secondary') {
                row.content[1] = row.default
                if (row.id === 'Marketing.DiscountStartDatetime' || row.id === 'Marketing.DiscountEndDatetime') {
                    row.content[1] = moment(row.default).format('LL')
                }
            }
            if (row.id === 'edit_marketing_button') {
                row.modal = 'edit_unit_marketing_modal'
            }
            if (row.id === 'Images') {
                dispatch(setImages(_.map((currentUnit as any)?.attributes?.Images, (image) => ({ data: image }))))
            }
            state[row.id] = row.default
        })
        setFilledModal(modal)
        setInitialState(state)
    }, [currentUnit])

    return ({
        modal: filledModal,
        initialState
    })
}

export default useEditUnit
