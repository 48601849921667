import React, { useState } from 'react'

import { useNavigate } from 'react-router-dom'
import { useParams } from 'react-router'
import _ from 'lodash'
import { useAppDispatch, useAppSelector } from '../../../hooks/redux'
import { type RootState } from '../../../store/store'
import ActionCard from '../../landlordModule/adminActions/actionCard'
import { animationOptions, swalAttentionLeave, swalOptions } from '../../../functions/swalOptions'
import Swal from 'sweetalert2'

import { updateStatusOfferAgent } from '../../../store/actionsCreators/offerActions/agentToAgentOfferCommunication/updateStatusOfferAgent'
import UseUserType from '../../../hooks/UseUserType'
import { openChatByUserID } from '../../../store/actionsCreators/chatActions'
import MainLoader from '../../../app/custom/loaders/MainLoader'
import { getCurrentDubaiDate } from '../../../functions/agent/date/getCurrentDateStrFormat'



import { getNameUser } from '../../../functions/agent/agentModuleComponent/getNameUser'
import { getUnitAddress } from '../../../functions/agent/agentModuleComponent/getUnitAddres'
import { type User } from '../../../store/slices/userSlice'
import { type Unit } from '../../../store/slices/unitsSlice'
import { toUTCWithOffset } from '../../../functions/agent/date/toUTCWithOffset'
import { sendNotification } from '../../../functions/shared/notifications/from-agent/sendNotification'
import { setUnitSold } from '../../../store/actionsCreators/unitsActions'

export default function OfferActionsForOtherAgents(): JSX.Element {
    const { unitID, objectID: offerID } = useParams()
    const { userType } = UseUserType()
    const offers = useAppSelector((state: RootState) => state.offers.offers)
    const offer = offers.filter((off: any) => off.id === Number(offerID))[0]
    const typeOffer = offer?.attributes?.Type?.[0]?.__component?.split('.')[1] ?? ''

    const currentUser = useAppSelector((state: RootState) => state.user.currentUser)
    const units = useAppSelector((state: RootState) => state.units.units)
    const unit = _.find(units, (unit) => unit.id === Number(unitID))
    const ownerUserId = (unit as Unit).attributes?.User.data?.id
    const [localLoading, setLocalLoading] = useState(true)
    const userToConnect = offer?.attributes?.User?.data?.id
    const offerStatuses = offer?.attributes?.Statuses
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const iconText = 'Success'

    const optionsSuccess = {
        ...swalOptions('success'),
        ...{

            showConfirmButton: true,
            iconHtml: '<div  id="lottie-icon-success></div>',
            confirmButtonText: 'Got it',
            didOpen: async () => {
                const lottieContainer = document.getElementsByClassName('swal2-icon-content')?.[0]
                const pathLottie = await import(`../../../../src/animations/${iconText}.json`)
                if (lottieContainer != null) {
                    lottie.loadAnimation({
                        container: lottieContainer,
                        renderer: 'svg',
                        ...{ ...animationOptions, animationData: pathLottie.default }
                    })
                }
            }

        }
    }
    const inMomentTime = toUTCWithOffset(getCurrentDubaiDate(), 1)
    const nameUser = getNameUser(currentUser as User)
    const unitAddress = (unit != null) ? getUnitAddress(unit) : 'Unit not taken address'
    return (
        <>
            {!localLoading
                ? <MainLoader title={'In process'} />

                : <div className={'d-flex flex-column w-100'}>
                    {

                        (offerStatuses?.includes('In-process') || offerStatuses?.includes('Negotiated by agent')) &&
                        <ActionCard
                            title={'Approve'}
                            description={'Approve offer'}
                            onClick={
                                () => {
                                    swalAttentionLeave('Are you sure?')
                                        .then(async (result: any) => {
                                            if (result.isConfirmed) {
                                                setLocalLoading(true)
                                                const request = await dispatch(
                                                    updateStatusOfferAgent({
                                                        offerID,
                                                        status: 'Confirmed',
                                                        notify: 'Agent'
                                                    })
                                                )

                                                if (request.success && request.response.data) {
                                                    setLocalLoading(false)

                                                    await sendNotification(inMomentTime, Number(ownerUserId), request?.response?.data?.id, 'user-offer-confirmed', `Attention kindy, ${nameUser} did offer on ${unitAddress} , please respond.`)

                                                    await Swal.fire({
                                                        ...optionsSuccess,
                                                        title: 'Confirmed!',
                                                        html: 'You have successfully confirmed offer'
                                                    }).then(async (value: any) => {
                                                        if (value.isConfirmed) {
                                                            navigate(`/agent/modalView/offer_modal/${unitID}/${offerID}/${'my_unit'}?type=${typeOffer ?? 'not'}`)
                                                        }
                                                    })
                                                } else {
                                                    setLocalLoading(false)

                                                    await Swal.fire({
                                                        ...swalOptions('error'),
                                                        ...{
                                                            title: 'Something wrong',
                                                            html: 'Try again'
                                                        }
                                                    })
                                                }
                                            }
                                        })
                                        .catch(console.log)
                                }
                            }
                            icon={'check_circle'}
                            styles={'mb-3 mx-n3'}
                        />
                    }

                    {
                        (offerStatuses?.includes('In-process') || offerStatuses?.includes('Negotiated by agent')) &&
                        <ActionCard
                            title={'Reject'}
                            description={'Reject offer'}
                            onClick={
                                () => {
                                    swalAttentionLeave('Are you sure?')
                                        .then(async (result: any) => {
                                            setLocalLoading(true)
                                            if (result.isConfirmed) {
                                                const request = await dispatch(
                                                    updateStatusOfferAgent({
                                                        offerID,
                                                        status: 'Canceled by other agent',
                                                        notify: 'Agent'
                                                    })
                                                )
                                                if (request.success && request.response.data) {
                                                    setLocalLoading(false)
                                                    await sendNotification(inMomentTime, Number(ownerUserId), request?.response?.data?.id, 'user-offer-canceled', `Attention kindy, ${nameUser} did offer on ${unitAddress} , please respond.`)

                                                    await Swal.fire({
                                                        ...optionsSuccess,
                                                        title: 'Rejected!',
                                                        html: 'You have successfully rejected offer'
                                                    }).then(async (value: any) => {
                                                        if (value.isConfirmed) {
                                                            navigate(`/agent/modalView/offer_modal/${unitID}/${offerID}/${'my_unit'}?type=${typeOffer ?? 'not'}`)
                                                            //      navigate(`/agent/modalView/viewing_modal/${unitID}/${offerID}/agent_s_unit`)
                                                        }
                                                    })
                                                } else {
                                                    setLocalLoading(false)

                                                    await Swal.fire({
                                                        ...swalOptions('error'),
                                                        ...{
                                                            title: 'Something wrong',
                                                            html: 'Try again'
                                                        }
                                                    })
                                                }
                                            }
                                        })
                                        .catch(console.log)
                                }
                            }
                            icon={'cancel'}
                            styles={'mb-3 mx-n3'}
                        />
                    }

                    {
                        (offerStatuses?.includes('In-process') || offerStatuses?.includes('Negotiated by agent')) &&
                        <ActionCard
                            title={'Negotiate'}
                            description={'Negotiate offer'}
                            onClick={() => {
                                navigate(`/agent/modal/agent_to_agent_negotiate_offer/${unitID}/${offerID}`)
                            }}
                            icon={'event_available'}
                            styles={'mb-3 mx-n3'}
                        />
                    }
                    {
                        (offerStatuses?.includes('Confirmed')) &&
                        <ActionCard
                            title={'Completed'}
                            description={'Close Deal'}
                            onClick={
                                () => {
                                    swalAttentionLeave('Are you sure?')
                                        .then(async (result: any) => {
                                            if (result.isConfirmed) {
                                                setLocalLoading(true)
                                                const request = await dispatch(
                                                    updateStatusOfferAgent({
                                                        offerID,
                                                        status: 'Completed',
                                                        notify: 'Agent'
                                                    })
                                                )

                                                if (request.success && request.response.data) {
                                                    setLocalLoading(false)



                                                    await Swal.fire({
                                                        ...optionsSuccess,
                                                        title: 'Completed!',
                                                        html: 'You have successfully completed offer'
                                                    }).then(async (value: any) => {
                                                        if (value.isConfirmed) {

                                                            await sendNotification(inMomentTime, Number(ownerUserId), request?.response?.data?.id, 'user-offer-completed', `Attention kindy, ${nameUser} did offer on ${unitAddress} , please respond.`)
                                                            // await sendUnitNotification(unit as Unit, 'user-unit-sold')
                                                            void dispatch(setUnitSold(Number(unitID)))





                                                            navigate(`/agent/modalView/offer_modal/${unitID}/${offerID}/${'my_unit'}?type=${typeOffer ?? 'not'}`)
                                                        }
                                                    })
                                                } else {
                                                    setLocalLoading(false)
                                                    await Swal.fire({
                                                        ...swalOptions('error'),
                                                        ...{
                                                            title: 'Something wrong',
                                                            html: 'Try again'
                                                        }
                                                    })
                                                }
                                            }
                                        })
                                        .catch(console.log)
                                }
                            }
                            icon={'check_circle'}
                            styles={'mb-3 mx-n3'}
                        />
                    }
                    <ActionCard
                        title={'Chat'}
                        description={'Chat any time about some details '}
                        onClick={
                            () => {
                                dispatch(openChatByUserID(userToConnect as number))
                                    .then(({ id }: { id: any }) => {
                                        if (id != null) {
                                            navigate(`/${userType.toLowerCase()}/chats/${String(id)}`)
                                        }
                                    })
                            }

                        }
                        icon={'mail'}
                        styles={'mb-3 mx-n3'}

                    />
                </div >}
        </>
    )
}
