
import React from 'react'

import { nanoid } from 'nanoid'

import BroadcastCardClient from './BroadcastCardClient'
import BroadcastCardUnit from './BroadcastCardUnit'
import { days } from '../../../../constants'

interface IProps {
    broadcasts: any
    typeBroadcast: string
}

export default function BroadcastsListItem({ broadcasts, typeBroadcast }: IProps): JSX.Element {
    return (
        <div className="flex-grow-1">
            {broadcasts && broadcasts.length > 0
                ? (
                    Array.from(new Set(broadcasts.map((broadcast: any) => (new Date(broadcast?.createdAt) as any).toDateString())))
                        .sort((a: any, b: any) => (new Date(a) as any) - (new Date(b) as any))
                        .map((dateString: any, index: number) => {
                            const date = new Date(dateString)
                            const day = date.getDate().toString().padStart(2, '0')
                            const month = date.toLocaleString('default', { month: 'short' })
                            const dayWeek = date.getDay()

                            const haveDatesBroadcasts = broadcasts.filter((br: any) => (new Date(br?.createdAt) as any).toDateString() === dateString)

                            return (
                                <div key={nanoid()} className="card shadow-sm mb-2 position-relative me-n0">
                                    <div className="d-flex justify-content-between">
                                        <div className="w-50px d-flex flex-column align-items-center justify-content-start px-auto mx-auto  pt-2 col-2">
                                            <span className="fw-normal fs-5x fs-lg-3x lh-1 my-1">{`${day}`}</span>
                                            <span className="text-gray-700 fs-4 fs-lg-3">{days[dayWeek]}</span>
                                        </div>
                                        <div className='col-10'>
                                            {
                                                haveDatesBroadcasts && haveDatesBroadcasts.length > 0
                                                    ? haveDatesBroadcasts.map((broadcast: any) => (
                                                        typeBroadcast === 'client'

                                                            ? <BroadcastCardClient key={nanoid()} currentBroadcast={broadcast} />
                                                            // <BroadcastClientCard key={nanoid()} currentBroadcast={broadcast} />
                                                            : <BroadcastCardUnit key={nanoid()} currentBroadcast={broadcast} />

                                                    ))
                                                    : <div className={'d-flex align-items-end justify-content-end p-4'}>
                                                        <span className="fs-2x text-gray-800 fw-semi-bold text-uppercase">No offers for today </span>
                                                    </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                )
                : <div className="card shadow-sm mb-2 position-relative" />
            }
        </div>

    )
}
