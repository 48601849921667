import MyCarousel from '../../../../app/MyCarousel'
import _ from 'lodash'
import UnitImageCard from './UnitImageCard'
import React from 'react'
import { nanoid } from 'nanoid'
import { untitledImage } from '../../../../urls'

export interface UnitSliderProps {
    unit: any // Replace to IUnit
    inMessage?: boolean
}
const UnitSlider = ({ unit, inMessage = false }: UnitSliderProps): JSX.Element => {
    //  // untitledImage
    console.log(unit.attributes, "unit.attributes")
    return (unit.attributes.Images.length > 0 ?
        (
            <div key={nanoid()}>
                <MyCarousel showTwoColumns={false}>
                    {
                        _.map(unit.attributes.Images || [], (image: string) => (
                            <UnitImageCard image={image} inMessage={inMessage} key={nanoid()} />
                        ))
                    }
                </MyCarousel>
            </div>
        )
        : (
            <div className="text-center">
                <div
                    className={`w-100 ${inMessage ? 'h-150px' : 'h-200px'} overflow-hidden bgi-no-repeat bgi-size-cover bgi-position-x-center bgi-position-y-center`}
                    style={{ backgroundImage: `url(${untitledImage})` }}
                >
                    <div className={`${inMessage ? 'h-150px' : 'h-200px'} w-100`}></div>
                </div>
            </div>


        )
    )
}

export default UnitSlider
