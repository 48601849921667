import React from 'react'
import { Link, useSearchParams } from 'react-router-dom'
import useUserType from '../../../../hooks/UseUserType'
import { useAppSelector } from '../../../../hooks/redux'
import Icon from '../../../../app/icon/Icon'
import { nanoid } from 'nanoid'
const ChatListHeader = (): JSX.Element => {
    const { userType } = useUserType()
    const [searchParams, setSearchParams] = useSearchParams()
    const favorites = searchParams.get('filter') === 'favorites'
    const blocked = searchParams.get('filter') === 'blocked'
    const userDevice = useAppSelector((state) => state.app.userDevice)
    const toggleFavourites = (): void => {
        if (favorites) {
            setSearchParams('')
        } else {
            setSearchParams('?filter=favorites')
        }
    }

    const toggleBlocked = (): void => {
        if (blocked) {
            setSearchParams('')
        } else {
            setSearchParams('?filter=blocked')
        }
    }
    return (
        <>
            <div className={'position-fixed w-100 shadow-sm'} style={{ zIndex: 3 }}>

                <div className={'bg-white d-flex align-items-center justify-content-between p-4 pt-6'}>
                    <h2 className={'fw-bold my-2'}>Chats</h2>
                    <div className={'d-flex gap-4'}>
                        <Link to={`/${userType.toLowerCase()}/search`}>
                            <Icon
                                name={'search'}
                                className={'text-muted fs-4x fs-lg-3x'}
                            />
                        </Link>
                        <Icon
                            name={'lock'}
                            key={nanoid()}
                            className={`${blocked ? 'text-dark' : 'text-muted'} fs-4x fs-lg-3x`}
                            onClick={toggleBlocked}
                        />
                        <Icon
                            name={'bookmark'}
                            key={nanoid()}
                            className={`${favorites ? 'text-dark' : 'text-muted'} fs-4x fs-lg-3x`}
                            onClick={toggleFavourites}
                        />
                    </div>
                </div>
            </div>
            <div className="" style={{ height: userDevice === 'IPhone' ? '88px' : '63px' }}></div>
        </>
    )
}

export default ChatListHeader
