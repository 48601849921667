import React from 'react'

import UpdateSubscriptions from './UpdateSubscriptions'
import SubscriptionsList from './SubscriptionsList'
import { getSubscriptionDetails } from '../../../../functions/agent/subscriptions/getSubsDetails'
import { BSO_DEVELOP_MODE_ON_FRONT } from '../../../../urls'
import Icon from '../../../../app/icon/Icon'



export default function CurrentSub(): JSX.Element {

    const isDev = process.env.REACT_APP_IS_DEV === 'true' || window.localStorage.getItem(BSO_DEVELOP_MODE_ON_FRONT) === 'true'

    return (
        <div className="card card-dashed p-2 bg-light-primary">
            <div className="d-flex justify-content-between align-items-center mb-1 rounded p-2 mb-2">
                <div className="d-flex align-items-center me-2 ">
                    <span className="badge badge-lg border border-5 border-success badge-circle badge-light-success me-6"></span>
                    <div className="flex-grow-1">
                        <div className="d-flex align-items-center fs-2 fw-bold flex-wrap">
                            {getSubscriptionDetails().title}
                        </div>
                        <div className="fw-semibold opacity-75 lh-sm">
                            {getSubscriptionDetails().description}
                        </div>
                    </div>
                </div>

                <div className="ms-5">
                    <span className="mb-2">AED</span>
                    <span className="fs-3x fw-bold">
                        {getSubscriptionDetails().amount}
                    </span>
                    <span className="fs-7 opacity-50">/<span>{getSubscriptionDetails().period}</span></span>
                </div>
            </div>

            <div className="pt-1 px-2 d-flex flex-column justify-content-between">

                <div className="separator separator-dashed my-2"></div>
                <h5 className="my-4">Subscription Details</h5>

                <div className="d-flex align-items-center fs-6 my-1">
                    <span className="fw-semi-bold  text-gray-700 flex-grow-1">
                        Started{' '}
                    </span>

                    {getSubscriptionDetails().startedAtDate != null
                        ? (<span className="fw-semi-bold text-text-gray-700 flex-grow-2">
                            {getSubscriptionDetails().startedAtDate}
                        </span>)
                        : null
                    }

                </div>

                <div className="separator separator-dashed my-2"></div>
                <div className="d-flex align-items-center fs-6 my-1">
                    <span className="fw-semi-bold  text-gray-700 flex-grow-1">
                        Status{' '}
                    </span>

                    <span className="flex-grow-2 badge fs-6 badge-light-success">
                        Active
                    </span>

                </div>
                <div className="separator separator-dashed my-2"></div>
                {isDev
                    ? <>
                        <div className="d-flex align-items-center fs-6 my-1">
                            <span className="fw-semi-bold  text-gray-700 flex-grow-1">
                                Next Invoice{' '}
                            </span>
                            {getSubscriptionDetails().finishedAtDate != null
                                ? (<span className="fw-semi-bold text-text-gray-700 flex-grow-2">
                                    {getSubscriptionDetails().finishedAtDate}
                                </span>)
                                : null
                            }
                        </div>
                        <div className="separator separator-dashed my-2"></div>

                        <div className="d-flex flex-wrap align-items-center my-1">
                            <div >
                                <div className="fs-6 fw-bold mb-1 text-uppercase">Auto renew</div>
                            </div>
                            <div className="ms-auto mb-1">
                                <Icon name={'check_circle'} className={'fs-3x text-success'} />
                            </div>
                        </div>
                        <div className="separator separator-dashed my-2"></div>
                        <div className="d-flex align-items-center justify-content-end py-2">
                            <div className="ms-2">
                                <UpdateSubscriptions title={'Compare plans'}>
                                    <SubscriptionsList />
                                </UpdateSubscriptions>
                            </div>
                        </div>
                    </>
                    : null}
            </div>
        </div>
    )
}
