import React, { useState } from 'react'
import { Progress, Space } from 'antd'
import { useAppSelector } from '../../../../hooks/redux'
import { type RootState } from '../../../../store/store'
import { type ISettings, type User } from '../../../../store/slices/userSlice'
import { generateProgressProfile } from '../../../../functions/agent/profile/generateProgressProfile'

interface IProps {
    progressProfile: number
}

function CurrentLevelBar({ progressProfile }: IProps): JSX.Element {
    const customText = (progressProfile: number) => (
        <>
            <span className="text-dark fw-bold fs-3x fs-lg-1x">{`${progressProfile}`} </span>  <span className="text-dark fw-bold fs-lg-4 ms-n3">%</span>
            <span className="text-dark fw-bold fs-6 d-block">{'complete'}</span>
        </>
    )

    return (
        <div>
            <h4>Current level</h4>
            <Progress
                type="circle"
                percent={progressProfile}
                strokeColor="#50cd89"
                format={() => customText(progressProfile)}
                size={82}
            />
        </div>
    )
}

export default CurrentLevelBar
