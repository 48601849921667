import React from 'react'
// import TabButton from '../../shared/elements/TabButton'
import { useSearchParams } from 'react-router-dom'
import SearchMessages from './search-results/messages'
import SearchHeader from './header'
// import SearchUnits from './search-results/units'
import SearchProfiles from './search-results/profiles'
import SearchInvites from './search-results/invites'
import SearchUnits from './search-results/units'
import { useAppSelector } from '../../../hooks/redux'
import useUserType from '../../../hooks/UseUserType'
import { type RootState } from '../../../store/store'
const Search = (): JSX.Element => {
    const { userType } = useUserType()
    const currentUser = useAppSelector((state: RootState) => state.user.currentUser)
    const [searchParams] = useSearchParams()
    const type = searchParams.get('type') ?? (!(userType === 'Landlord' && currentUser.id !== Number(process.env.REACT_APP_BSO_USER_ID)) ? 'profiles' : 'messages')
    const userDevice = useAppSelector((state) => state.app.userDevice)
    return (
        <div className={'page d-flex flex-column flex-row-fluid w-100'}
            style={{ height: userDevice === 'IPhone' ? 'calc(100vh - 9.5rem)' : 'calc(100vh - 8rem)' }}>
            <SearchHeader />
            <div className='my-lg-14 position-relative pt-8 pt-lg-14' style={{ marginTop: "calc(5.9rem)" }}>
                {
                    (type === 'messages') && (
                        <SearchMessages />
                    )
                }
                {
                    (type === 'units') && (
                        <SearchUnits />
                    )
                }
                {
                    (type === 'profiles') && (
                        <SearchProfiles />
                    )
                }
                {
                    (type === 'invites') && (
                        <SearchInvites />
                    )
                }
            </div>
        </div>
    )
}

export default Search
