import React, { useRef } from 'react'
import { Carousel as AntdCarousel, Row, Col } from 'antd'
import { type CarouselRef } from 'antd/es/carousel'

interface IProps {
    children: any
    showTwoColumns: boolean
    dots?: boolean
    autoScrol?: boolean
    afterChange?: (ref: React.Ref<CarouselRef>) => (current: number) => void
}

export default function MyCarousel({ children, showTwoColumns, dots = false, autoScrol = false, afterChange = () => () => { } }: IProps): JSX.Element {
    const ref = useRef<CarouselRef>(null)

    return (
        <>
            <AntdCarousel dots={dots} autoplay={autoScrol} afterChange={afterChange(ref)} ref={ref} waitForAnimate={true}>
                {showTwoColumns
                    ? (
                        <div>
                            <Row>
                                <Col span={12}>
                                    {children[0]}
                                </Col>
                                <Col span={12}>
                                    {children[1]}
                                </Col>
                            </Row>
                        </div>
                    )
                    : children
                }
                {showTwoColumns
                    ? (
                        <div>
                            <Row>
                                <Col span={12}>
                                    {children[1]}
                                </Col>
                                <Col span={12}>
                                    {children[2]}
                                </Col>
                            </Row>
                        </div>
                    )
                    : null}
            </AntdCarousel>
        </>
    )
}
