import { Spin } from 'antd'
import React from 'react'

interface ListLoaderProps {
    height?: string
    floating?: boolean
}

const ListLoader = ({ height, floating }: ListLoaderProps): JSX.Element => {
    return (
        <div className={`${height === undefined ? 'h-100' : ''} d-flex flex-column justify-content-center  ${floating === true ? 'position-absolute top-0 mt-8' : ''}`}
            style={{
                height
            }}
        >
            <Spin size={'large'} />
        </div>
    )
}

export default ListLoader
