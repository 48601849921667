import { getQueryDocuments } from "../../../functions/shared/api/queries/getQueryDocuments"
import { ACCESS_KEY, documentScanTypes, urlAPI, USER_ID_KEY } from "../../../urls"
import { type Document } from "../../slices/documentSlice"



export const checkUserDocumentIfItUseOtherUser = async (formValues: Record<string, any>): Promise<any> => {
    const jwt = window.localStorage.getItem(ACCESS_KEY) ?? ''
    const userID = window.localStorage.getItem(USER_ID_KEY) ?? ''


    try {

        const query = `filters[$or][0][Category][$eq]=Passport&filters[$or][1][Category][$eq]=Emirates ID&filters[ForRegister][$eq]=true&filters[User][id][$notNull]=true&populate[Type]=*&populate[User][fields][0]=id&populate[Unit][fields][0]=id&populate[Offer][fields][0]=id&populate[Advertise][fields][0]=id`;

        const sendObject = {
            method: 'GET',
            collection: 'documents',
            query
        };

  
        const response = await fetch(`${urlAPI}`, {
            method: 'POST',
            body: JSON.stringify(sendObject),
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${jwt}`
            }
        })

        const responseJSON = await response.json()

        if (responseJSON.success) {
            if (responseJSON.response.data.length > 0) {
                const filteredResults = responseJSON.response?.data?.filter((doc: Document) => {
                    const type = doc?.attributes?.Type?.[0];
                    return (
                        doc?.attributes?.User?.data?.id !== Number(userID) &&
                        (type != null) &&
                        type.DateOfBirth === formValues.dob &&
                        type.Name === formValues.name
                    );
                });
            
                if (filteredResults.length > 0) {
                    return true 
                } else {
                    return false 
                }
            }
        } else if (responseJSON.response.data === null || responseJSON.response.data.length === 0) {
            return false 
        }
        return false
    } catch (error) {
        console.log(error, 'error')
        return false
    }
}
