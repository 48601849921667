import { getErrorss } from '../../../../functions/errors/GetErrors'
import { getQueryCurrentUser } from '../../../../functions/shared/api/queries/getQueryCurrentUser'
import { ACCESS_KEY, USER_ID_KEY,  urlAPI } from '../../../../urls'
import { authSlice } from '../../../slices/authSlice'
import { modalSlice } from '../../../slices/modalSlice'
import { userSlice } from '../../../slices/userSlice'
import { type AppDispatch, type RootState } from '../../../store'
import { verifyUserForChangeEmail } from '../../authActions/registerAction'

export const changeEmailForFirstTime = (emailChanged: string) => async (dispatch: AppDispatch, getState: () => RootState) => {
    const jwt = window.localStorage.getItem(ACCESS_KEY)
    const userID = window.localStorage.getItem(USER_ID_KEY)

    try {
        dispatch(modalSlice.actions.dataFetchingStart())

        const sendObject = {
            method: 'PUT',
            collection: 'users',
            id: Number(userID),
            query: getQueryCurrentUser(),

            body: {

                newEmail: emailChanged,
                username: `${emailChanged.split('@')[0]}_${new Date().getTime()}`.replaceAll(' ', '_').replaceAll('.', '_')
            }
        }

        const response = await fetch(`${urlAPI}`, {
            method: 'POST',
            body: JSON.stringify(sendObject),
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${jwt}`
            }
        })
        const dataJSON = await response.json()
        if (dataJSON.success) {
            if (dataJSON.response.error != null) {
                dispatch(modalSlice.actions.dataFetchingFinish())
                return { ...dataJSON, textNavigate: '', isSuccessful: false, textNotification: getErrorss(dataJSON) }
            } else {
                dispatch(userSlice.actions.userFetchingSuccess(dataJSON.response))
                dispatch(authSlice.actions.emailSuccess(dataJSON.response.email))
                dispatch(modalSlice.actions.dataFetchingFinish())
                const verCheck = await dispatch(verifyUserForChangeEmail(emailChanged))
                if (verCheck.success) {
                    if (verCheck.response.error != null) {
                        return { ...verCheck, textNavigate: '', isSuccessful: false, textNotification: verCheck.response.error.message }
                    } else {
                        window.localStorage.setItem('changingEmail', 'true')
                        return { ...dataJSON, textNavigate: '', isSuccessful: true, textNotification: '' }
                    }
                } else {
                    dispatch(modalSlice.actions.dataFetchingFinish())
                    return { textNavigate: '', isSuccessful: false, textNotification: '' }
                }
            }
        } else {
            dispatch(modalSlice.actions.dataFetchingFinish())
            return { textNavigate: '', isSuccessful: false, textNotification: dataJSON.response.error.message } // ''
        }
    } catch (error) {
        console.log(error)
        dispatch(modalSlice.actions.dataFetchingFinish())
    }
}
